export const LABELS = {
  title: "Title",
  forename: "Forename",
  middleName: "Middle Name",
  surname: "Surname",
  dob: "Date of Birth",
  previousName: "Previous Full Name",
  dateOfChange: "Date of Change",
  nationality: "Nationality",
  dualNationality: "Dual Nationality",
  hasPermanentUkResideRight:
    "Does the applicant have permanent right to reside in the UK?",
  visaType: "What visa is the applicant currently on?",
  hasRefugeeAsylumStatus:
    "Is the applicant seeking asylum in the UK / Does the applicant have refugee status?",
  hasDiplomaticImmunity: "Does the applicant have diplomatic immunity?",
  phoneNumber: "Mobile telephone number",
  email: "Email address",
  gender: "Gender",
  maritalStatus: "Marital Status",
  address: {
    stayDurationYears:
      "How long has the applicant been at this address (Years)?",
    stayDurationMonths:
      "How long has the applicant been at this address (Months)?",
    residentialStatus: "Residential Status",
    addressLine1: "Address Line 1",
    addressLine2: "Address Line 2",
    addressLine3: "Address Line 3",
    townOrCity: "Town or city",
    county: "County",
    postcode: "Postcode",
  },
};
