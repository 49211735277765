import CTAButton from "components/CTA/Button";
import FormTitle from "components/Form/Title";
import { useState } from "react";

// Define the types for the options and props
interface Option {
  value: string;
  title: string;
}

interface FilterCaseProps {
  refetch: (params: { search: string; status: string }) => void;
  options?: Option[];
}

const FilterCase: React.FC<FilterCaseProps> = ({ refetch, options }) => {
  const [search, setSearch] = useState<string>("");
  const [status, setStatus] = useState<string>("");

  const handleSearch = () => {
    refetch({ search: search, status: status });
  };

  const handleReset = () => {
    setSearch("");
    setStatus("");
    refetch({ search: "", status: "" });
  };

  return (
    <div>
      <div className="mb-6 rounded-lg border border-gray-200 bg-white px-6 py-8 md:mb-0">
        <form className="md:-mt-4">
          <FormTitle mb left border title="Filter" />

          <div>
            <input
              type="text"
              name="search"
              className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm focus:outline-none sm:text-sm"
              placeholder="Search your query"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <select
            className="focus:border-secondary focus:ring-secondary mt-3 block w-full rounded-md border-gray-300 shadow-sm focus:outline-none sm:text-sm"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option key="init" value="">
              -- Select status --
            </option>
            {options &&
              options.map((opt, i) => (
                <option key={i} value={opt.value}>
                  {opt.title}
                </option>
              ))}
          </select>
          <div className="mt-4 flex flex-col space-y-4">
            <CTAButton secondary label="Reset" onClick={handleReset} />
            <CTAButton label="Filter" onClick={handleSearch} />
          </div>
        </form>
      </div>
    </div>
  );
};

FilterCase.displayName = "FilterCase";

export default FilterCase;
