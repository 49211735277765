import React from "react";
import { useWatch } from "react-hook-form";

import FormInputNumber from "components/Form/Input/Number";
import FormInputRadio from "components/Form/Input/Radio";
import FormTitle from "components/Form/Title";
import FormErrors from "pages/ApplicationForm/components/FormErrors";
import { checkTruthyValue } from "utils/validation";
import { StepProps } from "../../types";
import { LABELS } from "./labels";
import OtherResidentsDetail from "./OtherResidentsDetail";

const STEP_KEY = "other";

const OtherDetailsForm: React.FC<StepProps> = ({
  control,
  register,
  errors,
}) => {
  const otherDetail = useWatch({ name: STEP_KEY, control: control });

  return (
    <div className="flex flex-col space-y-4">
      <FormTitle left border title="Other Details" mb={false} />

      <FormErrors formErrors={errors?.[STEP_KEY]} />

      <FormInputNumber
        id={`${STEP_KEY}.numberOfFinancialDependents`}
        label={LABELS.numberOfFinancialDependents}
        register={register}
        error={errors?.[STEP_KEY]?.numberOfFinancialDependents?.message}
      />

      <FormInputNumber
        id={`${STEP_KEY}.numberOfFinancialDependentsUnder17`}
        label={LABELS.numberOfFinancialDependentsUnder17}
        register={register}
        error={errors?.[STEP_KEY]?.numberOfFinancialDependentsUnder17?.message}
      />

      <FormInputRadio
        id={`${STEP_KEY}.dependentsUnder17LivingAtProperty`}
        label={LABELS.dependentsUnder17LivingAtProperty}
        register={register}
        requiredField={false}
        initValue={otherDetail?.dependentsUnder17LivingAtProperty}
        error={errors?.[STEP_KEY]?.dependentsUnder17LivingAtProperty?.message}
      />

      <FormInputNumber
        id={`${STEP_KEY}.numberOfFinancialDependentsOver17`}
        label={LABELS.numberOfFinancialDependentsOver17}
        register={register}
        error={errors?.[STEP_KEY]?.numberOfFinancialDependentsOver17?.message}
      />

      <FormInputRadio
        id={`${STEP_KEY}.othersLivingInPropertyBesideApplicant`}
        label={LABELS.othersLivingInPropertyBesideApplicant}
        register={register}
        requiredField={false}
        initValue={otherDetail?.othersLivingInPropertyBesideApplicant}
        error={
          errors?.[STEP_KEY]?.othersLivingInPropertyBesideApplicant?.message
        }
      />

      {checkTruthyValue(otherDetail?.othersLivingInPropertyBesideApplicant) && (
        <>
          <OtherResidentsDetail errors={errors} {...{ control, register }} />

          <FormInputRadio
            id={`${STEP_KEY}.anyResidentsMemberOfApplicantsFamily`}
            label={LABELS.anyResidentsMemberOfApplicantsFamily}
            register={register}
            requiredField={false}
            initValue={otherDetail?.anyResidentsMemberOfApplicantsFamily}
            error={
              errors?.[STEP_KEY]?.anyResidentsMemberOfApplicantsFamily?.message
            }
          />

          <FormInputRadio
            id={`${STEP_KEY}.anyResidentsProvidingFundsTowardsTransaction`}
            label={LABELS.anyResidentsProvidingFundsTowardsTransaction}
            register={register}
            requiredField={false}
            initValue={
              otherDetail?.anyResidentsProvidingFundsTowardsTransaction
            }
            error={
              errors?.[STEP_KEY]?.anyResidentsProvidingFundsTowardsTransaction
                ?.message
            }
          />

          <FormInputRadio
            id={`${STEP_KEY}.anyResidentsFinanciallyDependentOnApplicant`}
            label={LABELS.anyResidentsFinanciallyDependentOnApplicant}
            register={register}
            requiredField={false}
            initValue={otherDetail?.anyResidentsFinanciallyDependentOnApplicant}
            error={
              errors?.[STEP_KEY]?.anyResidentsFinanciallyDependentOnApplicant
                ?.message
            }
          />

          {checkTruthyValue(
            otherDetail?.anyResidentsFinanciallyDependentOnApplicant,
          ) && (
            <FormInputNumber
              id={`${STEP_KEY}.howManyResidentsFinanciallyDependentOnApplicant`}
              label={LABELS.howManyResidentsFinanciallyDependentOnApplicant}
              register={register}
              error={
                errors?.[STEP_KEY]
                  ?.howManyResidentsFinanciallyDependentOnApplicant?.message
              }
            />
          )}
        </>
      )}

      <FormInputRadio
        id={`${STEP_KEY}.isThisPrivateSale`}
        label={LABELS.isThisPrivateSale}
        register={register}
        requiredField={false}
        initValue={otherDetail?.isThisPrivateSale}
        error={errors?.[STEP_KEY]?.isThisPrivateSale?.message}
      />

      <FormInputRadio
        id={`${STEP_KEY}.isVendorRelatedToApplicant`}
        label={LABELS.isVendorRelatedToApplicant}
        register={register}
        requiredField={false}
        initValue={otherDetail?.isVendorRelatedToApplicant}
        error={errors?.[STEP_KEY]?.isVendorRelatedToApplicant?.message}
      />

      <FormInputRadio
        id={`${STEP_KEY}.vacantPossessionOnCompletion`}
        label={LABELS.vacantPossessionOnCompletion}
        register={register}
        requiredField={false}
        initValue={otherDetail?.vacantPossessionOnCompletion}
        error={errors?.[STEP_KEY]?.vacantPossessionOnCompletion?.message}
      />

      <FormInputRadio
        id={`${STEP_KEY}.isPurchasePriceLessThanMarketValue`}
        label={LABELS.isPurchasePriceLessThanMarketValue}
        register={register}
        requiredField={false}
        initValue={otherDetail?.isPurchasePriceLessThanMarketValue}
        error={errors?.[STEP_KEY]?.isPurchasePriceLessThanMarketValue?.message}
      />
    </div>
  );
};

export default OtherDetailsForm;
