import { gql } from "@apollo/client";

export const QUERY_CASES = gql`
  query cases($search: String, $status: String) {
    cases(search: $search, status: $status) {
      uuid
      reference
      status
      statusDisplay
      createdOn
      applicantNames
      applications {
        uuid
        reference
        status
        statusDisplay
        createdOn
      }
      illustrations {
        uuid
        reference
        createdOn
      }
    }
    options_status: __type(name: "CaseStatusType") {
      options: enumValues {
        value: name
        title: description
      }
    }
  }
`;

export const QUERY_CASE_DETAIL = gql`
  query case($uuid: UUID!) {
    case(uuid: $uuid) {
      uuid
      reference
      status
      statusDisplay
      createdOn
      applicantNames
      applications {
        uuid
        reference
        status
        statusDisplay
        createdOn
        broker {
          applicationDate
        }
        downloadUrl
      }
      illustrations {
        uuid
        reference
        createdOn
        downloadUrl
      }
      actions {
        uuid
        comment
        createdOn
      }
      dips {
        uuid
        reference
        createdOn
        downloadUrl
      }
    }
  }
`;
