import Loading from "components/Loading";
import { classNames } from "utils/helpers";

type RestProps = {
  form?: undefined | string;
};

type Props = {
  type?: any;
  enabled?: boolean;
  loading?: boolean;
  label?: string;
  onClick?: Function;
  secondary?: boolean;
  full?: boolean;
  submitFormName?: string;
};

export default function CTAButton({
  type = "button",
  enabled = true,
  loading = false,
  label = "Submit",
  secondary = false,
  full = false,
  submitFormName,
  onClick,
}: Props) {
  const handleOnClick = () => onClick && enabled && onClick();

  const rest: RestProps = {};

  if (submitFormName) {
    rest.form = submitFormName;
  }

  if (loading) {
    return (
      <div className="justify-center px-4">
        <Loading />
      </div>
    );
  }

  return (
    <button
      type={type}
      disabled={!enabled}
      className={classNames(
        !enabled ? "cursor-not-allowed opacity-50" : "cursor-pointer",
        full ? "flex w-full" : "inline-flex",
        secondary
          ? " border-brand-green-dark bg-white hover:bg-gray-50"
          : "border-brand-green-medium bg-brand-green-medium hover:border-brand-green-bright hover:bg-brand-green-bright",
        "justify-center rounded-md border px-7 py-2 text-base font-semibold shadow-md",
      )}
      onClick={handleOnClick}
      {...rest}
    >
      {label}
    </button>
  );
}
