import { useQuery } from "@apollo/client";
import { useState } from "react";

import { QUERY_CASES } from "api/queries/cases";
import GenericError from "components/GenericError";
import PageContent from "components/Page/Content";
import { Case } from "ts/interfaces/common/case";
import Loading from "../../components/Loading";
import CaseCreateButton from "./components/CaseCreateButton";
import CaseList from "./components/CaseList";
import FilterCase from "./components/FilterCase";

interface Option {
  value: string;
  title: string;
}

interface QueryCasesData {
  cases: Case[];
  options_status: {
    options: Option[];
  };
}

const Cases: React.FC = () => {
  const { loading, error, data, refetch } = useQuery<QueryCasesData>(
    QUERY_CASES,
    {
      fetchPolicy: "no-cache",
    },
  );

  const [createError, setCreateError] = useState<Error | null>(null);
  const [recentCase, setRecentCase] = useState<string | null>(null);

  const renderContent = () => {
    if (!data || loading) {
      return <Loading />;
    }
    if (error) {
      return <GenericError error={error} />;
    }
    if (createError) {
      return <GenericError error={createError} />;
    }
    return (
      <div>
        <CaseCreateButton
          refetch={refetch}
          setCreateError={setCreateError}
          setRecentCase={setRecentCase}
        />
        <CaseList cases={data.cases} recentCase={recentCase} />
      </div>
    );
  };

  return (
    <PageContent title="Cases">
      <div className="grid md:grid-cols-4 md:gap-8">
        <FilterCase refetch={refetch} options={data?.options_status.options} />
        <div className="md:col-span-3">{renderContent()}</div>
      </div>
    </PageContent>
  );
};

export default Cases;
