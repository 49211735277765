import * as Sentry from "@sentry/react";
import { useEffect } from "react";

interface GenericErrorType {
  originalError?: Error;
  error?: Error;
  message?: string;
}

interface ErrorProps {
  error?: GenericErrorType;
  message?: string;
}

const GenericError: React.FC<ErrorProps> = ({ error, message }) => {
  useEffect(() => {
    if (error) {
      // Check if the error object has nested error properties
      const _error = error?.originalError || error?.error || error;
      Sentry.captureException(_error);
    }
  }, [error]);

  const _message =
    error?.message ||
    message ||
    "Error, please try again or contact us for help.";

  return (
    <section>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <p className="mx-auto mb-10 mt-10 text-center lg:max-w-2xl">
          {_message}
        </p>
      </div>
    </section>
  );
};

export default GenericError;
