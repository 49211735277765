import { useMutation, useQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactElement, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { MUTATION_CREATE_ACCOUNT } from "api/mutations/account";
import { QUERY_ACCOUNT_VALIDATE_INVITE_TOKEN } from "api/queries/account";
import AlertError from "components/Alert/Error";
import AlertSuccess from "components/Alert/Success";
import CTAButton from "components/CTA/Button";
import FormInputPassword from "components/Form/Input/Password";
import Loading from "components/Loading";
import PageContainer from "components/Page/Container";
import PageDialogScreen from "components/Page/DialogScreen";
import { AHAUZ_PHONE_NUMBER, APP_URLS } from "settings";
import { NewPasswordFormInputs, newPasswordSchema } from "utils/validation";

export default function AccountAcceptInvite() {
  let params = useParams();
  let token = params.token;

  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(QUERY_ACCOUNT_VALIDATE_INVITE_TOKEN, {
    variables: {
      token: token,
    },
  });

  const [
    createBrokerAccount,
    { loading: mutationLoading, error: mutationError, data: mutationData },
  ] = useMutation(MUTATION_CREATE_ACCOUNT);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<NewPasswordFormInputs>({
    resolver: yupResolver(newPasswordSchema),
    defaultValues: {
      newPassword1: "",
      newPassword2: "",
    },
  });

  useEffect(() => {
    if (mutationData?.response?.ok) {
      setTimeout(() => (window.location.href = APP_URLS.DASHBOARD), 2000);
    }
  }, [mutationData]);

  const newPassword1 = watch("newPassword1");
  const newPassword2 = watch("newPassword2");

  const onSubmit: SubmitHandler<NewPasswordFormInputs> = (formData: any) => {
    createBrokerAccount({
      variables: {
        account: {
          token: token,
          newPassword1: formData.newPassword1,
          newPassword2: formData.newPassword2,
        },
      },
    });
  };

  const renderCreateAccountSuccess = (): ReactElement => (
    <AlertSuccess title="Account created!" shadow>
      <p>
        Your account has been set up and we'll now redirect you to your{" "}
        <a
          href={APP_URLS.DASHBOARD}
          className="text-green-800 underline hover:text-green-800"
        >
          Dashboard
        </a>
        .
      </p>
    </AlertSuccess>
  );

  const renderError = () => (
    <AlertError>
      <>
        <p className="mb-2 font-medium">
          Sorry, there is an error with your invite link. It could have expired
          or have been used already.
        </p>
        <p>
          Please try again, or contact our Support team on {AHAUZ_PHONE_NUMBER}.
        </p>
      </>
    </AlertError>
  );

  const renderForm = () => (
    <PageContainer>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="space-y-4">
          <FormInputPassword
            id="newPassword1"
            register={register}
            error={
              errors?.newPassword1?.message ||
              mutationData?.response?.errors?.newPassword1?.[0]
            }
          />
          <FormInputPassword
            id="newPassword2"
            label="Confirm Password"
            register={register}
            validate={false}
            error={
              errors?.newPassword2?.message ||
              mutationData?.response?.errors?.newPassword2?.[0]
            }
          />
          <div className="pt-2">
            {mutationLoading ? (
              <Loading />
            ) : (
              <CTAButton
                type="submit"
                enabled={!!(newPassword1 && newPassword2)}
                label="Create account"
                full
              />
            )}
          </div>
        </div>
      </form>
    </PageContainer>
  );

  const renderSetPassword = () => {
    if (mutationData?.response?.ok) {
      return renderCreateAccountSuccess();
    }
    if (queryLoading) {
      return <Loading />;
    }
    if (
      !(queryError || mutationError) &&
      queryData?.accountValidateInviteToken?.ok
    ) {
      return renderForm();
    }
    return renderError();
  };

  return (
    <PageDialogScreen
      title="Ahauz Intermediary account"
      copy="Set your password to create and login to your new account"
    >
      <>
        {renderSetPassword()}
        <div className="mt-8 text-center text-base text-brand-copy-light">
          Already have an account?{" "}
          <a href={APP_URLS.LOGIN}>Click here to log in</a>
        </div>
      </>
    </PageDialogScreen>
  );
}
