const DEFAULT_FORM_DATA = {
  start: {
    numberOfApplicants: 1,
    consentToCreditCheckAndInformationProcessing: undefined,
    receivedSuccessfulDipFromFirstChargeLender: undefined,
    firstChargeLenderDipReference: undefined,
  },
};

export default DEFAULT_FORM_DATA;
