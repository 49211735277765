import { useQuery } from "@apollo/client";

import { QUERY_APPLICATIONS } from "api/queries/applications";
import ApplicationList from "components/Application/List";
import { APPLICATION_IN_PROGRESS_KEY } from "utils/consts";

export default function DashboardApplications() {
  const { data } = useQuery(QUERY_APPLICATIONS, {
    variables: {
      status: APPLICATION_IN_PROGRESS_KEY,
    },
  });

  if (data && data.applications) {
    return (
      <div className="mt-8">
        <ApplicationList
          title="Applications waiting to be submitted"
          showCase
          showApplicants
          applications={data.applications}
        />
      </div>
    );
  }

  return <></>;
}
