const DEFAULT_FORM_DATA = {
  applicant1Title: "",
  applicant1FirstName: "",
  applicant1LastName: "",
  applicant2Title: "",
  applicant2FirstName: "",
  applicant2LastName: "",
  propertyValue: "",
  equityLoanValue: "",
  creditIntermediaryFees: "",
};

export default DEFAULT_FORM_DATA;
