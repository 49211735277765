import { gql } from "@apollo/client";

import { FRAGMENT_BROKER_TYPE } from "api/fragments";

export const QUERY_AUTHENTICATED_USER = gql`
  query Broker {
    broker {
      ...BrokerFields
    }
  }
  ${FRAGMENT_BROKER_TYPE}
`;

export const QUERY_ACCOUNT_HIJACK = gql`
  query {
    brokerHijack {
      releaseUrl
      releaseNextUrl
      releaseToken
    }
  }
`;

export const QUERY_VALIDATE_ACCOUNT_RESET_PASSWORD_TOKEN = gql`
  query AccountValidateResetPasswordToken($token: String!, $uidb64: String!) {
    accountValidateResetPasswordToken(token: $token, uidb64: $uidb64) {
      ok
    }
  }
`;

export const QUERY_ACCOUNT_VALIDATE_INVITE_TOKEN = gql`
  query AccountValidateInviteToken($token: String!) {
    accountValidateInviteToken(token: $token) {
      ok
    }
  }
`;
