import React from "react";

import AddressSetter from "components/Form/AddressSetter";
import FormInputCurrency from "components/Form/Input/Currency";
import FormInputNumber from "components/Form/Input/Number";
import FormInputRadio from "components/Form/Input/Radio";
import FormInputSelect from "components/Form/Input/Select";
import FormInputText from "components/Form/Input/Text";
import FormInputTextArea from "components/Form/Input/TextArea";
import FormTitle from "components/Form/Title";
import FormErrors from "pages/ApplicationForm/components/FormErrors";
import { useWatch } from "react-hook-form";
import { checkTruthyValue } from "utils/validation";
import { StepProps } from "../../types";
import { LABELS } from "./labels";
import {
  PropertyCouncilTaxBands,
  PropertyTenures,
  PropertyTypes,
  TENURE_LEASEHOLD_OPTION,
} from "./options";

const STEP_KEY = "property";

const PropertyForm: React.FC<StepProps> = ({
  control,
  clearErrors,
  setValue,
  register,
  errors,
}) => {
  const propertyDetail = useWatch({ name: STEP_KEY, control: control });

  return (
    <div className="flex flex-col space-y-4">
      <FormTitle left border title="Property" mb={false} />

      <FormErrors formErrors={errors?.[STEP_KEY]} />

      <div className="flex flex-col space-y-4">
        <label className="block text-base">
          Full address of the property being purchased
        </label>

        <AddressSetter
          clearErrors={clearErrors}
          setValue={setValue}
          register={register}
          idKey={STEP_KEY}
          errorKey={errors?.[STEP_KEY]}
        />
      </div>

      <FormInputSelect
        id={`${STEP_KEY}.propertyType`}
        label={LABELS.propertyType}
        register={register}
        initSelect
        options={PropertyTypes}
        requiredField={false}
        error={errors?.[STEP_KEY]?.propertyType?.message}
      />

      <FormInputSelect
        id={`${STEP_KEY}.tenure`}
        label={LABELS.tenure}
        register={register}
        initSelect
        options={PropertyTenures}
        requiredField={false}
        error={errors?.[STEP_KEY]?.tenure?.message}
      />

      {propertyDetail?.tenure === TENURE_LEASEHOLD_OPTION && (
        <FormInputNumber
          id={`${STEP_KEY}.yearsLeftOnLease`}
          label={LABELS.yearsLeftOnLease}
          register={register}
          error={errors?.[STEP_KEY]?.yearsLeftOnLease?.message}
          maxDigits={4}
        />
      )}

      <FormInputNumber
        id={`${STEP_KEY}.yearPropertyBuilt`}
        label={LABELS.yearPropertyBuilt}
        register={register}
        error={errors?.[STEP_KEY]?.yearPropertyBuilt?.message}
        maxDigits={4}
      />

      <FormInputRadio
        id={`${STEP_KEY}.isNewBuildPurchase`}
        label={LABELS.isNewBuildPurchase}
        register={register}
        requiredField={false}
        initValue={propertyDetail?.isNewBuildPurchase}
        error={errors?.[STEP_KEY]?.isNewBuildPurchase?.message}
      />

      {checkTruthyValue(propertyDetail?.isNewBuildPurchase) && (
        <FormInputText
          id={`${STEP_KEY}.nameOfBuilder`}
          label={LABELS.nameOfBuilder}
          register={register}
          error={errors?.[STEP_KEY]?.nameOfBuilder?.message}
        />
      )}

      <FormInputRadio
        id={`${STEP_KEY}.hasNhbcFnd15Cover`}
        label={LABELS.hasNhbcFnd15Cover}
        register={register}
        requiredField={false}
        initValue={propertyDetail?.hasNhbcFnd15Cover}
        error={errors?.[STEP_KEY]?.hasNhbcFnd15Cover?.message}
      />

      <FormInputRadio
        id={`${STEP_KEY}.willBePartiallyRented`}
        label={LABELS.willBePartiallyRented}
        register={register}
        requiredField={false}
        initValue={propertyDetail?.willBePartiallyRented}
        error={errors?.[STEP_KEY]?.willBePartiallyRented?.message}
      />

      <FormInputRadio
        id={`${STEP_KEY}.willBePrimaryResidence`}
        label={LABELS.willBePrimaryResidence}
        register={register}
        requiredField={false}
        initValue={propertyDetail?.willBePrimaryResidence}
        error={errors?.[STEP_KEY]?.willBePrimaryResidence?.message}
      />

      <FormInputRadio
        id={`${STEP_KEY}.propertyNearBusinessPremises`}
        label={LABELS.propertyNearBusinessPremises}
        register={register}
        requiredField={false}
        initValue={propertyDetail?.propertyNearBusinessPremises}
        error={errors?.[STEP_KEY]?.propertyNearBusinessPremises?.message}
      />

      <FormInputRadio
        id={`${STEP_KEY}.canUseWithoutStructuralChanges`}
        label={LABELS.canUseWithoutStructuralChanges}
        register={register}
        requiredField={false}
        initValue={propertyDetail?.canUseWithoutStructuralChanges}
        error={errors?.[STEP_KEY]?.canUseWithoutStructuralChanges?.message}
      />

      <FormInputRadio
        id={`${STEP_KEY}.isCouncilFlat`}
        label={LABELS.isCouncilFlat}
        register={register}
        requiredField={false}
        initValue={propertyDetail?.isCouncilFlat}
        error={errors?.[STEP_KEY]?.isCouncilFlat?.message}
      />

      <FormInputRadio
        id={`${STEP_KEY}.isStudioFlat`}
        label={LABELS.isStudioFlat}
        register={register}
        requiredField={false}
        initValue={propertyDetail?.isStudioFlat}
        error={errors?.[STEP_KEY]?.isStudioFlat?.message}
      />

      <FormInputCurrency
        id={`${STEP_KEY}.serviceChargeIfFlat`}
        label={LABELS.serviceChargeIfFlat}
        register={register}
        requiredField={false}
        error={errors?.[STEP_KEY]?.serviceChargeIfFlat?.message}
        setValue={setValue}
      />

      <FormInputNumber
        id={`${STEP_KEY}.noOfFloorsIfFlat`}
        label={LABELS.noOfFloorsIfFlat}
        register={register}
        error={errors?.[STEP_KEY]?.noOfFloorsIfFlat?.message}
      />

      <FormInputNumber
        id={`${STEP_KEY}.flatFloor`}
        label={LABELS.flatFloor}
        register={register}
        error={errors?.[STEP_KEY]?.flatFloor?.message}
      />

      <FormInputNumber
        id={`${STEP_KEY}.noOfBedrooms`}
        label={LABELS.noOfBedrooms}
        register={register}
        error={errors?.[STEP_KEY]?.noOfBedrooms?.message}
      />

      <FormInputCurrency
        id={`${STEP_KEY}.councilTaxMonthly`}
        label={LABELS.councilTaxMonthly}
        register={register}
        requiredField={false}
        error={errors?.[STEP_KEY]?.councilTaxMonthly?.message}
        setValue={setValue}
      />

      <FormInputSelect
        id={`${STEP_KEY}.councilTaxBand`}
        label={LABELS.councilTaxBand}
        register={register}
        initSelect
        options={PropertyCouncilTaxBands}
        requiredField={false}
        error={errors?.[STEP_KEY]?.councilTaxBand?.message}
      />

      <FormInputText
        id={`${STEP_KEY}.localAuthority`}
        label={LABELS.localAuthority}
        register={register}
        error={errors?.[STEP_KEY]?.localAuthority?.message}
      />

      <FormInputRadio
        id={`${STEP_KEY}.hasOutbuildingOrAnnex`}
        label={LABELS.hasOutbuildingOrAnnex}
        register={register}
        requiredField={false}
        initValue={propertyDetail?.hasOutbuildingOrAnnex}
        error={errors?.[STEP_KEY]?.hasOutbuildingOrAnnex?.message}
      />

      {checkTruthyValue(propertyDetail?.hasOutbuildingOrAnnex) && (
        <FormInputTextArea
          id={`${STEP_KEY}.useOfOutbuildingOrAnnexDetails`}
          label={LABELS.useOfOutbuildingOrAnnexDetails}
          register={register}
          requiredField={false}
          error={errors?.[STEP_KEY]?.useOfOutbuildingOrAnnexDetails?.message}
        />
      )}
    </div>
  );
};

export default PropertyForm;
