import { setDataForApi } from "utils/api";
import { FormSchemaReviewConfirm } from "./types";

const parseDataForApi = (data: FormSchemaReviewConfirm) => {
  const _data = { ...data } as FormSchemaReviewConfirm;

  setDataForApi(_data);

  return _data;
};

export default parseDataForApi;
