export const LABELS = {
  numberOfFinancialDependents:
    "How many financial dependents do the applicants have in total?",
  numberOfFinancialDependentsUnder17: "How many under 17?",
  dependentsUnder17LivingAtProperty:
    "Will the dependents under 17 be living in the property?",
  numberOfFinancialDependentsOver17: "How many over 17?",
  othersLivingInPropertyBesideApplicant:
    "Apart from the applicant(s), will anyone over the age of 17 be living in the property?",
  anyResidentsMemberOfApplicantsFamily:
    "Are any of the above members of the applicants family?",
  anyResidentsProvidingFundsTowardsTransaction:
    "Are any of the above providing any funds towards this transaction?",
  anyResidentsFinanciallyDependentOnApplicant:
    "Are any of the above financially dependent on the applicant(s)?",
  howManyResidentsFinanciallyDependentOnApplicant: "If 'Yes', how many?",
  isThisPrivateSale: "Is this a private sale?",
  isVendorRelatedToApplicant: "Is the vendor related to the applicant(s)?",
  vacantPossessionOnCompletion:
    "Will there be a vacant possession on completion?",
  isPurchasePriceLessThanMarketValue:
    "Is the purchase price less than the full market value of the property?",
  otherResidentsDetail: {
    name: "Name",
    relationship: "Relationship",
    dob: "Date of Birth",
  },
};
