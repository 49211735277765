import {
  STYLE_INPUT,
  STYLE_INPUT_MARGIN_TOP,
} from "components/Form/Input/consts";
import FormInputError from "components/Form/Input/Error";
import FormInputLabel from "components/Form/Input/Label";
import { FormInputProps } from "components/Form/Input/types";

export default function FormInputEmail({
  id = "email",
  label = "Email",
  error,
  onChange,
  disabled = false,
  requiredField = true,
  register,
}: FormInputProps) {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event);
  };

  return (
    <div>
      <FormInputLabel id={id} label={label} />
      <div className={STYLE_INPUT_MARGIN_TOP}>
        <input
          id={id}
          type="email"
          autoComplete="email"
          required
          disabled={disabled}
          className={STYLE_INPUT}
          {...register(id, {
            required: requiredField,
            onChange: handleOnChange,
          })}
        />
        <FormInputError error={error} />
      </div>
    </div>
  );
}
