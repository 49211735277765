import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { useLazyQuery, useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { SUBMIT_APPLICATION } from "api/mutations/application";
import { QUERY_APPLICATION } from "api/queries/application";
import PageContent from "components/Page/Content";
import { APP_URLS } from "settings";
import useApplicationStore from "store/Application";
import { useUserStore } from "store/User";
import { APPLICATION_IN_PROGRESS_KEY } from "utils/consts";
import FormErrors from "./components/FormErrors";
import FormNav from "./components/FormNav";
import GetApplicationProgress from "./components/GetApplicationProgress";
import Sidebar from "./components/Sidebar";
import SubmitApplicationProgress from "./components/SubmitApplicationProgress";
import { DEFAULT_FORM_DATA } from "./defaultFormData";
import { FormSchema } from "./formSchema";
import { DEFAULT_STEP, getStepByID, getStepByKey } from "./formSteps";
import { ApiDataType } from "./types";
import { mutationHooks } from "./useFormMutations";
import { validationSchema } from "./validation";

const MultiStepForm: React.FC = () => {
  const [submitError, setSubmitError] = useState(false);

  const { applicationId: urlApplicationId, step } = useParams<{
    applicationId: string;
    step: string;
  }>();
  const navigate = useNavigate();
  const currentStep = getStepByKey(step || DEFAULT_STEP.key);

  const [
    getApplication,
    {
      data: backendApplication,
      loading: getApplicationLoading,
      error: getApplicationError,
    },
  ] = useLazyQuery(QUERY_APPLICATION, { fetchPolicy: "no-cache" });

  const [
    submitApplication,
    {
      data: submitApplicationData,
      loading: submitApplicationLoading,
      error: submitApplicationError,
    },
  ] = useMutation(SUBMIT_APPLICATION);

  const { user } = useUserStore();

  const {
    formData,
    setFormData,
    applicationId,
    applicationCase,
    brokerDetailPopulated,
    setApplicationId,
    setApplicationDetail,
    setFormDataFromExistingApplication,
    setAppllicationCase,
    setFormStatus,
    setBrokerDetailPopulated,
    resetApplicantsFormStatus,
    resetApplication,
  } = useApplicationStore();

  const {
    control,
    clearErrors,
    setValue,
    watch,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    trigger,
  } = useForm<FormSchema>({
    defaultValues: DEFAULT_FORM_DATA,
    // mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  const {
    fields: applicantsFieldArray,
    remove,
    append,
  } = useFieldArray({
    control,
    name: "applicants",
  });

  const numberOfApplicants = useWatch({
    name: "numberOfApplicants",
    control: control,
    // defaultValue: 1
  });

  const [updateFormStep] = mutationHooks[currentStep.key]();

  useEffect(() => {
    if (urlApplicationId && !applicationId) {
      getApplication({
        variables: {
          uuid: urlApplicationId,
          status: APPLICATION_IN_PROGRESS_KEY,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlApplicationId, applicationId, getApplication]);

  // Auto populate broker details
  useEffect(() => {
    // Only do this if a first time application
    if (applicationId || urlApplicationId) {
      return;
    }
    if (user && !brokerDetailPopulated) {
      setBrokerDetailPopulated();
      setTimeout(() => {
        reset({
          ...DEFAULT_FORM_DATA,
          ...{
            broker: {
              name: user?.fullName,
              firmName: user?.firmName,
              email: user?.email,
              phoneNumber: user?.firmPhoneNumber,
            },
          },
        });
      }, 300);
    }
  }, [
    user,
    applicationId,
    urlApplicationId,
    brokerDetailPopulated,
    setBrokerDetailPopulated,
    reset,
  ]);

  useEffect(() => {
    if (!backendApplication) {
      return;
    }
    setFormDataFromExistingApplication(backendApplication);
  }, [backendApplication, setFormDataFromExistingApplication]);

  useEffect(() => {
    reset(formData);
    setFormStatus(formData);
  }, [formData, reset, setFormStatus]);

  useEffect(() => {
    if (submitApplicationData) {
      setTimeout(() => {
        resetApplication();
        reset(DEFAULT_FORM_DATA);
        navigate(`${APP_URLS.APPLICATION_FORM}/${urlApplicationId}/complete`);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    submitApplicationData,
    submitApplicationError,
    reset,
    resetApplication,
    navigate,
  ]);

  // Manage applicants field array count
  useEffect(() => {
    const currentApplicants = applicantsFieldArray.length;
    const targetApplicants = Number(numberOfApplicants);

    if (currentApplicants > 2) {
      return;
    }

    if (targetApplicants > currentApplicants) {
      for (let i = currentApplicants; i < targetApplicants; i++) {
        append(DEFAULT_FORM_DATA.applicants[0]);
      }
      handleNumberOfApplicantsChange();
    } else if (targetApplicants < currentApplicants) {
      for (let i = currentApplicants; i > targetApplicants; i--) {
        remove(i - 1);
      }
      handleNumberOfApplicantsChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfApplicants, applicantsFieldArray.length, append, remove]);

  const inSubmitState =
    submitApplicationData || submitApplicationLoading || submitApplicationError;

  // Force refresh page to get proper application state with updated number of
  // applicants from BE, instead of programatically trying to sync in client store
  const handleNumberOfApplicantsChange = async () => {
    await handleSaveProgress(false);
    resetApplicantsFormStatus();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const triggerApiFormUpdate = async (
    data: any,
    validated: boolean = false,
  ) => {
    const apiData: ApiDataType = {
      applicationId: applicationId || urlApplicationId,
      data: currentStep.parser(data, numberOfApplicants, applicantsFieldArray),
      case: applicationCase?.uuid,
      validated: validated,
    };

    if (currentStep.key === "applicants") {
      apiData.numberOfApplicants = Number(numberOfApplicants);
    }

    const { data: response } = await updateFormStep({
      variables: apiData,
    });
    return response;
  };

  const onSubmit = async (data: FormSchema) => {
    if (submitError) {
      setSubmitError(false);
    }

    window.scrollTo(0, 0);

    submitApplication({
      variables: {
        applicationId: applicationId,
      },
    });
  };

  const handleSaveAndNavigate = async (
    navigateStep?: string,
    validate: boolean = false,
  ) => {
    if (submitError) {
      setSubmitError(false);
    }

    let isValid = false;

    if (validate) {
      isValid = await trigger(currentStep.key);

      if (!isValid) {
        window.scrollTo(0, 0);
        return;
      }
    }

    const formValues = { ...getValues(currentStep.key) };
    const stepData = { ...formData[currentStep.key], ...formValues };

    try {
      const response = await triggerApiFormUpdate(stepData, isValid);

      setApplicationId(response?.application?.data?.application?.uuid);
      setApplicationDetail(response?.application?.data?.application);
      setAppllicationCase(response?.application?.data?.application?.case);
      setFormData(
        response?.application?.data,
        currentStep.key,
        response?.application?.data?.application?.numberOfApplicants,
      );

      window.scrollTo(0, 0);

      const _appId =
        response?.application?.data?.application?.uuid || applicationId;

      if (navigateStep) {
        navigate(`${APP_URLS.APPLICATION_FORM}/${_appId}/${navigateStep}`);
      } else {
        if (!urlApplicationId && _appId) {
          navigate(
            `${APP_URLS.APPLICATION_FORM}/${_appId}/${currentStep.key}`,
            {
              replace: true,
            },
          );
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      setSubmitError(true);
    }
  };

  const handleSaveProgress = async (validate: boolean) => {
    await handleSaveAndNavigate(undefined, validate);
  };

  const handlePrevious = async (validate: boolean) => {
    await handleSaveAndNavigate(getStepByID(currentStep.id - 1).key, validate);
  };

  const handleNext = async () => {
    await handleSaveAndNavigate(getStepByID(currentStep.id + 1).key, true);
  };

  const handleSidebarClick = async (
    stepNumber: number,
    stepKey: string,
    validate: boolean,
  ) => {
    if (stepNumber === currentStep.id) {
      return;
    }
    await handleSaveAndNavigate(stepKey, validate);
  };

  if (!applicationId && (getApplicationLoading || getApplicationError)) {
    return (
      <GetApplicationProgress getApplicationLoading={getApplicationLoading} />
    );
  }

  return (
    <PageContent title="Application Form">
      <div className="flex w-full flex-col md:flex-row md:space-x-8">
        {inSubmitState ? (
          <div className="w-full rounded-lg border border-gray-200 bg-white p-6 text-center md:p-8">
            <SubmitApplicationProgress
              submitApplicationError={submitApplicationError}
            />
          </div>
        ) : (
          <>
            <div className="mb-6 md:mb-0 md:basis-1/4">
              <Sidebar
                currentStep={currentStep}
                onStepClick={handleSidebarClick}
              />
            </div>
            <div className="md:basis-3/4">
              <div className="rounded-lg border border-gray-200 bg-white p-6 md:p-8">
                {submitError && (
                  <FormErrors
                    formErrors={{
                      "Save error": {
                        message:
                          "We're unable to save your Application right now, please try again or contact us to discuss",
                      },
                    }}
                  />
                )}

                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="flex flex-col space-y-2"
                  noValidate
                >
                  {currentStep.component && (
                    <currentStep.component
                      control={control}
                      clearErrors={clearErrors}
                      setValue={setValue}
                      watch={watch}
                      register={register}
                      errors={errors}
                      applicantsFieldArray={applicantsFieldArray}
                      getValues={getValues}
                    />
                  )}

                  <FormNav
                    currentStep={currentStep}
                    handlePrevious={handlePrevious}
                    handleSaveProgress={handleSaveProgress}
                    handleNext={handleNext}
                  />
                </form>
              </div>
            </div>
          </>
        )}
      </div>
    </PageContent>
  );
};

export default MultiStepForm;
