import * as Yup from "yup";

import { baseApplicantsValidation } from "components/Application/Form/ApplicantDetails/validation";
import { checkFalseyValue, INVALID_RESPONSE_MSG } from "utils/validation";

const validation = Yup.array().of(
  baseApplicantsValidation.shape({
    nationality: Yup.string().required(INVALID_RESPONSE_MSG),

    hasPermanentUkResideRight: Yup.string().when("nationality", {
      is: (value: any) => value !== "BRITISH",
      then: () =>
        Yup.bool()
          .typeError(INVALID_RESPONSE_MSG)
          .required(INVALID_RESPONSE_MSG),
      otherwise: () => Yup.string().nullable(),
    }),

    visaType: Yup.string().when("nationality", {
      is: (value: any) => value !== "BRITISH",
      then: () =>
        Yup.string().when("hasPermanentUkResideRight", {
          is: (value: any) => checkFalseyValue(value),
          then: () => Yup.string().required(INVALID_RESPONSE_MSG),
          otherwise: () => Yup.string().nullable(),
        }),
      otherwise: () => Yup.string().nullable(),
    }),

    hasRefugeeAsylumStatus: Yup.string().when("nationality", {
      is: (value: any) => value !== "BRITISH",
      then: () =>
        Yup.bool()
          .typeError(INVALID_RESPONSE_MSG)
          .required(INVALID_RESPONSE_MSG),
      otherwise: () => Yup.string().nullable(),
    }),

    hasDiplomaticImmunity: Yup.bool()
      .typeError(INVALID_RESPONSE_MSG)
      .required(INVALID_RESPONSE_MSG),

    gender: Yup.string().required(INVALID_RESPONSE_MSG),
  }),
);

export default validation;
