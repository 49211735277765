import {
  FormSchema,
  FormSchemaApplicationDetail,
  FormSchemaCase,
  FormSchemaStatus,
} from "pages/ApplicationForm/formSchema";
import {
  DEFAULT_APPLICANTS_FORM_STATUS,
  DEFAULT_FORM_STATUS,
  getApplicationStatus,
} from "pages/ApplicationForm/formStatus";
import { create } from "zustand";

interface FormStore {
  applicationId: string | null;
  applicationDetail: FormSchemaApplicationDetail | null;
  applicationCase: FormSchemaCase | null;
  formData: FormSchema;
  formStatus: FormSchemaStatus;
  brokerDetailPopulated: boolean;
  setApplicationId: (applicationId: string | null) => void;
  setApplicationDetail: (application: any) => void;
  setAppllicationCase: (caseDetail: any) => void;
  setFormData: (
    data: Partial<FormSchema>,
    storeKey: string,
    numberOfApplicants?: number,
  ) => void;
  setFormDataFromExistingApplication: (data: any) => void;
  setFormStatus: (data: any) => void;
  setBrokerDetailPopulated: () => void;
  resetApplicantsFormStatus: () => void;
  resetApplication: () => void;
}

const useApplicationStore = create<FormStore>((set) => ({
  applicationId: null,
  applicationDetail: null,
  applicationCase: null,
  formData: {
    numberOfApplicants: 1,
  },
  formStatus: DEFAULT_FORM_STATUS,
  brokerDetailPopulated: false,

  setApplicationId: (applicationId) =>
    set((state) => {
      if (!state.applicationId && applicationId) {
        return {
          applicationId: applicationId,
        };
      }
      return {
        applicationId: state.applicationId,
      };
    }),

  setApplicationDetail: (application) =>
    set((state) => ({
      applicationDetail: {
        reference: application?.reference || state.applicationDetail?.reference,
        modifiedOn:
          application?.modifiedOn || state.applicationDetail?.modifiedOn,
      },
    })),

  setAppllicationCase: (caseDetail) =>
    set((state) => ({
      applicationCase: caseDetail || state.applicationCase,
    })),

  setFormData: (data, storeKey, numberOfApplicants) =>
    set((state) => ({
      formData: {
        ...state.formData,
        ...(numberOfApplicants && { numberOfApplicants }),
        ...{
          [storeKey]: storeKey === "applicants" ? data?.applicants : data,
        },
      },
    })),

  setFormDataFromExistingApplication: (data) =>
    set({
      applicationId: data?.application?.uuid,
      applicationDetail: {
        reference: data?.application?.reference,
        modifiedOn: data?.application?.modifiedOn,
      },
      applicationCase: data?.application?.case,
      formData: {
        numberOfApplicants: data?.application?.numberOfApplicants,
        broker: data?.application?.broker,
        applicants: data?.application?.applicants,
        declarations: data?.application?.declarations,
        employments: data?.application?.employments,
        expenditures: data?.application?.expenditures,
        loan: data?.application?.loan,
        property: data?.application?.property,
        other: data?.application?.other,
        solicitor: data?.application?.solicitor,
        // confirm: data?.application?.confirm,
      },
    }),

  setFormStatus: (data) =>
    set({
      formStatus: getApplicationStatus(data),
    }),

  setBrokerDetailPopulated: () =>
    set({
      brokerDetailPopulated: true,
    }),

  resetApplicantsFormStatus: () =>
    set((state) => ({
      formStatus: {
        ...state.formStatus,
        ...DEFAULT_APPLICANTS_FORM_STATUS,
      },
    })),

  resetApplication: () =>
    set({
      applicationId: null,
      applicationDetail: null,
      applicationCase: null,
      formData: {
        numberOfApplicants: 1,
      },
      formStatus: DEFAULT_FORM_STATUS,
      brokerDetailPopulated: false,
    }),
}));

export default useApplicationStore;
