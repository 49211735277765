import { gql } from "@apollo/client";

export const UPDATE_BROKER_DETAILS = gql`
  mutation updateStep(
    $applicationId: UUID
    $data: BrokerDetailInput!
    $case: UUID
    $validated: Boolean!
  ) {
    application {
      data: updateBrokerDetail(
        applicationId: $applicationId
        data: $data
        case: $case
        validated: $validated
      ) {
        ... on BrokerDetailType {
          email
          application {
            uuid
            reference
            modifiedOn
            case {
              reference
              uuid
            }
          }
          applicationDate
          feeAmount
          firmName
          isFeeCharged
          levelOfAdviceGiven
          confirmBrokerDeclarations
          name
          phoneNumber
          status
        }
      }
    }
  }
`;

export const UPDATE_APPLICANT_DETAILS = gql`
  mutation updateStep(
    $applicationId: UUID
    $data: [ApplicantDetailInput!]!
    $case: UUID
    $validated: Boolean!
    $numberOfApplicants: Int
  ) {
    application {
      data: updateApplicantDetails(
        applicationId: $applicationId
        data: $data
        case: $case
        validated: $validated
        numberOfApplicants: $numberOfApplicants
      ) {
        ... on ApplicantDetailType {
          application {
            uuid
            numberOfApplicants
            reference
            modifiedOn
            case {
              reference
              uuid
            }
          }
          applicants {
            uuid
            currentAddress {
              address
              addressLine1
              addressLine2
              addressLine3
              buildingName
              buildingNumber
              county
              postcode
              residentialStatus
              stayDurationMonths
              stayDurationYears
              subBuildingName
              subBuildingNumber
              thoroughfare
              townOrCity
            }
            dateOfChange
            dob
            dualNationality
            email
            visaType
            title
            surname
            status
            previousName
            previousAddresses {
              uuid
              townOrCity
              thoroughfare
              subBuildingNumber
              subBuildingName
              stayDurationYears
              stayDurationMonths
              residentialStatus
              postcode
              county
              buildingNumber
              buildingName
              addressLine3
              addressLine2
              addressLine1
              address
            }
            phoneNumber
            nationality
            maritalStatus
            middleName
            hasRefugeeAsylumStatus
            hasPermanentUkResideRight
            hasDiplomaticImmunity
            gender
            forename
          }
        }
      }
    }
  }
`;

export const UPDATE_APPLICANT_DECLARATIONS = gql`
  mutation updateStep(
    $applicationId: UUID!
    $data: [ApplicantDeclarationsInput!]!
    $validated: Boolean!
  ) {
    application {
      data: updateApplicantDeclarations(
        applicationId: $applicationId
        data: $data
        validated: $validated
      ) {
        ... on ApplicantDeclarationsType {
          applicantId
          ccjsGreaterThan500Last3years
          declarationComment
          defaultsGreaterThan10012monthsOr5003years
          hadCcj
          hadPropertyReprocessed
          hasBeenBankrupt
          hasDebtRegistered
          hasFailedCreditAgreements
          hasMadeCreditorsArrangement
          hasUnsatisfiedDefaults
          missed2OrMoreCreditPaymentsLast6months
          moreThanOneDefaultLast3years
          status
        }
      }
    }
  }
`;

export const UPDATE_APPLICANT_EMPLOYMENT = gql`
  mutation updateStep(
    $applicationId: UUID!
    $data: [ApplicantEmploymentInput!]!
    $validated: Boolean!
  ) {
    application {
      data: updateApplicantEmployment(
        applicationId: $applicationId
        data: $data
        validated: $validated
      ) {
        ... on ApplicantEmploymentType {
          applicantId
          status
          numberOfJobs
          isRetired
          incomeChangesDetails
          hasOtherSourcesOfIncome
          retirementIncomeSource
          employedInUk
          awareOfAnyChangesToIncome
          anticipatedRetirementAge
          mortgageTermBeyondRetirementAge
          anticipatedCurrentRetirementAnnualAmount
          employmentDetail {
            annualBasicSalary
            annualBonusGross
            annualCommissionGross
            annualLocationOrCarAllowanceGross
            annualOvertimeGross
            businessName
            currentYearProjectedDividends
            dailyRate
            dividendFromLatestFinalisedYear
            dividendFromPreviousYear
            employerName
            employmentType
            endDate
            uuid
            latestYearNetProfit
            occupation
            percentBusinessOwned
            previousYearNetProfit
            projectedGrossProfitCurrentTradingYear
            salaryFromLatestFinalisedYear
            salaryFromPreviousYear
            selfEmployedAccountant {
              townOrCity
              thoroughfare
              subBuildingNumber
              subBuildingName
              postcode
              phoneNumber
              firmName
              emailAddress
              county
              buildingNumber
              buildingName
              addressLine3
              addressLine2
              addressLine1
              address
            }
            shareholdingPercentInCompany
            startDate
            employmentStatus
            treatedAsEmployedForTax
          }
          employmentOtherIncomeSources {
            annualGrossIncome
            uuid
            source
          }
        }
      }
    }
  }
`;

export const UPDATE_APPLICANT_EXPENDITURE = gql`
  mutation updateStep(
    $applicationId: UUID!
    $data: [ApplicantExpenditureInput!]!
    $validated: Boolean!
  ) {
    application {
      data: updateApplicantExpenditure(
        applicationId: $applicationId
        data: $data
        validated: $validated
      ) {
        ... on ApplicantExpenditureType {
          applicantId
          additionalPropertyRunningCosts
          changeInExpenditureDetail
          childMaintenancePayments
          childcareCosts
          applicantHasOutstandingCredit
          creditProviders {
            balanceOutstanding
            creditEndDate
            uuid
            monthlyPayment
            mortgageConvertedToB2l
            mortgagePriorPaidOff
            provider
            providerType
            repayFromMortgage
          }
          csaCourtOrderPayments
          futureChangeInExpenditure
          other
          schoolUniversityFees
          status
        }
      }
    }
  }
`;

export const UPDATE_LOAN_DETAILS = gql`
  mutation updateStep(
    $applicationId: UUID
    $data: LoanInput!
    $case: UUID
    $validated: Boolean!
  ) {
    application {
      data: updateLoan(
        applicationId: $applicationId
        data: $data
        case: $case
        validated: $validated
      ) {
        ... on LoanType {
          amountOfGift
          application {
            uuid
            reference
            modifiedOn
            case {
              reference
              uuid
            }
          }
          depositAmount
          equityLoanAmountRequired
          equityLoanRepaymentPlan
          firstChargeInitialInterestRatePercent
          firstChargeLender
          firstChargeMortgageValue
          valueOfFeeAddedToFirstChargeMortgage
          firstChargeSvrPercent
          gifterDetail
          isAllDepositByOwnResource
          isAllOrPartDepositAGift
          isFirstChargeARepaymentMortgage
          isGiftFromCurrentOwner
          isInterestRateFixedForAnInitialPeriod
          otherDepositAmount
          otherDepositSource
          otherRepaymentPlan
          otherSourceOfDeposit
          propertyPurchasePrice
          purposeOfMortgage
          sourceOfDeposit
          status
          termOfFirstChargeLoanYears
          termOfInitialInterestRatePeriodYears
          willGifterLiveInPropertyAfterPurchase
          willGifterTakeInterestInPropertyForGift
        }
      }
    }
  }
`;

export const UPDATE_PROPERTY_DETAILS = gql`
  mutation updateStep(
    $applicationId: UUID
    $data: PropertyInput!
    $case: UUID
    $validated: Boolean!
  ) {
    application {
      data: updateProperty(
        applicationId: $applicationId
        data: $data
        case: $case
        validated: $validated
      ) {
        ... on PropertyType {
          application {
            uuid
            reference
            modifiedOn
            case {
              reference
              uuid
            }
          }
          yearsLeftOnLease
          yearPropertyBuilt
          willBePrimaryResidence
          willBePartiallyRented
          useOfOutbuildingOrAnnexDetails
          townOrCity
          thoroughfare
          tenure
          subBuildingNumber
          subBuildingName
          status
          serviceChargeIfFlat
          propertyType
          propertyNearBusinessPremises
          postcode
          noOfFloorsIfFlat
          noOfBedrooms
          nameOfBuilder
          localAuthority
          isStudioFlat
          isNewBuildPurchase
          isCouncilFlat
          hasOutbuildingOrAnnex
          hasNhbcFnd15Cover
          groundRentIfFlat
          flatFloor
          county
          councilTaxMonthly
          councilTaxBand
          canUseWithoutStructuralChanges
          buildingNumber
          buildingName
          addressLine3
          addressLine2
          addressLine1
          address
          application {
            uuid
          }
        }
      }
    }
  }
`;

export const UPDATE_OTHER_DETAILS = gql`
  mutation updateStep(
    $applicationId: UUID
    $data: OtherDetailsInput!
    $case: UUID
    $validated: Boolean!
  ) {
    application {
      data: updateOtherDetails(
        applicationId: $applicationId
        data: $data
        case: $case
        validated: $validated
      ) {
        ... on OtherDetailsType {
          anyResidentsFinanciallyDependentOnApplicant
          anyResidentsMemberOfApplicantsFamily
          anyResidentsProvidingFundsTowardsTransaction
          application {
            uuid
            reference
            modifiedOn
            case {
              reference
              uuid
            }
          }
          dependentsUnder17LivingAtProperty
          howManyResidentsFinanciallyDependentOnApplicant
          isPurchasePriceLessThanMarketValue
          isThisPrivateSale
          isVendorRelatedToApplicant
          numberOfFinancialDependents
          numberOfFinancialDependentsOver17
          numberOfFinancialDependentsUnder17
          otherResidentsDetail {
            dob
            uuid
            name
            relationship
          }
          othersLivingInPropertyBesideApplicant
          vacantPossessionOnCompletion
          status
        }
      }
    }
  }
`;

export const UPDATE_SOLICITOR_DETAILS = gql`
  mutation updateStep(
    $applicationId: UUID
    $data: SolicitorDetailsInput!
    $case: UUID
    $validated: Boolean!
  ) {
    application {
      data: updateSolicitorDetails(
        applicationId: $applicationId
        data: $data
        case: $case
        validated: $validated
      ) {
        ... on SolicitorDetailsType {
          actingSolicitorName
          address
          addressLine1
          addressLine2
          addressLine3
          application {
            uuid
            reference
            modifiedOn
            case {
              reference
              uuid
            }
          }
          buildingName
          county
          buildingNumber
          emailAddress
          phoneNumber
          firmName
          postcode
          reference
          status
          subBuildingName
          subBuildingNumber
          thoroughfare
          townOrCity
        }
      }
    }
  }
`;

export const UPDATE_REVIEW_CONFIRM = gql`
  mutation updateStep(
    $applicationId: UUID!
    $data: ReviewInput!
    $case: UUID
    $validated: Boolean!
  ) {
    application {
      data: updateReview(
        applicationId: $applicationId
        data: $data
        case: $case
        validated: $validated
      ) {
        ... on ReviewType {
          application {
            uuid
            reference
            modifiedOn
            case {
              reference
              uuid
            }
          }
          status
        }
      }
    }
  }
`;

export const SUBMIT_APPLICATION = gql`
  mutation submitApplication(
    $applicationId: UUID!
    $case: UUID
    $validated: Boolean
  ) {
    application {
      data: submitApplication(
        applicationId: $applicationId
        case: $case
        validated: $validated
      ) {
        ... on ApplicationType {
          uuid
          status
          case {
            reference
            uuid
          }
        }
      }
    }
  }
`;
