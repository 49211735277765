import APPLICANT_DECLARATIONS_DEFAULTS from "./steps/ApplicantDeclarations/defaultData";
import APPLICANT_DETAILS_DEFAULTS from "./steps/ApplicantDetails/defaultData";
import APPLICANT_EMPLOYMENT_DEFAULTS from "./steps/ApplicantEmployment/defaultData";
import APPLICANT_EXPENDITURE_DEFAULTS from "./steps/ApplicantExpenditure/defaultData";
import BROKER_DEFAULTS from "./steps/BrokerDetails/defaultData";
import LOAN_DEFAULTS from "./steps/Loan/defaultData";
import OTHER_DEFAULTS from "./steps/OtherDetails/defaultData";
import PROPERTY_DEFAULTS from "./steps/Property/defaultData";
import { CONFIRM_DEFAULTS } from "./steps/ReviewConfirm/defaultData";
import SOLICITOR_DEFAULTS from "./steps/SolicitorDetails/defaultData";

export const DEFAULT_FORM_DATA = {
  ...BROKER_DEFAULTS,
  ...{
    numberOfApplicants: 1,
  },
  ...APPLICANT_DETAILS_DEFAULTS,
  ...APPLICANT_DECLARATIONS_DEFAULTS,
  ...APPLICANT_EMPLOYMENT_DEFAULTS,
  ...APPLICANT_EXPENDITURE_DEFAULTS,
  ...LOAN_DEFAULTS,
  ...PROPERTY_DEFAULTS,
  ...OTHER_DEFAULTS,
  ...SOLICITOR_DEFAULTS,
  ...CONFIRM_DEFAULTS,
};
