import CTAButton from "components/CTA/Button";
import { useState } from "react";

interface ActionModalProps {
  show: boolean;
  title: string;
  description?: string;
  cancelText?: string;
  proceedText?: string;
  onCancel?: () => void;
  onProceed?: () => void;
}

const ActionModal: React.FC<ActionModalProps> = ({
  show,
  title,
  description,
  cancelText,
  proceedText,
  onCancel,
  onProceed,
}) => {
  const [showModal] = useState(show);

  const handleCancel = () => onCancel && onCancel();
  const handleProceed = () => onProceed && onProceed();

  return (
    <>
      {showModal && (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
            <div className="relative mx-auto my-6 w-auto max-w-2xl">
              {/*content*/}
              <div className="relative flex w-full flex-col rounded-lg border-0 bg-white px-5 py-5 shadow-lg outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between rounded-t border-b border-solid border-slate-200">
                  <h3 className="text-xl font-semibold">{title}</h3>
                  <button
                    className="float-right ml-auto border-0 bg-transparent p-1 text-3xl font-semibold leading-none text-black outline-none focus:outline-none"
                    onClick={handleCancel}
                  >
                    <span className="block h-8 w-8 bg-transparent text-3xl text-black outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative flex-auto py-3">
                  <p>
                    {description ||
                      "Are you sure you want to perform this action?"}
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end rounded-b border-t border-solid border-slate-200 pb-1 pt-3">
                  <CTAButton
                    secondary
                    label={cancelText || "Cancel"}
                    onClick={handleCancel}
                  />
                  <div className="ml-4">
                    <CTAButton
                      label={proceedText || "Proceed"}
                      onClick={handleProceed}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      )}
    </>
  );
};

export default ActionModal;
