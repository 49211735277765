import React from "react";

import Loading from "components/Loading";
import PageContent from "components/Page/Content";

interface GetProgressProps {
  getApplicationLoading: boolean;
}

const GetApplicationProgress: React.FC<GetProgressProps> = ({
  getApplicationLoading,
}) => {
  return (
    <PageContent title="Application Form">
      <div className="flex w-full flex-col md:flex-row md:space-x-8">
        <div className="w-full rounded-lg border border-gray-200 bg-white p-6 text-center md:p-8">
          {getApplicationLoading ? (
            <>
              <Loading />
              <p className="mt-4">Loading Application...</p>
            </>
          ) : (
            <>
              <p>Sorry, there has been an error loading your Application.</p>
              <p className="mt-2">Please contact us to discuss this further.</p>
            </>
          )}
        </div>
      </div>
    </PageContent>
  );
};

export default GetApplicationProgress;
