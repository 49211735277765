import { gql } from "@apollo/client";

export const QUERY_ILLUSTRATION = gql`
  query Illustration($uuid: String!) {
    illustration(uuid: $uuid) {
      uuid
      reference
      createdOn
      downloadUrl
      case {
        uuid
        status
        reference
      }
    }
  }
`;
