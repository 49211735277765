import { XCircleIcon } from "@heroicons/react/20/solid";

type Props = {
  title?: string;
  children: JSX.Element;
};

export default function AlertError({ title, children }: Props) {
  return (
    <div className="rounded-md border border-red-800 bg-red-100 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-800" aria-hidden="true" />
        </div>
        <div className="-mt-0.5 ml-3 text-base text-red-800">
          {title && <p className="text-base font-medium">{title}</p>}
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
}
