import React from "react";
import {
  Control,
  useFieldArray,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";

import CTAButton from "components/CTA/Button";
import AddressSetter from "components/Form/AddressSetter";
import FormInputNumber from "components/Form/Input/Number";
import FormInputSelect from "components/Form/Input/Select";
import { ComonObjectType } from "ts/types";
import { LABELS } from "./labels";
import { ApplicantAddressResidentialStatuses } from "./options";

interface StepProps {
  control: Control<any, any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  clearErrors: UseFormClearErrors<any>;
  idKey: string;
  errorKey: ComonObjectType | undefined;
}

const STEP_KEY = "previousAddresses";

const PreviousAddress: React.FC<StepProps> = ({
  control,
  register,
  setValue,
  clearErrors,
  idKey,
  errorKey,
}) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `${idKey}.${STEP_KEY}`,
  });

  const applicantsPreviousAddresses = useWatch({
    name: `${idKey}.${STEP_KEY}`,
    control: control,
  });

  return (
    <div className="flex flex-col space-y-4">
      <label className="block text-base">
        If they have lived for less than 3 years in that address, please list
        below all the other addresses they have lived in the past 3 years:
      </label>

      {fields.map((item, k) => {
        return (
          <div key={item.id} className="flex flex-col space-y-4">
            <label className="block text-base font-semibold">
              Previous address {k + 1}
            </label>

            <input
              {...register(`${idKey}.${STEP_KEY}.${k}.uuid`, {
                value: applicantsPreviousAddresses?.[k]?.uuid,
              })}
              type="hidden"
            />

            <AddressSetter
              clearErrors={clearErrors}
              setValue={setValue}
              register={register}
              idKey={`${idKey}.${STEP_KEY}.${k}`}
              errorKey={errorKey?.[STEP_KEY]?.[k]}
            />

            <FormInputNumber
              id={`${idKey}.${STEP_KEY}.${k}.stayDurationYears`}
              label={LABELS.address.stayDurationYears}
              register={register}
              requiredField={false}
              error={errorKey?.[STEP_KEY]?.[k]?.stayDurationYears?.message}
              maxDigits={4}
            />

            <FormInputNumber
              id={`${idKey}.${STEP_KEY}.${k}.stayDurationMonths`}
              label={LABELS.address.stayDurationMonths}
              register={register}
              requiredField={false}
              error={errorKey?.[STEP_KEY]?.[k]?.stayDurationMonths?.message}
              maxDigits={2}
            />

            <FormInputSelect
              id={`${idKey}.${STEP_KEY}.${k}.residentialStatus`}
              label={LABELS.address.residentialStatus}
              register={register}
              initSelect
              options={ApplicantAddressResidentialStatuses}
              requiredField={false}
              error={errorKey?.[STEP_KEY]?.[k]?.residentialStatus?.message}
            />

            <div className="mb-8 sm:col-span-6">
              <div className="flex justify-start space-x-4">
                <CTAButton secondary label="Delete" onClick={() => remove(k)} />
              </div>
            </div>
          </div>
        );
      })}

      <div className="mb-8 sm:col-span-6">
        <div className="flex justify-start space-x-4">
          <CTAButton
            secondary
            label="Add address"
            onClick={() =>
              append({
                address: "",
                stayDurationYears: undefined,
                stayDurationMonths: undefined,
                residentialStatus: "",
                buildingName: "",
                buildingNumber: "",
                subBuildingName: "",
                subBuildingNumber: "",
                thoroughfare: "",
                addressLine1: "",
                addressLine2: "",
                addressLine3: "",
                townOrCity: "",
                county: "",
                postcode: "",
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default PreviousAddress;
