import * as Yup from "yup";

export const VALIDATION_REQUIRED = "This field is required";
export const VALIDATION_EMAIL = "Please input a valid email address";
export const VALIDATION_PHONE_NUMBER = "Please input a valid phone number";
export const VALIDATION_DATE = "Please input a valid date";
export const VALIDATION_PERCENT = "Please input a valid percent value";
export const VALIDATION_POSTCODE = "Please input a valid postcode";
export const INVALID_RESPONSE_MSG = "An answer is required for this question";

export const POSTCODE_REGEX = /^[A-Za-z]{1,2}\d[A-Za-z\d]? ?\d[A-Za-z]{2}$/;
export const PHONE_NUMBER_REGEX =
  /^(?!.*(\d)\1{5})((((\+44\s?([0–6]|[8–9])\d{3}|\(?0([0–6]|[8–9])\d{3}\)?)\s?\d{3}\s?(\d{2}|\d{3}))|((\+44\s?([0–6]|[8–9])\d{3}|\(?0([0–6]|[8–9])\d{3}\)?)\s?\d{3}\s?(\d{4}|\d{3}))|((\+44\s?([0–6]|[8–9])\d{1}|\(?0([0–6]|[8–9])\d{1}\)?)\s?\d{4}\s?(\d{4}|\d{3}))|((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4})))(?:[\s-]?(?:x|ext\.?|#)\d{3,4})?$/;

export interface NewPasswordFormInputs {
  newPassword1: string;
  newPassword2?: string;
}

export const validateConfirmPassword = (passwordField: string) =>
  Yup.string().test(
    "passwords-match",
    "Passwords must match",
    function (value) {
      return this.parent[passwordField] === value;
    },
  );

export const newPasswordSchema = Yup.object({
  newPassword1: Yup.string().required(VALIDATION_REQUIRED),
  newPassword2: validateConfirmPassword("newPassword1"),
});

/**
 * Check that a given value matches a defined True type
 * @param {any} value
 */
export const checkTruthyValue = (value: any) => {
  const trueTypes = [true, "True", "true"];
  return trueTypes.includes(value);
};

/**
 * Check that a given value matches a defined False type
 * @param {any} value
 */
export const checkFalseyValue = (value: any) => {
  const falseTypes = [false, "False", "false"];
  return falseTypes.includes(value);
};

export const pastDateValidation = Yup.string()
  .test("valid-past-date", VALIDATION_DATE, (value: any) => {
    if (!value) {
      return true;
    }
    if (value.length < 8) {
      return true;
    }
    const inputDate = new Date(value);
    const today = new Date();
    return inputDate <= today; // Check that the date is not in the future
  })
  .typeError(VALIDATION_DATE)
  .nullable();

export const futureDateValidation = Yup.string()
  .test("valid-future-date", VALIDATION_DATE, (value: any) => {
    if (!value) {
      return true;
    }
    if (value.length < 8) {
      return true;
    }
    const inputDate = new Date(value);
    const today = new Date();
    return inputDate >= today; // Check that is in the future
  })
  .typeError(VALIDATION_DATE)
  .nullable();

export const percentValueValidation = Yup.string()
  .nullable()
  .test("is-decimal", VALIDATION_PERCENT, (value) => {
    if (!value) {
      return true;
    }
    if (value.includes(".")) {
      if (!(value + "").match(/^[0-9]*\.[0-9]{1,2}$/)) {
        return false;
      }
    }
    const _valAsFloat = parseFloat(value);
    if (_valAsFloat < 0 || _valAsFloat > 100) {
      return false;
    }
    return true;
  });

export const nullableNumberValidation = Yup.number()
  .nullable()
  .transform((_, val) => (val === Number(val) ? val : null));
