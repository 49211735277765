import React from "react";
import { useWatch } from "react-hook-form";

import FormInputRadio from "components/Form/Input/Radio";
import FormInputTextArea from "components/Form/Input/TextArea";
import FormTitle from "components/Form/Title";
import FormErrors from "pages/ApplicationForm/components/FormErrors";
import { getApplicantName } from "pages/ApplicationForm/utils";
import { StepProps } from "../../types";
import { LABELS } from "./labels";

const STEP_KEY = "declarations";

const ApplicantDeclarationsForm: React.FC<StepProps> = ({
  control,
  register,
  errors,
  applicantsFieldArray,
}) => {
  const declarations = useWatch({ name: STEP_KEY, control: control });

  return (
    <div className="flex flex-col space-y-4">
      <FormTitle left border title="Applicant Declarations" mb={false} />

      <FormErrors formErrors={errors?.[STEP_KEY]} />

      {!applicantsFieldArray ||
      (applicantsFieldArray && applicantsFieldArray?.length === 0) ? (
        <p className="pt-4 text-center">
          Please first submit Applicant Details to complete this step.
        </p>
      ) : (
        applicantsFieldArray.map((applicant, k) => {
          const applicantNumber = k + 1;

          return (
            <div key={applicant.id} className="pb-4 last:pb-0">
              <FormTitle
                left
                title={`Applicant: ${
                  getApplicantName(applicant) || applicantNumber
                }`}
              />

              <input
                {...register(`${STEP_KEY}.${k}.applicantId`, {
                  value: applicant.uuid,
                })}
                type="hidden"
              />

              <div className="flex flex-col space-y-4">
                <FormInputRadio
                  id={`${STEP_KEY}.${k}.hadPropertyReprocessed`}
                  label={LABELS.hadPropertyReprocessed}
                  register={register}
                  requiredField={false}
                  initValue={declarations?.[k]?.hadPropertyReprocessed}
                  error={
                    errors?.[STEP_KEY]?.[k]?.hadPropertyReprocessed?.message
                  }
                />

                <FormInputRadio
                  id={`${STEP_KEY}.${k}.hadCcj`}
                  label={LABELS.hadCcj}
                  register={register}
                  requiredField={false}
                  initValue={declarations?.[k]?.hadCcj}
                  error={errors?.[STEP_KEY]?.[k]?.hadCcj?.message}
                />

                <FormInputRadio
                  id={`${STEP_KEY}.${k}.ccjsGreaterThan500Last3years`}
                  label={LABELS.ccjsGreaterThan500Last3years}
                  register={register}
                  requiredField={false}
                  initValue={declarations?.[k]?.ccjsGreaterThan500Last3years}
                  error={
                    errors?.[STEP_KEY]?.[k]?.ccjsGreaterThan500Last3years
                      ?.message
                  }
                />

                <FormInputRadio
                  id={`${STEP_KEY}.${k}.moreThanOneDefaultLast3years`}
                  label={LABELS.moreThanOneDefaultLast3years}
                  register={register}
                  requiredField={false}
                  initValue={declarations?.[k]?.moreThanOneDefaultLast3years}
                  error={
                    errors?.[STEP_KEY]?.[k]?.moreThanOneDefaultLast3years
                      ?.message
                  }
                />

                <FormInputRadio
                  id={`${STEP_KEY}.${k}.hasBeenBankrupt`}
                  label={LABELS.hasBeenBankrupt}
                  register={register}
                  requiredField={false}
                  initValue={declarations?.[k]?.hasBeenBankrupt}
                  error={errors?.[STEP_KEY]?.[k]?.hasBeenBankrupt?.message}
                />

                <FormInputRadio
                  id={`${STEP_KEY}.${k}.hasDebtRegistered`}
                  label={LABELS.hasDebtRegistered}
                  register={register}
                  requiredField={false}
                  initValue={declarations?.[k]?.hasDebtRegistered}
                  error={errors?.[STEP_KEY]?.[k]?.hasDebtRegistered?.message}
                />

                <FormInputRadio
                  id={`${STEP_KEY}.${k}.hasMadeCreditorsArrangement`}
                  label={LABELS.hasMadeCreditorsArrangement}
                  register={register}
                  requiredField={false}
                  initValue={declarations?.[k]?.hasMadeCreditorsArrangement}
                  error={
                    errors?.[STEP_KEY]?.[k]?.hasMadeCreditorsArrangement
                      ?.message
                  }
                />

                <FormInputRadio
                  id={`${STEP_KEY}.${k}.hasUnsatisfiedDefaults`}
                  label={LABELS.hasUnsatisfiedDefaults}
                  register={register}
                  requiredField={false}
                  initValue={declarations?.[k]?.hasUnsatisfiedDefaults}
                  error={
                    errors?.[STEP_KEY]?.[k]?.hasUnsatisfiedDefaults?.message
                  }
                />

                <FormInputRadio
                  id={`${STEP_KEY}.${k}.defaultsGreaterThan10012monthsOr5003years`}
                  label={LABELS.defaultsGreaterThan10012monthsOr5003years}
                  register={register}
                  requiredField={false}
                  initValue={
                    declarations?.[k]?.defaultsGreaterThan10012monthsOr5003years
                  }
                  error={
                    errors?.[STEP_KEY]?.[k]
                      ?.defaultsGreaterThan10012monthsOr5003years?.message
                  }
                />

                <FormInputRadio
                  id={`${STEP_KEY}.${k}.hasFailedCreditAgreements`}
                  label={LABELS.hasFailedCreditAgreements}
                  register={register}
                  requiredField={false}
                  initValue={declarations?.[k]?.hasFailedCreditAgreements}
                  error={
                    errors?.[STEP_KEY]?.[k]?.hasFailedCreditAgreements?.message
                  }
                />

                <FormInputRadio
                  id={`${STEP_KEY}.${k}.missed2OrMoreCreditPaymentsLast6months`}
                  label={LABELS.missed2OrMoreCreditPaymentsLast6months}
                  register={register}
                  requiredField={false}
                  initValue={
                    declarations?.[k]?.missed2OrMoreCreditPaymentsLast6months
                  }
                  error={
                    errors?.[STEP_KEY]?.[k]
                      ?.missed2OrMoreCreditPaymentsLast6months?.message
                  }
                />

                <FormInputTextArea
                  id={`${STEP_KEY}.${k}.declarationComment`}
                  label={LABELS.declarationComment}
                  register={register}
                  requiredField={false}
                  error={errors?.[STEP_KEY]?.[k]?.declarationComment?.message}
                />
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default ApplicantDeclarationsForm;
