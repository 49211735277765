export const ApplicantEmploymentDetailStatuses = [
  {
    value: "EMPL",
    title: "Employed",
  },
  {
    value: "SEMP",
    title: "Self employed (Partner)",
  },
  {
    value: "SEMS",
    title: "Self employed (Sole Trader)",
  },
  {
    value: "DNOD",
    title:
      "Director or Shareholder less than 25% share, no dividends or share of net profits",
  },
  {
    value: "DWDV",
    title:
      "Director or Shareholder less than 25% share, getting dividends or share of net profits",
  },
  {
    value: "DMDV",
    title: "Director or Shareholder greater than or equal to 25% share",
  },
  {
    value: "RTRD",
    title: "Retired",
  },
  {
    value: "HMKR",
    title: "Homemaker",
  },
  {
    value: "STUD",
    title: "Student",
  },
  {
    value: "UEMP",
    title: "Not employed",
  },
];

export const ApplicantEmploymentTypeStatuses = [
  {
    value: "FTC",
    title: "Fixed term contract",
  },
  {
    value: "PERM",
    title: "Permanent",
  },
  {
    value: "SCFT",
    title: "Sub-contractor fixed term",
  },
  {
    value: "SCOE",
    title: "Sub-contractor open ended",
  },
  {
    value: "ZHC",
    title: "Zero hours contract",
  },
  {
    value: "TEMP",
    title: "Temporary",
  },
  {
    value: "O",
    title: "Other",
  },
];

export const ApplicantEmploymentOtherIncomeSources = [
  {
    value: "ATA",
    title: "Attendance Allowance",
  },
  {
    value: "ADH",
    title: "Additional Duty Hours (ADH)",
  },
  {
    value: "ADA",
    title: "Adoption Allowance",
  },
  {
    value: "BUR",
    title: "Bursary",
  },
  {
    value: "CAL",
    title: "Carers Allowance",
  },
  {
    value: "CAA",
    title: "Constant Attendance Allowance",
  },
  {
    value: "CB",
    title: "Child Benefit",
  },
  {
    value: "CTC",
    title: "Child Tax Credit",
  },
  {
    value: "DLA",
    title: "Disability living Allowance",
  },
  {
    value: "ESA",
    title: "Employment & Support Allowance",
  },
  {
    value: "FB",
    title: "Flexible Benefit",
  },
  {
    value: "FPA",
    title: "Flight Pay/Allowance",
  },
  {
    value: "FCA",
    title: "Foster Care Allowance",
  },
  {
    value: "GA",
    title: "Guardian Allowance",
  },
  {
    value: "IIDB",
    title: "Industrial Injuries Disablement Benefit",
  },
  {
    value: "II",
    title: "Investment income",
  },
  {
    value: "MNT",
    title: "Maintenance",
  },
  {
    value: "MP",
    title: "Maternity/Paternity/Adoption Pay",
  },
  {
    value: "MS",
    title: "Mortgage Subsidy",
  },
  {
    value: "NHSB",
    title: "NHS Bank",
  },
  {
    value: "PIP",
    title: "Personal Independence Payment",
  },
  {
    value: "PENC",
    title: "Pension - Credit",
  },
  {
    value: "PENP",
    title: "Pension - Private",
  },
  {
    value: "PENS",
    title: "Pension - State",
  },
  {
    value: "PENWD",
    title: "Pension - War Disablement",
  },
  {
    value: "PENW",
    title: "Pension - War Widow(er)",
  },
  {
    value: "RI",
    title: "Rental Income",
  },
  {
    value: "SA",
    title: "Shift Allowance",
  },
  {
    value: "STIP",
    title: "Stipend",
  },
  {
    value: "TACA",
    title: "Town, Area or Car Allowance",
  },
  {
    value: "WPA",
    title: "Widowed Parent Allowance (including Widow's Pension)",
  },
  {
    value: "WTC",
    title: "Working Tax Credits",
  },
];
