import { setDataForApi } from "utils/api";
import { FormSchemaOtherDetails } from "./types";

const parseDataForApi = (data: FormSchemaOtherDetails) => {
  const _data = { ...data } as FormSchemaOtherDetails;
  const { otherResidentsDetail } = _data;

  if (otherResidentsDetail) {
    otherResidentsDetail.forEach((res) => {
      setDataForApi(res);
    });
  }

  setDataForApi(_data);

  return _data;
};

export default parseDataForApi;
