import { parseApplicantDetail } from "components/Application/Form/ApplicantDetails/api";
import { FormSchemaApplicantDetails } from "./types";

const parseDataForApi = (data: Array<FormSchemaApplicantDetails>) => {
  if (!data) {
    return data;
  }

  const applicantsDetail = Object.values(data).map((applicant) => {
    parseApplicantDetail(applicant);
    return applicant;
  });

  return applicantsDetail;
};

export default parseDataForApi;
