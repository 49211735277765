import { classNames } from "utils/helpers";

type Props = {
  id?: string;
  label?: string;
  light?: boolean;
};

export default function FormInputLabel({
  id = "",
  label = "",
  light = false,
}: Props) {
  return (
    <div>
      <label
        htmlFor={id}
        className={classNames(
          light ? "text-brand-copy-lighter" : "",
          "block text-base",
        )}
      >
        {label}
      </label>
    </div>
  );
}
