import * as Yup from "yup";

import declarationsValidation from "./steps/ApplicantDeclarations/validation";
import applicantsValidation from "./steps/ApplicantDetails/validation";
import employmentValidation from "./steps/ApplicantEmployment/validation";
import expenditureValidation from "./steps/ApplicantExpenditure/validation";
import brokerValidation from "./steps/BrokerDetails/validation";
import loanValidation from "./steps/Loan/validation";
import otherValidation from "./steps/OtherDetails/validation";
import propertyValidation from "./steps/Property/validation";
import confirmValidation from "./steps/ReviewConfirm/validation";
import solicitorValidation from "./steps/SolicitorDetails/validation";

export const validationSchema: Yup.ObjectSchema<any> = Yup.object({
  numberOfApplicants: Yup.number(),
  broker: brokerValidation,
  applicants: applicantsValidation,
  declarations: declarationsValidation,
  employments: employmentValidation,
  expenditures: expenditureValidation,
  loan: loanValidation,
  property: propertyValidation,
  other: otherValidation,
  solicitor: solicitorValidation,
  confirm: confirmValidation,
});
