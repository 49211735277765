export const DEFAULT_APPLICANT_DATA = {
  title: "",
  forename: "",
  middleName: "",
  surname: "",
  dob: "",
  previousName: "",
  dateOfChange: "",
  phoneNumber: "",
  email: "",
  currentAddress: {
    address: "",
    stayDurationYears: undefined,
    stayDurationMonths: undefined,
    residentialStatus: "",
    buildingName: "",
    buildingNumber: "",
    subBuildingName: "",
    subBuildingNumber: "",
    thoroughfare: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    townOrCity: "",
    county: "",
    postcode: "",
  },
  previousAddresses: undefined,
};
