import React from "react";
import {
  Control,
  FieldErrors,
  useFieldArray,
  UseFormRegister,
  useWatch,
} from "react-hook-form";

import CTAButton from "components/CTA/Button";
import FormInputDate from "components/Form/Input/Date";
import FormInputText from "components/Form/Input/Text";
import { FormSchema } from "pages/ApplicationForm/formSchema";
import { LABELS } from "./labels";

interface StepProps {
  errors: FieldErrors<FormSchema>;
  control: Control<FormSchema, any>;
  register: UseFormRegister<FormSchema>;
}

const PARENT_KEY = "other";
const STEP_KEY = "otherResidentsDetail";

const OtherResidentsDetail: React.FC<StepProps> = ({
  errors,
  control,
  register,
}) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `${PARENT_KEY}.${STEP_KEY}`,
  });

  const residentDetail = useWatch({
    name: `${PARENT_KEY}.${STEP_KEY}`,
    control: control,
  });

  return (
    <div>
      {fields.map((item, k) => {
        return (
          <div key={item.id}>
            <input
              {...register(`${PARENT_KEY}.${STEP_KEY}.${k}.uuid`, {
                value: residentDetail?.[k]?.uuid,
              })}
              type="hidden"
            />

            <div className="mb-1 font-bold">{`Resident ${k + 1}`}</div>
            <div className="mt-0 grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-6 md:gap-y-6">
              <div className="col-span-1 sm:col-span-2">
                <FormInputText
                  id={`${PARENT_KEY}.${STEP_KEY}.${k}.name`}
                  label={LABELS.otherResidentsDetail.name}
                  register={register}
                  requiredField={false}
                  error={errors?.[PARENT_KEY]?.[STEP_KEY]?.[k]?.name?.message}
                />
              </div>
              <div className="col-span-1 sm:col-span-2">
                <FormInputText
                  id={`${PARENT_KEY}.${STEP_KEY}.${k}.relationship`}
                  label={LABELS.otherResidentsDetail.relationship}
                  register={register}
                  requiredField={false}
                  error={
                    errors?.[PARENT_KEY]?.[STEP_KEY]?.[k]?.relationship?.message
                  }
                />
              </div>
              <div className="col-span-1 sm:col-span-2">
                <FormInputDate
                  id={`${PARENT_KEY}.${STEP_KEY}.${k}.dob`}
                  label={LABELS.otherResidentsDetail.dob}
                  register={register}
                  requiredField={false}
                  error={errors?.[PARENT_KEY]?.[STEP_KEY]?.[k]?.dob?.message}
                />
              </div>
              <div className="col-span-1 mt-2 sm:col-span-6 md:-mt-2">
                <div className="mb-4 flex justify-start space-x-4">
                  {fields.length > 1 && (
                    <CTAButton
                      secondary
                      label="Delete"
                      onClick={() => remove(k)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}

      <div className="mb-8 sm:col-span-6">
        <div className="flex justify-start space-x-4">
          <CTAButton
            secondary
            label="Add resident"
            onClick={() =>
              append({
                name: "",
                relationship: "",
                dob: "",
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default OtherResidentsDetail;
