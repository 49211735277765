import { STYLE_INPUT_MARGIN_TOP } from "components/Form/Input/consts";
import FormInputError from "components/Form/Input/Error";
import FormInputLabel from "components/Form/Input/Label";
import { FormInputProps } from "components/Form/Input/types";
import { useEffect, useState } from "react";

export default function FormInputRadio({
  id,
  label,
  error,
  initValue,
  requiredField = true,
  register,
}: FormInputProps) {
  const [defaultVal, setDefaultVal] = useState<string>("");

  useEffect(() => {
    if (!defaultVal && initValue !== undefined) {
      if (initValue === null) {
        setDefaultVal("");
      } else {
        setDefaultVal(initValue.toString());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === null) {
      setDefaultVal("");
    } else {
      setDefaultVal(e.target.value.toString());
    }
  };

  return (
    <div>
      <FormInputLabel id={id} label={label} />
      <div className={STYLE_INPUT_MARGIN_TOP}>
        <div className="space-y-6 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
          <div className="flex items-center">
            <input
              {...register(id, {
                required: requiredField,
                onChange: handleChange,
              })}
              id={`${id}-yes`}
              type="radio"
              className="h-4 w-4 border-gray-300 text-brand-orange-medium focus:ring-brand-orange-medium"
              value="true"
              checked={defaultVal === "true"}
            />
            <label htmlFor={`${id}-yes`} className="ml-2 block">
              Yes
            </label>
          </div>
          <div className="flex items-center">
            <input
              {...register(id, {
                required: requiredField,
                onChange: handleChange,
              })}
              id={`${id}-no`}
              type="radio"
              className="h-4 w-4 border-gray-300 text-brand-orange-medium focus:ring-brand-orange-medium"
              value="false"
              checked={defaultVal === "false"}
            />
            <label htmlFor={`${id}-no`} className="ml-2 block">
              No
            </label>
          </div>
        </div>
        <FormInputError error={error} />
      </div>
    </div>
  );
}
