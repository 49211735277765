import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useLazyQuery } from "@apollo/client";
import { QUERY_DIP } from "api/queries/dip";
import FormTitle from "components/Form/Title";
import Loading from "components/Loading";
import PageContent from "components/Page/Content";
import { APP_URLS } from "settings";
import useApplicationStore from "store/Application";

const DipComplete: React.FC = () => {
  let navigate = useNavigate();

  const { dipId: urlDipId } = useParams<{
    dipId: string;
  }>();

  const [dip, setDip] = useState<any | null>(null);

  const { setAppllicationCase, resetApplication } = useApplicationStore();

  const [
    getDip,
    { data: backendDip, loading: getDipLoading, error: getDipError },
  ] = useLazyQuery(QUERY_DIP, { fetchPolicy: "no-cache" });

  useEffect(() => {
    if (urlDipId) {
      getDip({
        variables: {
          uuid: urlDipId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlDipId, getDip]);

  useEffect(() => {
    if (!backendDip) {
      return;
    }
    setDip(backendDip?.dip);
  }, [backendDip, setDip]);

  const startNewApplication = () => {
    resetApplication();

    if (dip?.case) {
      setAppllicationCase({
        reference: dip.case.reference,
        uuid: dip.case.uuid,
      });
    }
    navigate(APP_URLS.APPLICATION_FORM);
  };

  return (
    <PageContent title="Application Form">
      <div className="mx-auto max-w-3xl space-y-5">
        <div className="rounded-lg border border-gray-200 bg-white p-6 md:p-8">
          {getDipLoading && (
            <>
              <Loading />
              <p className="mt-4">Submitting Decision in Principle...</p>
            </>
          )}

          {getDipError && (
            <>
              <p>
                Sorry, there has been an error submitting your Decision in
                Principle.
              </p>
              <p className="mt-2">Please contact us to discuss this further.</p>
            </>
          )}

          {dip && (
            <div className="space-y-4">
              <FormTitle
                left
                border
                title="The Decision in Principle has been generated"
                mb={false}
              />

              <div className="space-y-2">
                <p>
                  View and download the Decision in Principle:{" "}
                  <a
                    download
                    href={dip?.downloadUrl}
                    className="link underline"
                  >
                    Download
                  </a>
                </p>
                <p>
                  To continue with the application process, please submit an
                  application form. You can find it{" "}
                  <button
                    onClick={startNewApplication}
                    className="link underline"
                  >
                    here
                  </button>
                  .
                </p>

                {dip?.case?.reference && (
                  <>
                    <p>
                      You can track your Case ({dip.case.reference}) attached to
                      this Decision in Principle{" "}
                      <Link
                        to={`${APP_URLS.DASHBOARD}cases/${dip.case.uuid}`}
                        className="link underline"
                      >
                        here
                      </Link>
                      .
                    </p>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </PageContent>
  );
};

export default DipComplete;
