import { useLocation, Navigate, Outlet } from "react-router-dom";

import { APP_URLS } from "settings";

/**
 * Route authenticated users to requested page, else to Login page
 */
export default function RouteRequireAuth({
  authenticated,
}: {
  authenticated: boolean;
}) {
  let location = useLocation();

  if (!authenticated) {
    const navigateTo = APP_URLS.LOGIN;
    return <Navigate to={navigateTo} state={{ from: location }} />;
  }

  return <Outlet />;
}
