import React from "react";

import Loading from "components/Loading";

interface SubmitProgressProps {
  submitDipError: any;
}

const SubmitDipProgress: React.FC<SubmitProgressProps> = ({
  submitDipError,
}) => {
  if (submitDipError) {
    return (
      <>
        <p>
          Sorry, there has been an error submitting your Decision in Principle.
        </p>
        <p className="mt-2">Please contact us to discuss this further.</p>
      </>
    );
  }

  return (
    <>
      <Loading />
      <p className="mt-4">Submitting Decision in Principle...</p>
    </>
  );
};

export default SubmitDipProgress;
