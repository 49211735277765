import React from "react";

import ApplicantEmploymentFormFields from "components/Application/Form/ApplicantEmployment";
import FormTitle from "components/Form/Title";
import FormErrors from "pages/ApplicationForm/components/FormErrors";
import { getApplicantName } from "pages/ApplicationForm/utils";
import { StepProps } from "../../types";

const STEP_KEY = "employments";

const ApplicantEmploymentForm: React.FC<StepProps> = ({
  control,
  register,
  errors,
  setValue,
  clearErrors,
  applicantsFieldArray,
}) => {
  return (
    <div className="flex flex-col space-y-4">
      <FormTitle left border title="Applicant Employment" mb={false} />

      <FormErrors formErrors={errors?.[STEP_KEY]} />

      {!applicantsFieldArray ||
      (applicantsFieldArray && applicantsFieldArray?.length === 0) ? (
        <p className="pt-4 text-center">
          Please first submit Applicant Details to complete this step.
        </p>
      ) : (
        applicantsFieldArray.map((applicant, k) => {
          const applicantNumber = k + 1;
          const idKey = `${STEP_KEY}.${k}`;
          const errorKey = errors?.[STEP_KEY]?.[k];

          return (
            <div key={applicant.id} className="pb-4 last:pb-0">
              <FormTitle
                left
                title={`Applicant: ${
                  getApplicantName(applicant) || applicantNumber
                }`}
              />

              <input
                {...register(`${STEP_KEY}.${k}.applicantId`, {
                  value: applicant.uuid,
                })}
                type="hidden"
              />

              <ApplicantEmploymentFormFields
                register={register}
                control={control}
                idKey={idKey}
                errorKey={errorKey}
                clearErrors={clearErrors}
                setValue={setValue}
              />
            </div>
          );
        })
      )}
    </div>
  );
};

export default ApplicantEmploymentForm;
