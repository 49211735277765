import React from "react";
import {
  Control,
  useFieldArray,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";

import { DEFAULT_CREDIT_PROVIDER_DATA } from "components/Application/Form/ApplicantExpenditure/defaultData";
import CTAButton from "components/CTA/Button";
import FormInputCurrency from "components/Form/Input/Currency";
import FormInputDate from "components/Form/Input/Date";
import FormInputRadio from "components/Form/Input/Radio";
import FormInputSelect from "components/Form/Input/Select";
import FormInputText from "components/Form/Input/Text";
import { ComonObjectType } from "ts/types";
import { LABELS } from "./labels";
import { ExpenditureCreditProviderTypes } from "./options";

interface StepProps {
  control: Control<any, any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  idKey: string;
  errorKey: ComonObjectType | undefined;
}

const STEP_KEY = "creditProviders";

const CreditProviders: React.FC<StepProps> = ({
  control,
  register,
  setValue,
  idKey,
  errorKey,
}) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `${idKey}.${STEP_KEY}`,
  });

  const applicantsCredit = useWatch({
    name: `${idKey}.${STEP_KEY}`,
    control: control,
  });

  return (
    <div className="flex flex-col space-y-4">
      {fields.map((item, k) => {
        return (
          <div key={item.id} className="flex flex-col space-y-4">
            <label className="block text-base font-semibold">
              Credit provider {k + 1}
            </label>

            <input
              {...register(`${idKey}.${STEP_KEY}.${k}.uuid`, {
                value: applicantsCredit?.[k]?.uuid,
              })}
              type="hidden"
            />

            <FormInputSelect
              id={`${idKey}.${STEP_KEY}.${k}.providerType`}
              label={LABELS.creditProviders.providerType}
              register={register}
              initSelect
              options={ExpenditureCreditProviderTypes}
              requiredField={false}
              error={errorKey?.[STEP_KEY]?.[k]?.providerType?.message}
            />

            <FormInputText
              id={`${idKey}.${STEP_KEY}.${k}.provider`}
              label={LABELS.creditProviders.provider}
              register={register}
              requiredField={false}
              error={errorKey?.[STEP_KEY]?.[k]?.provider?.message}
            />

            <FormInputCurrency
              id={`${idKey}.${STEP_KEY}.${k}.balanceOutstanding`}
              label={LABELS.creditProviders.balanceOutstanding}
              register={register}
              requiredField={false}
              error={errorKey?.[STEP_KEY]?.[k]?.balanceOutstanding?.message}
              setValue={setValue}
            />

            <FormInputCurrency
              id={`${idKey}.${STEP_KEY}.${k}.monthlyPayment`}
              label={LABELS.creditProviders.monthlyPayment}
              register={register}
              requiredField={false}
              error={errorKey?.[STEP_KEY]?.[k]?.monthlyPayment?.message}
              setValue={setValue}
            />

            <FormInputDate
              id={`${idKey}.${STEP_KEY}.${k}.creditEndDate`}
              label={LABELS.creditProviders.creditEndDate}
              register={register}
              requiredField={false}
              error={errorKey?.[STEP_KEY]?.[k]?.creditEndDate?.message}
            />

            <FormInputRadio
              id={`${idKey}.${STEP_KEY}.${k}.mortgagePriorPaidOff`}
              label={LABELS.creditProviders.mortgagePriorPaidOff}
              register={register}
              requiredField={false}
              initValue={applicantsCredit?.[k]?.mortgagePriorPaidOff}
              error={errorKey?.[STEP_KEY]?.[k]?.mortgagePriorPaidOff?.message}
            />

            <FormInputRadio
              id={`${idKey}.${STEP_KEY}.${k}.repayFromMortgage`}
              label={LABELS.creditProviders.repayFromMortgage}
              register={register}
              requiredField={false}
              initValue={applicantsCredit?.[k]?.repayFromMortgage}
              error={errorKey?.[STEP_KEY]?.[k]?.repayFromMortgage?.message}
            />

            {["MM", "OM"].includes(
              applicantsCredit?.[k]?.providerType || "",
            ) && (
              <FormInputRadio
                id={`${idKey}.${STEP_KEY}.${k}.mortgageConvertedToB2l`}
                label={LABELS.creditProviders.mortgageConvertedToB2l}
                register={register}
                requiredField={false}
                initValue={applicantsCredit?.[k]?.mortgageConvertedToB2l}
                error={
                  errorKey?.[STEP_KEY]?.[k]?.mortgageConvertedToB2l?.message
                }
              />
            )}

            <div className="mb-8 sm:col-span-6">
              <div className="flex justify-start space-x-4">
                <CTAButton
                  secondary
                  label={`Delete credit provider ${k + 1}`}
                  onClick={() => remove(k)}
                />
              </div>
            </div>
          </div>
        );
      })}

      <div className="mb-8 sm:col-span-6">
        <div className="flex justify-start space-x-4">
          <CTAButton
            secondary
            label="Add credit"
            onClick={() => append(DEFAULT_CREDIT_PROVIDER_DATA)}
          />
        </div>
      </div>
    </div>
  );
};

export default CreditProviders;
