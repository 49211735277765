import * as Yup from "yup";

import { checkTruthyValue, INVALID_RESPONSE_MSG } from "utils/validation";

const validation = Yup.array().of(
  Yup.object({
    hadPropertyReprocessed: Yup.bool()
      .typeError(INVALID_RESPONSE_MSG)
      .required(INVALID_RESPONSE_MSG),
    hadCcj: Yup.bool()
      .typeError(INVALID_RESPONSE_MSG)
      .required(INVALID_RESPONSE_MSG),
    ccjsGreaterThan500Last3years: Yup.bool()
      .typeError(INVALID_RESPONSE_MSG)
      .required(INVALID_RESPONSE_MSG),
    moreThanOneDefaultLast3years: Yup.bool()
      .typeError(INVALID_RESPONSE_MSG)
      .required(INVALID_RESPONSE_MSG),
    hasBeenBankrupt: Yup.bool()
      .typeError(INVALID_RESPONSE_MSG)
      .required(INVALID_RESPONSE_MSG),
    hasDebtRegistered: Yup.bool()
      .typeError(INVALID_RESPONSE_MSG)
      .required(INVALID_RESPONSE_MSG),
    hasMadeCreditorsArrangement: Yup.bool()
      .typeError(INVALID_RESPONSE_MSG)
      .required(INVALID_RESPONSE_MSG),
    hasUnsatisfiedDefaults: Yup.bool()
      .typeError(INVALID_RESPONSE_MSG)
      .required(INVALID_RESPONSE_MSG),
    defaultsGreaterThan10012monthsOr5003years: Yup.bool()
      .typeError(INVALID_RESPONSE_MSG)
      .required(INVALID_RESPONSE_MSG),
    hasFailedCreditAgreements: Yup.bool()
      .typeError(INVALID_RESPONSE_MSG)
      .required(INVALID_RESPONSE_MSG),
    missed2OrMoreCreditPaymentsLast6months: Yup.bool()
      .typeError(INVALID_RESPONSE_MSG)
      .required(INVALID_RESPONSE_MSG),
    declarationComment: Yup.string().when(
      [
        "hadPropertyReprocessed",
        "hadCcj",
        "ccjsGreaterThan500Last3years",
        "moreThanOneDefaultLast3years",
        "hasBeenBankrupt",
        "hasDebtRegistered",
        "hasMadeCreditorsArrangement",
        "hasUnsatisfiedDefaults",
        "defaultsGreaterThan10012monthsOr5003years",
        "hasFailedCreditAgreements",
        "missed2OrMoreCreditPaymentsLast6months",
      ],
      {
        is: (
          hadPropertyReprocessed: any,
          hadCcj: any,
          ccjsGreaterThan500Last3years: any,
          moreThanOneDefaultLast3years: any,
          hasBeenBankrupt: any,
          hasDebtRegistered: any,
          hasMadeCreditorsArrangement: any,
          hasUnsatisfiedDefaults: any,
          defaultsGreaterThan10012monthsOr5003years: any,
          hasFailedCreditAgreements: any,
          missed2OrMoreCreditPaymentsLast6months: any,
        ) =>
          [
            hadPropertyReprocessed,
            hadCcj,
            ccjsGreaterThan500Last3years,
            moreThanOneDefaultLast3years,
            hasBeenBankrupt,
            hasDebtRegistered,
            hasMadeCreditorsArrangement,
            hasUnsatisfiedDefaults,
            defaultsGreaterThan10012monthsOr5003years,
            hasFailedCreditAgreements,
            missed2OrMoreCreditPaymentsLast6months,
          ].some((val) => checkTruthyValue(val)),
        then: () => Yup.string().required("Please provide more information"),
        otherwise: () => Yup.string().nullable(),
      },
    ),
  }),
);

export default validation;
