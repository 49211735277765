import * as Yup from "yup";

import { checkFalseyValue, INVALID_RESPONSE_MSG } from "utils/validation";

const validation: Yup.ObjectSchema<any> = Yup.object().shape({
  start: Yup.object().shape({
    consentToCreditCheckAndInformationProcessing: Yup.string()
      .transform((_, originalValue) => {
        return !originalValue ? "false" : originalValue;
      })
      .test("test_can_proceed", "", (value, ctx) => {
        if (value && checkFalseyValue(value)) {
          return ctx.createError({
            type: "root",
            message:
              "Sorry, we can not proceed unless you confirm you have received all applicants' consent to a soft credit check and for their information to be processed",
          });
        }
        return true;
      }),
    receivedSuccessfulDipFromFirstChargeLender: Yup.string()
      .transform((_, originalValue) => {
        return !originalValue ? "false" : originalValue;
      })
      .test("test_can_proceed", "", (value, ctx) => {
        if (value && checkFalseyValue(value)) {
          return ctx.createError({
            type: "root",
            message:
              "Sorry, we can not proceed until you have completed a First Charge Lender Decision in Principle",
          });
        }
        return true;
      }),
    numberOfApplicants: Yup.number().required(INVALID_RESPONSE_MSG),
  }),
});

export default validation;
