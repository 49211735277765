import { useQuery } from "@apollo/client";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";

import { QUERY_RESOURCES } from "api/queries/resources";

type ResourceType = {
  name: string;
  url: string;
};

export default function DashboardResources() {
  const { loading, error, data } = useQuery(QUERY_RESOURCES);

  if (loading || error) {
    return <></>;
  }

  const resources = data?.resources || [];

  return (
    <>
      {resources.length > 0 && (
        <div className="shrink-0 grow basis-1/3" aria-labelledby="Resources">
          <h3 className="mb-6 text-left text-xl font-normal lg:ml-16">
            Resources
          </h3>
          <ul className="lg:border-l lg:border-gray-300 lg:pl-16">
            {resources.map((resource: ResourceType, i: string) => {
              return (
                <li
                  key={i}
                  className="mb-4 flex flex-col items-start rounded-md border border-gray-200 bg-white px-6 py-6 text-lg text-brand-copy-primary focus:outline-none"
                >
                  <a
                    href={resource.url}
                    rel="noreferrer"
                    className="flex w-full justify-between text-brand-copy-primary"
                    target="_blank"
                  >
                    <span>{resource.name}</span>
                    <ArrowDownTrayIcon className="h-7 w-7" />
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
}
