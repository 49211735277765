import { gql } from "@apollo/client";

export const QUERY_RESOURCES = gql`
  query {
    resources {
      name
      resourceType
      url
    }
  }
`;
