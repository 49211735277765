export const LABELS = {
  address: "Full address of the property",
  addressLine1: "Address Line 1",
  addressLine2: "Address Line 2",
  addressLine3: "Address Line 3",
  townOrCity: "Town or city",
  county: "County",
  postcode: "Postcode",
  propertyType: "What type of property is this?",
  tenure: "Tenure of property",
  yearsLeftOnLease: "Years left on lease",
  yearPropertyBuilt: "Year property built",
  isNewBuildPurchase: "Is this a new build purchase?",
  nameOfBuilder: "Name of the Builder",
  hasNhbcFnd15Cover: "Does the property have NHBC/Foundation 15 cover?",
  willBePartiallyRented: "Will part of the property be rented out?",
  willBePrimaryResidence: "Will the property be the primary residence?",
  propertyNearBusinessPremises:
    "Is the property above, adjacent or near to retail / business premises?",
  canUseWithoutStructuralChanges:
    "Can the property be used without structural changes?",
  isCouncilFlat: "Is the property a council flat or an ex-council flat?",
  isStudioFlat: "Is the property a studio flat?",
  groundRentIfFlat:
    "If the property is a flat, what is the ground rent (monthly)",
  serviceChargeIfFlat:
    "If the property is a flat, what is the service charge (monthly)",
  noOfFloorsIfFlat: "Number of floors in the block (if property is a flat)",
  flatFloor: "Which floor is the flat on? (if property a flat)",
  noOfBedrooms: "Number of bedrooms",
  councilTaxMonthly: "Council tax (monthly)",
  councilTaxBand: "Council tax band",
  localAuthority: "Local Authority",
  hasOutbuildingOrAnnex: "Does the property have any outbuildings or annexes?",
  useOfOutbuildingOrAnnexDetails:
    "If 'yes', please provide details including their intended us",
};
