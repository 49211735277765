const DEFAULT_FORM_DATA = {
  other: {
    numberOfFinancialDependents: undefined,
    numberOfFinancialDependentsUnder17: undefined,
    dependentsUnder17LivingAtProperty: undefined,
    numberOfFinancialDependentsOver17: undefined,
    othersLivingInPropertyBesideApplicant: undefined,
    anyResidentsMemberOfApplicantsFamily: undefined,
    anyResidentsProvidingFundsTowardsTransaction: undefined,
    anyResidentsFinanciallyDependentOnApplicant: undefined,
    howManyResidentsFinanciallyDependentOnApplicant: undefined,
    isThisPrivateSale: undefined,
    isVendorRelatedToApplicant: undefined,
    vacantPossessionOnCompletion: undefined,
    isPurchasePriceLessThanMarketValue: undefined,
    otherResidentsDetail: [
      {
        name: "",
        relationship: "",
        dob: "",
      },
    ],
  },
};

export default DEFAULT_FORM_DATA;
