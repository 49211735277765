import React from "react";

import ApplicantDetailsForm from "./steps/ApplicantDetails";
import ApplicantEmploymentForm from "./steps/ApplicantEmployment";
import ApplicantExpenditureForm from "./steps/ApplicantExpenditure";
import LoanForm from "./steps/Loan";
import StartForm from "./steps/Start";

export type StepType = {
  id: number;
  key: "start" | "applicants" | "employments" | "expenditures" | "loan";
  name: string;
  component: React.FC<any>;
};

export const STEPS: { [k: number]: StepType } = {
  1: {
    id: 1,
    key: "start",
    name: "Start",
    component: StartForm,
  },
  2: {
    id: 2,
    key: "applicants",
    name: "Applicants",
    component: ApplicantDetailsForm,
  },
  3: {
    id: 3,
    key: "employments",
    name: "Employment",
    component: ApplicantEmploymentForm,
  },
  4: {
    id: 4,
    key: "expenditures",
    name: "Expenditure",
    component: ApplicantExpenditureForm,
  },
  5: {
    id: 5,
    key: "loan",
    name: "Loan",
    component: LoanForm,
  },
};

export const DEFAULT_STEP: StepType = STEPS[1];

export const TOTAL_STEPS = Object.keys(STEPS).length;

export const getStepByID = (stepID: number): StepType =>
  Object.values(STEPS).find((step) => step.id === stepID) || DEFAULT_STEP;

export const getStepByKey = (stepKey: string): StepType =>
  Object.values(STEPS).find((step) => step.key === stepKey) || DEFAULT_STEP;
