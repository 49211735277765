import React from "react";
import {
  Control,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";

import FormInputCurrency from "components/Form/Input/Currency";
import FormInputRadio from "components/Form/Input/Radio";
import FormInputTextArea from "components/Form/Input/TextArea";
import { ComonObjectType } from "ts/types";
import { checkTruthyValue } from "utils/validation";
import CreditProviders from "./CreditProviders";
import { LABELS } from "./labels";

interface StepProps {
  control: Control<any, any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  idKey: string;
  errorKey: ComonObjectType | undefined;
}

const ApplicantExpenditureFormFields: React.FC<StepProps> = ({
  control,
  register,
  setValue,
  idKey,
  errorKey,
}) => {
  const watchExpenditures = useWatch({ name: idKey, control: control });

  return (
    <>
      <div className="flex flex-col space-y-4">
        <label className="block text-base font-semibold">
          Outstanding credit
        </label>

        <FormInputRadio
          id={`${idKey}.applicantHasOutstandingCredit`}
          label={LABELS.applicantHasOutstandingCredit}
          register={register}
          requiredField={false}
          initValue={watchExpenditures?.applicantHasOutstandingCredit}
          error={errorKey?.applicantHasOutstandingCredit?.message}
        />

        {checkTruthyValue(watchExpenditures?.applicantHasOutstandingCredit) && (
          <CreditProviders
            setValue={setValue}
            idKey={idKey}
            errorKey={errorKey}
            {...{ control, register }}
          />
        )}

        <label className="block text-base font-semibold">
          Other monthly expenditure
        </label>

        <FormInputCurrency
          id={`${idKey}.childcareCosts`}
          label={LABELS.childcareCosts}
          register={register}
          requiredField={false}
          error={errorKey?.childcareCosts?.message}
          setValue={setValue}
        />

        <FormInputCurrency
          id={`${idKey}.schoolUniversityFees`}
          label={LABELS.schoolUniversityFees}
          register={register}
          requiredField={false}
          error={errorKey?.message}
          setValue={setValue}
        />

        <FormInputCurrency
          id={`${idKey}.childMaintenancePayments`}
          label={LABELS.childMaintenancePayments}
          register={register}
          requiredField={false}
          error={errorKey?.message}
          setValue={setValue}
        />

        <FormInputCurrency
          id={`${idKey}.csaCourtOrderPayments`}
          label={LABELS.csaCourtOrderPayments}
          register={register}
          requiredField={false}
          error={errorKey?.message}
          setValue={setValue}
        />

        <FormInputCurrency
          id={`${idKey}.additionalPropertyRunningCosts`}
          label={LABELS.additionalPropertyRunningCosts}
          register={register}
          requiredField={false}
          error={errorKey?.additionalPropertyRunningCosts?.message}
          setValue={setValue}
        />

        <FormInputCurrency
          id={`${idKey}.other`}
          label={LABELS.other}
          register={register}
          requiredField={false}
          error={errorKey?.other?.message}
          setValue={setValue}
        />

        <FormInputRadio
          id={`${idKey}.futureChangeInExpenditure`}
          label={LABELS.futureChangeInExpenditure}
          register={register}
          requiredField={false}
          initValue={watchExpenditures?.futureChangeInExpenditure}
          error={errorKey?.futureChangeInExpenditure?.message}
        />

        {checkTruthyValue(watchExpenditures?.futureChangeInExpenditure) && (
          <FormInputTextArea
            id={`${idKey}.changeInExpenditureDetail`}
            label={LABELS.changeInExpenditureDetail}
            register={register}
            requiredField={false}
            error={errorKey?.changeInExpenditureDetail?.message}
          />
        )}
      </div>
    </>
  );
};

export default ApplicantExpenditureFormFields;
