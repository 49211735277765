import { DEFAULT_EMPLOYMENT_DATA } from "components/Application/Form/ApplicantEmployment/defaultData";

const DEFAULT_FORM_DATA = {
  employments: [
    {
      ...DEFAULT_EMPLOYMENT_DATA,
    },
  ],
};

export default DEFAULT_FORM_DATA;
