import ApplicantDetailsFormFields from "components/Application/Form/ApplicantDetails";
import FormTitle from "components/Form/Title";
import FormErrors from "pages/ApplicationForm/components/FormErrors";
import { getApplicantName } from "pages/ApplicationForm/utils";
import { FormSchemaApplicants } from "pages/DecisionInPrinciple/formSchema";
import { StepProps } from "pages/DecisionInPrinciple/types";

const STEP_KEY = "details";
const PARENT_KEY = "applicants";

const ApplicantDetailsForm: React.FC<StepProps> = ({
  clearErrors,
  register,
  errors,
  setValue,
  applicantsFields,
  control,
}) => {
  const stepErrors = errors.applicants as unknown as FormSchemaApplicants;

  return (
    <div className="flex flex-col space-y-4">
      <FormTitle left border title="Applicant Details" mb={false} />

      {stepErrors && (
        <FormErrors
          formErrors={Object.values(stepErrors).map((errs) => errs.details)}
        />
      )}

      <>
        {applicantsFields ? (
          applicantsFields.map((applicant: FormSchemaApplicants, k: number) => {
            const applicantNumber = k + 1;
            const idKey = `${PARENT_KEY}.${k}.${STEP_KEY}`;
            const errorKey = errors?.[PARENT_KEY]?.[k]?.[STEP_KEY];

            return (
              <div key={k} className="pt-4">
                <FormTitle
                  left
                  title={`Applicant: ${
                    getApplicantName(applicant.details) || applicantNumber
                  }`}
                />

                <ApplicantDetailsFormFields
                  register={register}
                  control={control}
                  idKey={idKey}
                  errorKey={errorKey}
                  clearErrors={clearErrors}
                  setValue={setValue}
                />
              </div>
            );
          })
        ) : (
          <></>
        )}
      </>
    </div>
  );
};

export default ApplicantDetailsForm;
