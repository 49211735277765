import * as Yup from "yup";

import { INVALID_RESPONSE_MSG } from "utils/validation";

const validation = Yup.object({
  numberOfFinancialDependents: Yup.number()
    .typeError(INVALID_RESPONSE_MSG)
    .required(INVALID_RESPONSE_MSG),

  // howManyResidentsFinanciallyDependentOnApplicant: Yup.number().when(
  //   "anyResidentsFinanciallyDependentOnApplicant",
  //   {
  //     is: (value: any) => checkTruthyValue(value),
  //     then: () =>
  //       Yup.number()
  //         .typeError(INVALID_RESPONSE_MSG)
  //         .required(INVALID_RESPONSE_MSG),
  //     otherwise: () => Yup.number().nullable(),
  //   },
  // ),
}).nullable();

export default validation;
