const DEFAULT_FORM_DATA = {
  solicitor: {
    address: "",
    buildingName: "",
    buildingNumber: "",
    subBuildingName: "",
    subBuildingNumber: "",
    thoroughfare: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    townOrCity: "",
    county: "",
    postcode: "",
    firmName: "",
    actingSolicitorName: "",
    emailAddress: "",
    phoneNumber: "",
    reference: "",
  },
};

export default DEFAULT_FORM_DATA;
