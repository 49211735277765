import * as Yup from "yup";

import {
  checkFalseyValue,
  checkTruthyValue,
  futureDateValidation,
  INVALID_RESPONSE_MSG,
  nullableNumberValidation,
  percentValueValidation,
} from "utils/validation";

export const baseEmploymentValidation = Yup.object().shape({
  isRetired: Yup.bool()
    .typeError(INVALID_RESPONSE_MSG)
    .required(INVALID_RESPONSE_MSG),

  employedInUk: Yup.bool().when("isRetired", {
    is: (value: any) => checkFalseyValue(value),
    then: () =>
      Yup.bool().typeError(INVALID_RESPONSE_MSG).required(INVALID_RESPONSE_MSG),
    otherwise: () => Yup.bool().nullable(),
  }),

  awareOfAnyChangesToIncome: Yup.bool().when("isRetired", {
    is: (value: any) => checkFalseyValue(value),
    then: () =>
      Yup.bool().typeError(INVALID_RESPONSE_MSG).required(INVALID_RESPONSE_MSG),
    otherwise: () => Yup.bool().nullable(),
  }),

  incomeChangesDetails: Yup.string().when("isRetired", {
    is: (value: any) => checkFalseyValue(value),
    then: () =>
      Yup.string().when("awareOfAnyChangesToIncome", {
        is: (value: any) => checkTruthyValue(value),
        then: () => Yup.string().required(INVALID_RESPONSE_MSG),
        otherwise: () => Yup.string().nullable(),
      }),
    otherwise: () => Yup.string().nullable(),
  }),

  hasOtherSourcesOfIncome: Yup.bool().when("isRetired", {
    is: (value: any) => checkFalseyValue(value),
    then: () =>
      Yup.bool().typeError(INVALID_RESPONSE_MSG).required(INVALID_RESPONSE_MSG),
    otherwise: () => Yup.bool().nullable(),
  }),

  // employmentOtherIncomeSources: Yup.string().when("isRetired", {
  //   is: (value: any) => checkFalseyValue(value),
  //   then: () =>
  //     Yup.string().when("hasOtherSourcesOfIncome", {
  //       is: (value: any) => checkTruthyValue(value),
  //       then: () =>
  //         Yup.array().of(
  //           Yup.object({
  //             source: Yup.string().required(INVALID_RESPONSE_MSG),
  //             annualGrossIncome: Yup.string().required(INVALID_RESPONSE_MSG),
  //           }),
  //         ),
  //       otherwise: () => Yup.string().nullable(),
  //     }),
  //   otherwise: () => Yup.array().nullable(),
  // }),

  anticipatedRetirementAge: Yup.string().when("isRetired", {
    is: (value: any) => checkFalseyValue(value),
    then: () =>
      Yup.number()
        .typeError(INVALID_RESPONSE_MSG)
        .required(INVALID_RESPONSE_MSG),
    otherwise: () => nullableNumberValidation,
  }),

  mortgageTermBeyondRetirementAge: Yup.bool().when("isRetired", {
    is: (value: any) => checkFalseyValue(value),
    then: () =>
      Yup.bool().typeError(INVALID_RESPONSE_MSG).required(INVALID_RESPONSE_MSG),
    otherwise: () => Yup.bool().nullable(),
  }),

  retirementIncomeSource: Yup.string().when("isRetired", {
    is: (value: any) => checkFalseyValue(value),
    then: () =>
      Yup.string().when("mortgageTermBeyondRetirementAge", {
        is: (value: any) => checkTruthyValue(value),
        then: () => Yup.string().required(INVALID_RESPONSE_MSG),
        otherwise: () => Yup.string().nullable(),
      }),
    otherwise: () => Yup.string().nullable(),
  }),

  anticipatedCurrentRetirementAnnualAmount: Yup.string().when("isRetired", {
    is: (value: any) => checkFalseyValue(value),
    then: () =>
      Yup.string().when("mortgageTermBeyondRetirementAge", {
        is: (value: any) => checkTruthyValue(value),
        then: () => Yup.string().required(INVALID_RESPONSE_MSG),
        otherwise: () => Yup.string().nullable(),
      }),
    otherwise: () => Yup.string().nullable(),
  }),

  numberOfJobs: Yup.string().when("isRetired", {
    is: (value: any) => checkFalseyValue(value),
    then: () =>
      Yup.number()
        .required(INVALID_RESPONSE_MSG)
        .typeError(INVALID_RESPONSE_MSG),
    otherwise: () => Yup.string().nullable(),
  }),

  employmentDetail: Yup.string().when("isRetired", {
    is: (value: any) => checkFalseyValue(value),
    then: () =>
      Yup.array().of(
        Yup.object({
          employmentStatus: Yup.string().required(INVALID_RESPONSE_MSG),
          percentBusinessOwned: percentValueValidation,
          shareholdingPercentInCompany: percentValueValidation,
          endDate: futureDateValidation,
        }),
      ),
    otherwise: () => Yup.array().nullable(),
  }),
});

// employmentDetail: Yup.array().when("isRetired", {
//   is: (value: any) => checkFalseyValue(value),
//   then: () =>
//     Yup.array().of(
//       Yup.object({
//         status: Yup.string().required(INVALID_RESPONSE_MSG),
//         employmentType: Yup.string().required(INVALID_RESPONSE_MSG),
//         occupation: Yup.string().required(INVALID_RESPONSE_MSG),
//         employerName: Yup.string().required(INVALID_RESPONSE_MSG),
//         dailyRate: Yup.string().required(INVALID_RESPONSE_MSG),
//         treatedAsEmployedForTax: Yup.bool().typeError(INVALID_RESPONSE_MSG).required(INVALID_RESPONSE_MSG),
//         startDate: Yup.string().required(INVALID_RESPONSE_MSG),
//         endDate: Yup.string().required(INVALID_RESPONSE_MSG),
//         annualBasicSalary: Yup.string().required(INVALID_RESPONSE_MSG),
//         annualBonusGross: Yup.string().required(INVALID_RESPONSE_MSG),
//         annualOvertimeGross: Yup.string().required(INVALID_RESPONSE_MSG),
//         annualCommissionGross: Yup.string().required(INVALID_RESPONSE_MSG),
//         annualLocationOrCarAllowanceGross: Yup.string().required(INVALID_RESPONSE_MSG),
//         businessName: Yup.string().required(INVALID_RESPONSE_MSG),
//         percentBusinessOwned: Yup.string().required(INVALID_RESPONSE_MSG),
//         projectedGrossProfitCurrentTradingYear: Yup.string().required(INVALID_RESPONSE_MSG),
//         latestYearNetProfit: Yup.string().required(INVALID_RESPONSE_MSG),
//         previousYearNetProfit: Yup.string().required(INVALID_RESPONSE_MSG),
//         shareholdingPercentInCompany: Yup.string().required(INVALID_RESPONSE_MSG),
//         salaryFromLatestFinalisedYear: Yup.string().required(INVALID_RESPONSE_MSG),
//         salaryFromPreviousYear: Yup.string().required(INVALID_RESPONSE_MSG),
//         currentYearProjectedDividends: Yup.string().required(INVALID_RESPONSE_MSG),
//         dividendFromLatestFinalisedYear: Yup.string().required(INVALID_RESPONSE_MSG),
//         dividendFromPreviousYear: Yup.string().required(INVALID_RESPONSE_MSG),
//         selfEmployedAccountant: Yup.object({
//           addressLine1: Yup.string().required(INVALID_RESPONSE_MSG),
//           townOrCity: Yup.string().required(INVALID_RESPONSE_MSG),
//           county: Yup.string().required(INVALID_RESPONSE_MSG),
//           postcode: Yup.string().required(INVALID_RESPONSE_MSG),
//           firmName: Yup.string().required(INVALID_RESPONSE_MSG),
//           phoneNumber: Yup.string()
//             .required(INVALID_RESPONSE_MSG)    .matches(/^([0-9]{8,11})$/, {
//   message: VALIDATION_PHONE_NUMBER,
//   excludeEmptyString: true,
// }),
//           emailAddress: Yup.string().required(INVALID_RESPONSE_MSG).email("Email is invalid"),
//         }),
//       })
//     ),
//   otherwise: () => Yup.array().nullable(),
// }),
