import { gql } from "@apollo/client";

export const MUTATION_CREATE_ILLUSTRATION = gql`
  mutation CreateIllustration($input: CreateIllustrationInput!) {
    response: createIllustration(input: $input) {
      illustration {
        uuid
        reference
        downloadUrl
        case {
          uuid
          reference
        }
      }
      ok
      errors
    }
  }
`;
