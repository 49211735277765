import PageContent from "components/Page/Content";
import { useUserStore } from "store/User";
import DashboardResources from "./components/Resources";
import DashboardNavList from "./components/NavList";
import DashboardApplications from "./components/Applications";

export default function Dashboard() {
  const user = useUserStore((state) => state.user);

  return (
    <PageContent
      title={`Hi ${user?.firstName}, welcome to your Ahauz Dashboard`}
    >
      <>
        <div className="flex flex-col justify-center lg:flex-row lg:justify-between">
          <DashboardNavList />
          <DashboardResources />
        </div>
        <div>
          <DashboardApplications />
        </div>
      </>
    </PageContent>
  );
}
