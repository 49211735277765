import { setDataForApi } from "utils/api";

export const parseApplicantEmployment = (applicant: any) => {
  if (!applicant) {
    return applicant;
  }

  const { employmentDetail, employmentOtherIncomeSources } = applicant;

  if (applicant.numberOfJobs) {
    applicant.numberOfJobs = Number(applicant.numberOfJobs);
  }

  if (employmentDetail) {
    employmentDetail.forEach((emp: any) => {
      if (emp.selfEmployedAccountant) {
        setDataForApi(emp.selfEmployedAccountant);
      }
      setDataForApi(emp);
    });
  }

  if (employmentOtherIncomeSources) {
    employmentOtherIncomeSources.forEach((icm: any) => {
      setDataForApi(icm);
    });
  }

  setDataForApi(applicant);

  return applicant;
};
