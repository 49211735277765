export const TENURE_LEASEHOLD_OPTION = "L";

export const PropertyTypes = [
  {
    value: "CFM",
    title: "Converted flat/Maisonette",
  },
  {
    value: "DB",
    title: "Detached bungalow",
  },
  {
    value: "DH",
    title: "Detached house",
  },
  {
    value: "PBFM",
    title: "Purpose built flat/Maisonette",
  },
  {
    value: "SB",
    title: "Semi-detached bungalow",
  },
  {
    value: "SH",
    title: "Semi-detached house",
  },
  {
    value: "TB",
    title: "Terraced bungalow",
  },
  {
    value: "TH",
    title: "Terraced house",
  },
  {
    value: "SCFM",
    title: "Self contained flat/Maisonette",
  },
  {
    value: "SCST",
    title: "Self contained studio flat",
  },
];

export const PropertyTenures = [
  {
    value: "F",
    title: "Freehold",
  },
  {
    value: "L",
    title: "Leasehold",
  },
  {
    value: "SF",
    title: "Share of Freehold",
  },
  {
    value: "O",
    title: "Other",
  },
];

export const PropertyCouncilTaxBands = [
  {
    value: "A",
    title: "Band A",
  },
  {
    value: "B",
    title: "Band B",
  },
  {
    value: "C",
    title: "Band C",
  },
  {
    value: "D",
    title: "Band D",
  },
  {
    value: "E",
    title: "Band E",
  },
  {
    value: "F",
    title: "Band F",
  },
  {
    value: "G",
    title: "Band G",
  },
  {
    value: "H",
    title: "Band H",
  },
  {
    value: "U",
    title: "Unknown",
  },
];
