import React from "react";

import FormTitle from "components/Form/Title";
import FormErrors from "pages/ApplicationForm/components/FormErrors";
import { isFormComplete } from "pages/ApplicationForm/formStatus";
import useApplicationStore from "store/Application";
import { StepProps } from "../../types";

const STEP_KEY = "confirm";

const ReviewConfirmForm: React.FC<StepProps> = ({ errors }) => {
  const { formStatus } = useApplicationStore();

  return (
    <div className="flex flex-col space-y-4">
      <FormTitle left border title="Review & Confirm" mb={false} />

      <FormErrors formErrors={errors?.[STEP_KEY]} />

      {!isFormComplete(formStatus) ? (
        <p className="pt-4 text-center">
          Please complete all form steps before submitting your Application.
        </p>
      ) : (
        <>
          <span className="block text-base">
            Please ensure all information is accurate and complete. If
            everything is correct, click{" "}
            <span className="font-semibold">Submit Application</span> to
            proceed. To make changes, click{" "}
            <span className="font-semibold">Previous</span>.
          </span>
        </>
      )}
    </div>
  );
};

export default ReviewConfirmForm;
