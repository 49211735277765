import CTAButton from "components/CTA/Button";
import useADipStore from "store/DecisionInPrinciple";
import { TOTAL_STEPS } from "../formSteps";

interface NavigationProps {
  handlePrevious: (validate: boolean) => void;
}

const FormNav: React.FC<NavigationProps> = ({ handlePrevious }) => {
  const { formStep } = useADipStore();

  return (
    <nav className="!mt-8 flex justify-between border-t border-gray-200 pt-8">
      <div>
        {formStep.id > 1 && (
          <CTAButton secondary label="Previous" onClick={handlePrevious} />
        )}
      </div>
      <div className="flex space-x-2">
        {formStep.id === TOTAL_STEPS ? (
          <CTAButton type="submit" label="Submit Decision in Principle" />
        ) : (
          <CTAButton label="Submit step" type="submit" />
        )}
      </div>
    </nav>
  );
};

export default FormNav;
