import React from "react";

import Loading from "components/Loading";

interface SubmitProgressProps {
  submitApplicationError: any;
}

const SubmitApplicationProgress: React.FC<SubmitProgressProps> = ({
  submitApplicationError,
}) => {
  if (submitApplicationError) {
    return (
      <>
        <p>Sorry, there has been an error submitting your Application.</p>
        <p className="mt-2">Please contact us to discuss this further.</p>
      </>
    );
  }

  return (
    <>
      <Loading />
      <p className="mt-4">Submitting Application...</p>
    </>
  );
};

export default SubmitApplicationProgress;
