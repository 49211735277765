import { setDataForApi } from "utils/api";
import { FormSchemaApplicantDetails } from "../ApplicantDetails/types";
import { FormSchemaApplicantDeclarations } from "./types";

const parseDataForApi = (
  data: Array<FormSchemaApplicantDeclarations>,
  _numberOfApplicants: number,
  applicantsFieldArray: Array<FormSchemaApplicantDetails>,
) => {
  if (!data) {
    return data;
  }

  const applicantsDetail = Object.values(data).map((applicant, i) => {
    const declarationComment = applicant?.declarationComment || "";

    setDataForApi(applicant);

    // Force update text fields back to original
    applicant.declarationComment = declarationComment;

    if (!applicant.applicantId) {
      applicant.applicantId = applicantsFieldArray[i].uuid;
    }
    return applicant;
  });

  return applicantsDetail;
};

export default parseDataForApi;
