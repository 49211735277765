import CTAButton from "components/CTA/Button";
import { APP_URLS } from "settings";
import { Illustration } from "ts/interfaces/common/esis";
import IllustrationList from "./IllustrationList";

// Define the props interface
interface IllustrationsProps {
  caseID: string;
  illustrations: Illustration[]; // Array of illustration items
}

const Illustrations: React.FC<IllustrationsProps> = ({
  caseID,
  illustrations,
}) => {
  const routeToCreateIllustration = () => {
    window.location.href = `${APP_URLS.CREATE_ILLUSTRATION}?case=${caseID}`;
  };

  return (
    <>
      <div className="flex items-end justify-between">
        <h3 className="text-lg font-semibold">Illustrations</h3>
        <CTAButton
          label="Create Illustration"
          onClick={routeToCreateIllustration}
        />
      </div>
      <IllustrationList illustrations={illustrations} />
    </>
  );
};

Illustrations.displayName = "Illustrations";

export default Illustrations;
