import * as Yup from "yup";

import {
  INVALID_RESPONSE_MSG,
  pastDateValidation,
  PHONE_NUMBER_REGEX,
  POSTCODE_REGEX,
  VALIDATION_DATE,
  VALIDATION_PHONE_NUMBER,
  VALIDATION_POSTCODE,
} from "utils/validation";

const applicantAddressSchema = Yup.object().shape({
  stayDurationYears: Yup.number()
    .typeError(INVALID_RESPONSE_MSG)
    .required(INVALID_RESPONSE_MSG),
  stayDurationMonths: Yup.number().when("stayDurationYears", {
    is: (value: any) => {
      return !value || value === 0;
    },
    then: () =>
      Yup.number()
        .typeError(INVALID_RESPONSE_MSG)
        .required(INVALID_RESPONSE_MSG)
        .min(1, VALIDATION_DATE)
        .max(11, VALIDATION_DATE),
    otherwise: () =>
      Yup.number()
        .typeError(INVALID_RESPONSE_MSG)
        .required(INVALID_RESPONSE_MSG)
        .max(11, VALIDATION_DATE),
  }),
  residentialStatus: Yup.string().required(INVALID_RESPONSE_MSG),
  addressLine1: Yup.string().required(INVALID_RESPONSE_MSG),
  townOrCity: Yup.string().required(INVALID_RESPONSE_MSG),
  postcode: Yup.string()
    .trim()
    .required(INVALID_RESPONSE_MSG)
    .matches(POSTCODE_REGEX, VALIDATION_POSTCODE),
});

export const baseApplicantsValidation = Yup.object().shape({
  title: Yup.string().required(INVALID_RESPONSE_MSG),
  forename: Yup.string().required(INVALID_RESPONSE_MSG),
  surname: Yup.string().required(INVALID_RESPONSE_MSG),
  dob: pastDateValidation.required(VALIDATION_DATE),
  dateOfChange: Yup.string().when("previousName", {
    is: (value: any) => !!value,
    then: () => pastDateValidation.required(VALIDATION_DATE),
    otherwise: () => Yup.string().nullable(),
  }),
  phoneNumber: Yup.string()
    .required(INVALID_RESPONSE_MSG)
    .matches(PHONE_NUMBER_REGEX, {
      message: VALIDATION_PHONE_NUMBER,
      excludeEmptyString: true,
    }),
  email: Yup.string().required(INVALID_RESPONSE_MSG).email("Email is invalid"),
  currentAddress: applicantAddressSchema,
  previousAddresses: Yup.array().of(applicantAddressSchema),
});
