import { baseApplicantsValidation } from "components/Application/Form/ApplicantDetails/validation";
import * as Yup from "yup";

const validation: Yup.ObjectSchema<any> = Yup.object().shape({
  applicants: Yup.array().of(
    Yup.object({
      details: baseApplicantsValidation,
    }),
  ),
});

export default validation;
