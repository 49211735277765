import { XCircleIcon } from "@heroicons/react/20/solid";

interface FormErrorProps {
  formErrors?: any;
}

interface FormErrorSingleType {
  message?: string;
  type?: string;
}

const FormErrors: React.FC<FormErrorProps> = ({ formErrors }) => {
  const hasFormErrors = formErrors && Object.keys(formErrors).length;
  const hasErrors = () => hasFormErrors;

  /**
   * Return a prettified field name string
   * @param {string} fieldName
   * @returns string
   */
  const getPrettyFieldName = (fieldName: string) => {
    let result = fieldName.replace(/([A-Z])/g, " $1");
    result = result.charAt(0).toUpperCase() + result.slice(1);
    result = result.replace(/_/g, " ");

    // Likely an index rather than key skip outputting a field name
    if (!result || result.length <= 1) {
      return "";
    }

    return `${result}:`;
  };

  const errorOuput = (
    key: string,
    message: string,
    type?: string,
    keyPrefix?: string,
  ) => {
    if (key === "" || type === "root") {
      return message;
    }
    let output = `${getPrettyFieldName(key)} ${message}`;
    if (keyPrefix) {
      output = `${keyPrefix} - ${output}`;
    }
    return output;
  };

  const buildErrorArray = (
    errorArray: Array<any>,
    errors: any,
    keyPrefix?: string,
  ) => {
    if (!errors) {
      return;
    }
    for (const [k, v] of Object.entries(errors) as [
      string,
      FormErrorSingleType,
    ][]) {
      if (v?.message) {
        errorArray.push(errorOuput(k, v.message, v?.type, keyPrefix));
      }
    }
  };

  /**
   * Build array of client side form errors
   * @returns array
   */
  const getFormErrors = (errors: any) => {
    if (!errors) {
      return [];
    }

    const errorArray: any[] = [];

    if (Array.isArray(errors)) {
      errors.forEach((errs, i) => {
        if (errors.length > 1) {
          buildErrorArray(errorArray, errs, `Applicant ${i + 1}`);
        } else {
          buildErrorArray(errorArray, errs);
        }
      });
    } else {
      buildErrorArray(errorArray, errors);
    }

    return errorArray;
  };

  if (hasErrors()) {
    return (
      <div className="mb-8 rounded-md border border-red-800 bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0 pt-0.5">
            <XCircleIcon className="h-5 w-5 text-red-800" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <span className="mt-0 text-left text-sm font-medium text-red-800">
              There were errors with your submission
            </span>
            <div className="mt-2 text-sm text-red-800">
              <ul className="list-disc space-y-1 pl-5">
                {getFormErrors(formErrors).map((error, i) => (
                  <li key={i}>{error}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <></>;
};

export default FormErrors;
