export const LABELS = {
  futureChangeInExpenditure:
    "Are you aware of any future changes to your expenditure which may impact on your ability to repay the mortgage?",
  changeInExpenditureDetail: "If yes, please give further details",
  childcareCosts: "Childcare costs (per month)",
  schoolUniversityFees: "School or University fees (per month)",
  childMaintenancePayments: "Child maintenance payments (per month)",
  csaCourtOrderPayments: "CSA or court order payments (per month)",
  additionalPropertyRunningCosts:
    "Additional property running costs (per month)",
  other: "Other (per month)",
  applicantHasOutstandingCredit:
    "Does the applicant have any credit commitments?",
  creditProviders: {
    providerType: "Type",
    provider: "Credit Provider",
    balanceOutstanding: "Balance outstanding",
    monthlyPayment: "Monthly payment",
    creditEndDate: "Credit end date",
    mortgagePriorPaidOff: "Paid off prior to completion of mortgage?",
    repayFromMortgage: "Repaid with money from this mortgage?",
    mortgageConvertedToB2l: "Will the mortgage be converted to a B2L mortgage?",
  },
};
