import React, { useState } from "react";
import {
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";

import FormInputAddress from "components/Form/Input/Address";
import FormInputText from "components/Form/Input/Text";
import { AddressType, ComonObjectType } from "ts/types";

interface AddressSetterProps {
  clearErrors: UseFormClearErrors<any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  idKey: string;
  errorKey: ComonObjectType | undefined;
}

export const LABELS = {
  addressLine1: "Address Line 1",
  addressLine2: "Address Line 2",
  addressLine3: "Address Line 3",
  townOrCity: "Town or city",
  county: "County",
  postcode: "Postcode",
};

const AddressSetter: React.FC<AddressSetterProps> = ({
  clearErrors,
  setValue,
  register,
  idKey,
  errorKey,
}) => {
  const [isAddressLookup, setIsAddressLookup] = useState<boolean>(true);

  const setAddress = (address: AddressType) => {
    setValue(`${idKey}.address`, address?.address);
    setValue(`${idKey}.buildingName`, address?.buildingName);
    setValue(`${idKey}.buildingNumber`, address?.buildingNumber);
    setValue(`${idKey}.subBuildingName`, address?.subBuildingName);
    setValue(`${idKey}.subBuildingNumber`, address?.subBuildingNumber);
    setValue(`${idKey}.thoroughfare`, address?.thoroughfare);
    setValue(`${idKey}.addressLine1`, address?.line1);
    setValue(`${idKey}.addressLine2`, address?.line2);
    setValue(`${idKey}.addressLine3`, address?.line3);
    setValue(`${idKey}.townOrCity`, address?.townOrCity);
    setValue(`${idKey}.county`, address?.county);
    setValue(`${idKey}.postcode`, address?.postcode);
    clearErrors(`${idKey}.addressLine1`);
  };

  const resetAddressFields = (addressLookup: boolean) => {
    setIsAddressLookup(addressLookup);
    // setValue(`${idKey}.address`, "");
    // setValue(`${idKey}.buildingName`, "");
    // setValue(`${idKey}.buildingNumber`, "");
    // setValue(`${idKey}.subBuildingName`, "");
    // setValue(`${idKey}.subBuildingNumber`, "");
    // setValue(`${idKey}.thoroughfare`, "");
    // setValue(`${idKey}.addressLine1`, "");
    // setValue(`${idKey}.addressLine2`, "");
    // setValue(`${idKey}.addressLine3`, "");
    // setValue(`${idKey}.townOrCity`, "");
    // setValue(`${idKey}.county`, "");
    // setValue(`${idKey}.postcode`, "");
    clearErrors();
  };

  return (
    <>
      {isAddressLookup && (
        <div className="!-mt-0">
          <FormInputAddress
            id={`${idKey}.address`}
            label=""
            initialAddress=""
            register={register}
            setValue={setAddress}
            triggerManualInput={() => resetAddressFields(false)}
            error={
              errorKey?.addressLine1?.message
                ? "Please enter a valid address"
                : ""
            }
          />
        </div>
      )}

      <div className={isAddressLookup ? "hidden" : "block"}>
        <div className="flex flex-col space-y-4">
          <FormInputText
            id={`${idKey}.addressLine1`}
            label={LABELS.addressLine1}
            register={register}
            error={errorKey?.addressLine1?.message}
          />
          <FormInputText
            id={`${idKey}.addressLine2`}
            label={LABELS.addressLine2}
            register={register}
            error={errorKey?.addressLine2?.message}
          />
          <FormInputText
            id={`${idKey}.addressLine3`}
            label={LABELS.addressLine3}
            register={register}
            error={errorKey?.addressLine3?.message}
          />
          <FormInputText
            id={`${idKey}.townOrCity`}
            label={LABELS.townOrCity}
            register={register}
            error={errorKey?.townOrCity?.message}
          />
          <FormInputText
            id={`${idKey}.county`}
            label={LABELS.county}
            register={register}
            error={errorKey?.county?.message}
          />
          <FormInputText
            id={`${idKey}.postcode`}
            label={LABELS.postcode}
            register={register}
            error={errorKey?.postcode?.message}
          />
          <button
            onClick={() => resetAddressFields(true)}
            className="link text-right text-sm underline"
            type="button"
          >
            Try an address look up again?
          </button>
        </div>
      </div>
    </>
  );
};

export default AddressSetter;
