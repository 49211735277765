import { setDataForApi } from "utils/api";
import { FormSchemaSolicitor } from "./types";

const parseDataForApi = (data: FormSchemaSolicitor) => {
  const _data = { ...data };
  setDataForApi(_data);
  return _data;
};

export default parseDataForApi;
