import { useLazyQuery } from "@apollo/client";
import { QUERY_ACCOUNT_HIJACK } from "api/queries/account";
import { useEffect, useState } from "react";
import { useUserStore } from "store/User";

type HijackType = {
  fetched: boolean;
  releaseUrl: string;
  releaseToken: string;
  releaseNextUrl: string;
};

export default function Hijack() {
  const [hijackData, setHijackData] = useState<HijackType>({
    fetched: false,
    releaseUrl: "",
    releaseToken: "",
    releaseNextUrl: "",
  });

  const user = useUserStore((state) => state.user);
  const isHijacked = user && user?.isHijacked;

  const [getHijackData] = useLazyQuery(QUERY_ACCOUNT_HIJACK);

  useEffect(() => {
    if (isHijacked && !hijackData.fetched) {
      const fetchData = async () => {
        const { data } = await getHijackData();
        setHijackData({
          fetched: true,
          ...data.brokerHijack,
        });
      };
      fetchData();
    }
  }, [isHijacked, hijackData, getHijackData]);

  if (isHijacked) {
    return (
      <div className="djhj fixed bottom-0 w-full bg-red-500" id="djhj">
        <div className="djhj-notification mx-auto flex max-w-4xl justify-between p-6">
          <div className="djhj-message flex items-center text-lg text-white">
            <span>
              <span className="font-bold uppercase">BROKER SESSION:</span> You
              are currently working on behalf of <em>{user?.fullName}</em>
            </span>
          </div>
          <form
            action={hijackData.releaseUrl}
            method="POST"
            className="djhj-actions"
          >
            <input
              type="hidden"
              name="csrfmiddlewaretoken"
              value={hijackData.releaseToken}
            ></input>
            <input
              type="hidden"
              name="next"
              value={hijackData.releaseNextUrl}
            />
            <button
              className="djhj-button rounded-md bg-white px-5 py-3 font-bold uppercase text-black hover:bg-slate-50"
              type="submit"
            >
              Release
            </button>
          </form>
        </div>
      </div>
    );
  }

  return <></>;
}
