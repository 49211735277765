import {
  APPLICATION_COMPLETE_KEY,
  APPLICATION_IN_PROGRESS_KEY,
} from "utils/consts";
import { FormSchemaStatus } from "./formSchema";

const getArrayStatus = (statusArray?: Array<any>): string => {
  if (!Array.isArray(statusArray)) {
    return APPLICATION_IN_PROGRESS_KEY;
  }

  const _statusArray = statusArray.filter((item) => item);

  if (
    _statusArray.length > 0 &&
    _statusArray.every(
      (item) => item?.status && item?.status === APPLICATION_COMPLETE_KEY,
    )
  ) {
    return APPLICATION_COMPLETE_KEY;
  }
  return APPLICATION_IN_PROGRESS_KEY;
};

const getSingleStatus = (status?: string): string =>
  status || APPLICATION_IN_PROGRESS_KEY;

export const getApplicationStatus = (application?: any) => {
  const arrayStatusFields = [
    "applicants",
    "declarations",
    "employments",
    "expenditures",
  ] as const;
  const singleStatusFields = [
    "broker",
    "loan",
    "property",
    "other",
    "solicitor",
  ] as const;

  const arrayStatuses = arrayStatusFields.reduce(
    (acc, field) => ({
      ...acc,
      [field]: getArrayStatus(application?.[field]),
    }),
    {} as Record<(typeof arrayStatusFields)[number], string>,
  );

  const singleStatuses = singleStatusFields.reduce(
    (acc, field) => ({
      ...acc,
      [field]: getSingleStatus(application?.[field]?.status),
    }),
    {} as Record<(typeof singleStatusFields)[number], string>,
  );

  return { ...arrayStatuses, ...singleStatuses };
};

export const isFormComplete = (formStatus: FormSchemaStatus) =>
  Object.values(formStatus).every(
    (value) => value === APPLICATION_COMPLETE_KEY,
  );

export const DEFAULT_APPLICANTS_FORM_STATUS = {
  applicants: APPLICATION_IN_PROGRESS_KEY,
  declarations: APPLICATION_IN_PROGRESS_KEY,
  employments: APPLICATION_IN_PROGRESS_KEY,
  expenditures: APPLICATION_IN_PROGRESS_KEY,
};

export const DEFAULT_FORM_STATUS = {
  broker: APPLICATION_IN_PROGRESS_KEY,
  applicants: APPLICATION_IN_PROGRESS_KEY,
  declarations: APPLICATION_IN_PROGRESS_KEY,
  employments: APPLICATION_IN_PROGRESS_KEY,
  expenditures: APPLICATION_IN_PROGRESS_KEY,
  loan: APPLICATION_IN_PROGRESS_KEY,
  property: APPLICATION_IN_PROGRESS_KEY,
  other: APPLICATION_IN_PROGRESS_KEY,
  solicitor: APPLICATION_IN_PROGRESS_KEY,
};
