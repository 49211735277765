const MoneyFormat = new Intl.NumberFormat("en-GB", {
  maximumFractionDigits: 2,
});

const MoneyFormatCurrencyStyle = new Intl.NumberFormat("en-GB", {
  maximumFractionDigits: 2,
  style: "currency",
  currency: "GBP",
});

export const asMoney = (
  value: number,
  currency: boolean = false,
  allowZero: boolean = false,
): string => {
  if (allowZero && value === 0) {
    return "0";
  }
  if (value) {
    const formatter = currency ? MoneyFormatCurrencyStyle : MoneyFormat;
    const result = formatter.format(value);
    return result.replace(/£/g, "");
  }
  if (value === 0) {
    return "0";
  }
  return "";
};

export const inputToFloat = (input: any) => {
  if (input) {
    try {
      return parseFloat(input.toString().replaceAll(",", ""));
    } catch (error) {
      return parseFloat(input.toString());
    }
  }
  return input;
};

export const calcEquityLoanValue = (propertyValue?: string): string => {
  const minPropertyValue = 10000;

  if (propertyValue && Number(propertyValue) > minPropertyValue) {
    const percentOfPropertyValueForEquityLoan = 15;
    const equityLoanValue = asMoney(
      inputToFloat(
        (percentOfPropertyValueForEquityLoan / 100) * Number(propertyValue),
      ),
      true,
    );
    return equityLoanValue;
  }

  return "";
};
