export const LABELS = {
  hadPropertyReprocessed: "Has the applicant ever had a property repossessed?",
  hadCcj: "Does the applicant have any unsatisfied CCJs? ",
  ccjsGreaterThan500Last3years:
    "Does the applicant have any satisfied CCJs greater than £500 registered in the past 3 years?",
  moreThanOneDefaultLast3years:
    "Has the applicant had more than one default, with the latest being registered in the last 3 years?",
  hasBeenBankrupt:
    "Has the applicant been declared bankrupt, entered into an IVA or debt relief order or still have an outstanding bankruptcy restriction order?",
  hasDebtRegistered:
    "Has the applicant had a court order for debt registered against the applicant or any such proceedings pending?",
  hasMadeCreditorsArrangement:
    "Has the applicant made arrangements with creditors or any such proceedings pending?",
  hasUnsatisfiedDefaults: "Does the applicant have any unsatisfied defaults?",
  defaultsGreaterThan10012monthsOr5003years:
    "Has the applicant had any defaults registed against them greater than £100 in the last 12 months or greater than £500 in the last 3 years?",
  hasFailedCreditAgreements:
    "Has the applicant failed to maintain any credit agreements above £100 in the past 12 months?",
  missed2OrMoreCreditPaymentsLast6months:
    "Has the applicant missed two or more credit payments in the past 6 months?",
  declarationComment:
    "Please provide comments here if the answer to any of the above questions is 'Yes'",
};
