const DEFAULT_FORM_DATA = {
  declarations: [
    {
      hadPropertyReprocessed: undefined,
      hadCcj: undefined,
      ccjsGreaterThan500Last3years: undefined,
      moreThanOneDefaultLast3years: undefined,
      hasBeenBankrupt: undefined,
      hasDebtRegistered: undefined,
      hasMadeCreditorsArrangement: undefined,
      hasUnsatisfiedDefaults: undefined,
      defaultsGreaterThan10012monthsOr5003years: undefined,
      hasFailedCreditAgreements: undefined,
      missed2OrMoreCreditPaymentsLast6months: undefined,
      declarationComment: "",
    },
  ],
};

export default DEFAULT_FORM_DATA;
