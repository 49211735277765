import {
  INVALID_RESPONSE_MSG,
  nullableNumberValidation,
  POSTCODE_REGEX,
  VALIDATION_DATE,
  VALIDATION_POSTCODE,
} from "utils/validation";
import * as Yup from "yup";
import { TENURE_LEASEHOLD_OPTION } from "./options";

const currentYear = new Date().getFullYear();

const validation = Yup.object({
  addressLine1: Yup.string().required(INVALID_RESPONSE_MSG),
  townOrCity: Yup.string().required(INVALID_RESPONSE_MSG),
  postcode: Yup.string()
    .trim()
    .required(INVALID_RESPONSE_MSG)
    .matches(POSTCODE_REGEX, VALIDATION_POSTCODE),
  propertyType: Yup.string().required(INVALID_RESPONSE_MSG),
  tenure: Yup.string().required(INVALID_RESPONSE_MSG),
  yearsLeftOnLease: Yup.string().when("tenure", {
    is: (value: any) => value === TENURE_LEASEHOLD_OPTION,
    then: () =>
      Yup.number()
        .typeError(INVALID_RESPONSE_MSG)
        .required(INVALID_RESPONSE_MSG),
    otherwise: () => nullableNumberValidation,
  }),
  yearPropertyBuilt: Yup.number()
    .nullable()
    .typeError(INVALID_RESPONSE_MSG)
    .test("is-current-year-or-less", VALIDATION_DATE, (value) => {
      if (!value) {
        return true;
      }
      return value ? value >= 1000 && value <= currentYear + 100 : false;
    }),
  isNewBuildPurchase: Yup.bool()
    .typeError(INVALID_RESPONSE_MSG)
    .required(INVALID_RESPONSE_MSG),
  hasNhbcFnd15Cover: Yup.bool()
    .typeError(INVALID_RESPONSE_MSG)
    .required(INVALID_RESPONSE_MSG),
  willBePartiallyRented: Yup.bool()
    .typeError(INVALID_RESPONSE_MSG)
    .required(INVALID_RESPONSE_MSG),
  willBePrimaryResidence: Yup.bool()
    .typeError(INVALID_RESPONSE_MSG)
    .required(INVALID_RESPONSE_MSG),
  propertyNearBusinessPremises: Yup.bool()
    .typeError(INVALID_RESPONSE_MSG)
    .required(INVALID_RESPONSE_MSG),
  canUseWithoutStructuralChanges: Yup.bool()
    .typeError(INVALID_RESPONSE_MSG)
    .required(INVALID_RESPONSE_MSG),
  isCouncilFlat: Yup.bool()
    .typeError(INVALID_RESPONSE_MSG)
    .required(INVALID_RESPONSE_MSG),
  isStudioFlat: Yup.bool()
    .typeError(INVALID_RESPONSE_MSG)
    .required(INVALID_RESPONSE_MSG),
  // useOfOutbuildingOrAnnexDetails: Yup.string().when("hasOutbuildingOrAnnex", {
  //   is: (value: any) => checkTruthyValue(value),
  //   then: () => Yup.string().required(INVALID_RESPONSE_MSG),
  //   otherwise: () => Yup.string().nullable(),
  // }),
}).nullable();

export default validation;
