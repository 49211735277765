import { gql } from "@apollo/client";

export const FRAGMENT_BASE_RESPONSE_FIELDS = gql`
  fragment BaseResponseFields on BaseResponseType {
    ok
    message
    errors
  }
`;

export const FRAGMENT_BROKER_TYPE = gql`
  fragment BrokerFields on BrokerType {
    id
    email
    fullName
    firstName
    lastName
    fullName
    firmName
    firmPhoneNumber
    firmAddress
    firmWebsite
    firmFcaNumber
    brokerageFee
    isHijacked
  }
`;
