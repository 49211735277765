export const LABELS = {
  isRetired: "Is the applicant retired?",
  numberOfJobs: "Number of jobs classed as main income",
  employedInUk: "Is the applicant employed in the UK?",
  awareOfAnyChangesToIncome:
    "Is the applicant aware of any imminent changes to their income?",
  incomeChangesDetails: "If yes, please provide details",
  hasOtherSourcesOfIncome:
    "Does the applicant have any other sources of income?",
  anticipatedRetirementAge: "Anticipated retirement age",
  mortgageTermBeyondRetirementAge:
    "Is the term of the mortgage beyond the applicant's retirement age?",
  retirementIncomeSource: "Retirement income source",
  anticipatedCurrentRetirementAnnualAmount: "Anticipated/Current annual amount",
  employmentDetail: {
    employmentStatus: "Current employment status",
    employmentType: "Employment type",
    occupation: "Occupation",
    employerName: "Employer name",
    dailyRate: "Daily rate",
    treatedAsEmployedForTax:
      "Is the applicant treated as employed for tax purposes?",
    startDate: "Start date",
    endDate: "End date if on a fixed-term contract/probationary period",
    annualBasicSalary: "Annual basic salary (gross)",
    annualBonusGross: "Annual bonus (gross)",
    annualOvertimeGross: "Annual overtime (gross)",
    annualCommissionGross: "Annual commission (gross)",
    annualLocationOrCarAllowanceGross:
      "Annual location or car allowance (gross)",
    businessName: "Business name",
    percentBusinessOwned: "Percentage of business owned (if applicable)",
    projectedGrossProfitCurrentTradingYear:
      "Projected gross profit for current trading year",
    latestYearNetProfit: "Latest year net profit",
    previousYearNetProfit: "Previous year net profit",
    shareholdingPercentInCompany: "Applicant's shareholding in company",
    salaryFromLatestFinalisedYear: "Salary from latest finalised year",
    salaryFromPreviousYear: "Salary from previous year",
    currentYearProjectedDividends: "Current year projected dividends",
    dividendFromLatestFinalisedYear: "Dividend from latest finalised year",
    dividendFromPreviousYear: "Dividend from previous year",
    selfEmployedAccountant: {
      addressLine1: "Address Line 1",
      addressLine2: "Address Line 2",
      addressLine3: "Address Line 3",
      townOrCity: "Town or city",
      county: "County",
      postcode: "Postcode",
      firmName: "Accountancy firm name",
      phoneNumber: "Accountants phone number",
      emailAddress: "Accountants Email address",
    },
  },
  employmentOtherIncomeSources: {
    source: "Source",
    annualGrossIncome: "Gross annual income",
  },
};
