import React, { useEffect } from "react";

import FormInputRadio from "components/Form/Input/Radio";
import FormInputSelect from "components/Form/Input/Select";
import FormInputText from "components/Form/Input/Text";
import FormTitle from "components/Form/Title";
import FormErrors from "pages/ApplicationForm/components/FormErrors";
import { StepProps } from "pages/DecisionInPrinciple/types";
import { checkFalseyValue, checkTruthyValue } from "utils/validation";
import { LABELS } from "./labels";

const STEP_KEY = "start";

const StartForm: React.FC<StepProps> = ({
  watch,
  register,
  errors,
  setValue,
  clearErrors,
}) => {
  const receivedSuccessfulDipFromFirstChargeLender = watch(
    `${STEP_KEY}.receivedSuccessfulDipFromFirstChargeLender`,
  );

  const consentToCreditCheckAndInformationProcessing = watch(
    `${STEP_KEY}.consentToCreditCheckAndInformationProcessing`,
  );

  useEffect(() => {
    if (checkFalseyValue(consentToCreditCheckAndInformationProcessing)) {
      clearErrors();
      setValue(
        `${STEP_KEY}.receivedSuccessfulDipFromFirstChargeLender`,
        undefined,
      );
    }
  }, [consentToCreditCheckAndInformationProcessing, setValue, clearErrors]);

  return (
    <div className="flex flex-col space-y-4">
      <FormTitle left border title="Start" mb={false} />

      <FormErrors formErrors={errors?.[STEP_KEY]} />

      <FormInputRadio
        id={`${STEP_KEY}.consentToCreditCheckAndInformationProcessing`}
        label={LABELS.consentToCreditCheckAndInformationProcessing}
        register={register}
        requiredField={false}
        initValue={consentToCreditCheckAndInformationProcessing}
      />

      {checkTruthyValue(consentToCreditCheckAndInformationProcessing) && (
        <>
          <FormInputRadio
            id={`${STEP_KEY}.receivedSuccessfulDipFromFirstChargeLender`}
            label={LABELS.receivedSuccessfulDipFromFirstChargeLender}
            register={register}
            requiredField={false}
            initValue={receivedSuccessfulDipFromFirstChargeLender}
          />

          {checkTruthyValue(receivedSuccessfulDipFromFirstChargeLender) && (
            <>
              <FormInputText
                id={`${STEP_KEY}.firstChargeLenderDipReference`}
                label={LABELS.firstChargeLenderDipReference}
                register={register}
                requiredField={false}
                error={
                  errors?.[STEP_KEY]?.firstChargeLenderDipReference?.message
                }
              />

              <FormInputSelect
                id={`${STEP_KEY}.numberOfApplicants`}
                label={LABELS.numberOfApplicants}
                register={register}
                initSelect
                options={[
                  {
                    value: 1,
                    title: "1",
                  },
                  {
                    value: 2,
                    title: "2",
                  },
                ]}
                error={errors?.[STEP_KEY]?.numberOfApplicants?.message}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default StartForm;
