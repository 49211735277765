import FormInputError from "components/Form/Input/Error";
import FormInputLabel from "components/Form/Input/Label";
import { FormInputProps } from "components/Form/Input/types";

export default function FormInputPercent({
  id,
  label,
  error,
  disabled = false,
  requiredField = true,
  register,
}: FormInputProps) {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const newValue = value.replace(/[^0-9.]/g, "");
    event.target.value = newValue;
  };

  return (
    <div>
      <FormInputLabel id={id} label={label} />
      <div className="mt-1 sm:col-span-2">
        <div className="flex rounded-md">
          <input
            type="text"
            id={id}
            required
            className="block w-full appearance-none rounded-none rounded-l-md border border-gray-300 px-3 py-2 text-base placeholder-gray-400 focus:border-brand-orange-medium focus:outline-none focus:ring-brand-orange-medium"
            disabled={disabled}
            inputMode="numeric"
            {...register(id, {
              required: requiredField,
              pattern: {
                value: /^[0-9.]*$/,
                message: "Only numbers or dots are allowed",
              },
              onChange: handleOnChange,
            })}
          />
          <span className="inline-flex items-center rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-3 text-base text-gray-500">
            %
          </span>
        </div>
        <FormInputError error={error} />
      </div>
    </div>
  );
}
