import { DEFAULT_APPLICANT_DATA } from "components/Application/Form/ApplicantDetails/defaultData";

const DEFAULT_FORM_DATA = {
  applicants: [
    {
      ...DEFAULT_APPLICANT_DATA,
      ...{
        nationality: "",
        dualNationality: "",
        hasPermanentUkResideRight: undefined,
        visaType: "",
        hasRefugeeAsylumStatus: undefined,
        hasDiplomaticImmunity: undefined,
        gender: "",
        maritalStatus: "",
      },
    },
  ],
};

export default DEFAULT_FORM_DATA;
