import { gql } from "@apollo/client";

export const QUERY_APPLICATIONS = gql`
  query Applications($status: String) {
    applications(status: $status) {
      uuid
      reference
      createdOn
      status
      applicantNames
      case {
        uuid
        reference
      }
      broker {
        applicationDate
      }
      applicants {
        title
        forename
        surname
      }
    }
  }
`;
