import React from "react";

import ApplicantDeclarationsForm from "./steps/ApplicantDeclarations";
import ApplicantDetailsForm from "./steps/ApplicantDetails";
import ApplicantEmploymentForm from "./steps/ApplicantEmployment";
import ApplicantExpenditureForm from "./steps/ApplicantExpenditure";
import BrokerDetailsForm from "./steps/BrokerDetails";
import LoanForm from "./steps/Loan";
import OtherDetailsForm from "./steps/OtherDetails";
import PropertyForm from "./steps/Property";
import ReviewConfirmForm from "./steps/ReviewConfirm";
import SolicitorDetailsForm from "./steps/SolicitorDetails";

import { APP_URLS } from "settings";
import parseDataForApiApplicantDeclarations from "./steps/ApplicantDeclarations/api";
import parseDataForApiApplicantDetails from "./steps/ApplicantDetails/api";
import parseDataForApiApplicantEmployment from "./steps/ApplicantEmployment/api";
import parseDataForApiApplicantExpenditure from "./steps/ApplicantExpenditure/api";
import parseDataForApiBroker from "./steps/BrokerDetails/api";
import parseDataForApiLoan from "./steps/Loan/api";
import parseDataForApiOther from "./steps/OtherDetails/api";
import parseDataForApiProperty from "./steps/Property/api";
import parseDataForApiReviewConfirm from "./steps/ReviewConfirm/api";
import parseDataForApiSolicitor from "./steps/SolicitorDetails/api";

export type StepType = {
  id: number;
  key:
    | "broker"
    | "applicants"
    | "declarations"
    | "employments"
    | "expenditures"
    | "loan"
    | "property"
    | "other"
    | "solicitor"
    | "confirm";
  name: string;
  component: React.FC<any>;
  parser: Function;
};

export const DEFAULT_STEP: { id: number; key: string } = {
  id: 1,
  key: "broker",
};

export const STEPS: { [k: number]: StepType } = {
  1: {
    id: 1,
    key: "broker",
    name: "Broker",
    component: BrokerDetailsForm,
    parser: parseDataForApiBroker,
  },
  2: {
    id: 2,
    key: "applicants",
    name: "Applicant",
    component: ApplicantDetailsForm,
    parser: parseDataForApiApplicantDetails,
  },
  3: {
    id: 3,
    key: "declarations",
    name: "Declarations",
    component: ApplicantDeclarationsForm,
    parser: parseDataForApiApplicantDeclarations,
  },
  4: {
    id: 4,
    key: "employments",
    name: "Employment",
    component: ApplicantEmploymentForm,
    parser: parseDataForApiApplicantEmployment,
  },
  5: {
    id: 5,
    key: "expenditures",
    name: "Expenditure",
    component: ApplicantExpenditureForm,
    parser: parseDataForApiApplicantExpenditure,
  },
  6: {
    id: 6,
    key: "loan",
    name: "Loan",
    component: LoanForm,
    parser: parseDataForApiLoan,
  },
  7: {
    id: 7,
    key: "property",
    name: "Property",
    component: PropertyForm,
    parser: parseDataForApiProperty,
  },
  8: {
    id: 8,
    key: "other",
    name: "Other Details",
    component: OtherDetailsForm,
    parser: parseDataForApiOther,
  },
  9: {
    id: 9,
    key: "solicitor",
    name: "Solicitor Details",
    component: SolicitorDetailsForm,
    parser: parseDataForApiSolicitor,
  },
  10: {
    id: 10,
    key: "confirm",
    name: "Review & Confirm",
    component: ReviewConfirmForm,
    parser: parseDataForApiReviewConfirm,
  },
};

export const TOTAL_STEPS = Object.keys(STEPS).length;

export const getStepByKey = (stepKey: string): StepType =>
  Object.values(STEPS).find((step) => step.key === stepKey) ||
  STEPS[DEFAULT_STEP.id];

export const getStepByID = (stepID: number): StepType =>
  Object.values(STEPS).find((step) => step.id === stepID) ||
  STEPS[DEFAULT_STEP.id];

export const applicationStepOne = (applicationID: string | null = null) => {
  const stepOne = STEPS[1];
  if (applicationID) {
    return `${APP_URLS.APPLICATION_FORM}/${applicationID}/${stepOne.key}`;
  }
  return `${APP_URLS.APPLICATION_FORM}/${stepOne.key}`;
};
