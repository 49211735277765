import { baseEmploymentValidation } from "components/Application/Form/ApplicantEmployment/validation";
import * as Yup from "yup";

const validation: Yup.ObjectSchema<any> = Yup.object().shape({
  applicants: Yup.array().of(
    Yup.object({
      employments: baseEmploymentValidation,
    }),
  ),
});

export default validation;
