import React from "react";

import AddressSetter from "components/Form/AddressSetter";
import FormInputEmail from "components/Form/Input/Email";
import FormInputText from "components/Form/Input/Text";
import FormTitle from "components/Form/Title";
import FormErrors from "pages/ApplicationForm/components/FormErrors";
import { StepProps } from "../../types";
import { LABELS } from "./labels";

const STEP_KEY = "solicitor";

const SolicitorDetailsForm: React.FC<StepProps> = ({
  clearErrors,
  setValue,
  register,
  errors,
}) => {
  return (
    <div className="flex flex-col space-y-4">
      <FormTitle left border title="Solicitor Details" mb={false} />

      <FormErrors formErrors={errors?.[STEP_KEY]} />

      <FormInputText
        id={`${STEP_KEY}.firmName`}
        label={LABELS.firmName}
        register={register}
        error={errors?.[STEP_KEY]?.firmName?.message}
      />

      <FormInputText
        id={`${STEP_KEY}.actingSolicitorName`}
        label={LABELS.actingSolicitorName}
        register={register}
        error={errors?.[STEP_KEY]?.actingSolicitorName?.message}
      />

      <FormInputEmail
        id={`${STEP_KEY}.emailAddress`}
        label={LABELS.emailAddress}
        register={register}
        requiredField={false}
        error={errors?.[STEP_KEY]?.emailAddress?.message}
      />

      <FormInputText
        id={`${STEP_KEY}.phoneNumber`}
        label={LABELS.phoneNumber}
        register={register}
        error={errors?.[STEP_KEY]?.phoneNumber?.message}
      />

      <FormInputText
        id={`${STEP_KEY}.reference`}
        label={LABELS.reference}
        register={register}
        error={errors?.[STEP_KEY]?.reference?.message}
      />

      <div className="flex flex-col space-y-4">
        <label className="block text-base">Solicitor address</label>

        <AddressSetter
          clearErrors={clearErrors}
          setValue={setValue}
          register={register}
          idKey={STEP_KEY}
          errorKey={errors?.[STEP_KEY]}
        />
      </div>
    </div>
  );
};

export default SolicitorDetailsForm;
