import { setDataForApi } from "utils/api";
import { FormSchemaLoan } from "./types";

const parseDataForApi = (data: FormSchemaLoan) => {
  const _data = { ...data };
  setDataForApi(_data);
  return _data;
};

export default parseDataForApi;
