import { BaseApplicantDetails } from "ts/interfaces/common/applicants";
import { APPLICATION_IN_PROGRESS_KEY } from "utils/consts";

export const SELECT_OPTION_OTHER = "O";

export const canContinueApplication = (status: string) =>
  status === APPLICATION_IN_PROGRESS_KEY;

export const getApplicantName = (applicant: BaseApplicantDetails) =>
  applicant?.forename && applicant?.surname
    ? `${applicant.forename} ${applicant.surname}`
    : "";
