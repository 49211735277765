export const ApplicantGenders = [
  {
    value: "M",
    title: "Male",
  },
  {
    value: "F",
    title: "Female",
  },
  {
    value: "N",
    title: "Prefer not to say",
  },
];

export const ApplicantMaritalStatuses = [
  {
    value: "SG",
    title: "Single",
  },
  {
    value: "M",
    title: "Married/Civil partner",
  },
  {
    value: "SP",
    title: "Separated",
  },
  {
    value: "SV",
    title: "Divorced/Person whose Civil Partnership has been dissolved",
  },
  {
    value: "SD",
    title: "Widowed/Surviving civil partner",
  },
  {
    value: "CO",
    title: "Cohabiting",
  },
  {
    value: "ND",
    title: "Not disclosed",
  },
];

export const ApplicantAddressResidentialStatuses = [
  {
    value: "RNT",
    title: "Renting",
  },
  {
    value: "LFF",
    title: "Living with family/friend",
  },
  {
    value: "HWM",
    title: "Homeowner - with mortgage",
  },
  {
    value: "HNM",
    title: "Homeowner - without mortgage",
  },
  {
    value: "RHA",
    title: "Renting from housing association",
  },
  {
    value: "CTN",
    title: "Council tenant",
  },
  {
    value: "PEM",
    title: "Provided by employer",
  },
];

export const ApplicantNationalities = [
  {
    value: "BRITISH",
    title: "British",
  },
  {
    value: "AFGHAN",
    title: "Afghan",
  },
  {
    value: "ALBANIAN",
    title: "Albanian",
  },
  {
    value: "ALGERIAN",
    title: "Algerian",
  },
  {
    value: "AMERICAN",
    title: "American",
  },
  {
    value: "ANDORRAN",
    title: "Andorran",
  },
  {
    value: "ANGOLAN",
    title: "Angolan",
  },
  {
    value: "ANGUILLAN",
    title: "Anguillan",
  },
  {
    value: "CITIZEN_OF_ANTIGUA_AND_BARBUDA",
    title: "Citizen of Antigua and Barbuda",
  },
  {
    value: "ARGENTINE",
    title: "Argentine",
  },
  {
    value: "ARMENIAN",
    title: "Armenian",
  },
  {
    value: "AUSTRALIAN",
    title: "Australian",
  },
  {
    value: "AUSTRIAN",
    title: "Austrian",
  },
  {
    value: "AZERBAIJANI",
    title: "Azerbaijani",
  },
  {
    value: "BAHAMIAN",
    title: "Bahamian",
  },
  {
    value: "BAHRAINI",
    title: "Bahraini",
  },
  {
    value: "BANGLADESHI",
    title: "Bangladeshi",
  },
  {
    value: "BARBADIAN",
    title: "Barbadian",
  },
  {
    value: "BELARUSIAN",
    title: "Belarusian",
  },
  {
    value: "BELGIAN",
    title: "Belgian",
  },
  {
    value: "BELIZEAN",
    title: "Belizean",
  },
  {
    value: "BENINESE",
    title: "Beninese",
  },
  {
    value: "BERMUDIAN",
    title: "Bermudian",
  },
  {
    value: "BHUTANESE",
    title: "Bhutanese",
  },
  {
    value: "BOLIVIAN",
    title: "Bolivian",
  },
  {
    value: "CITIZEN_OF_BOSNIA_AND_HERZEGOVINA",
    title: "Citizen of Bosnia and Herzegovina",
  },
  {
    value: "BOTSWANAN",
    title: "Botswanan",
  },
  {
    value: "BRAZILIAN",
    title: "Brazilian",
  },
  {
    value: "BRITISH",
    title: "British",
  },
  {
    value: "BRITISH_VIRGIN_ISLANDER",
    title: "British Virgin Islander",
  },
  {
    value: "BRUNEIAN",
    title: "Bruneian",
  },
  {
    value: "BULGARIAN",
    title: "Bulgarian",
  },
  {
    value: "BURKINAN",
    title: "Burkinan",
  },
  {
    value: "BURMESE",
    title: "Burmese",
  },
  {
    value: "BURUNDIAN",
    title: "Burundian",
  },
  {
    value: "CAMBODIAN",
    title: "Cambodian",
  },
  {
    value: "CAMEROONIAN",
    title: "Cameroonian",
  },
  {
    value: "CANADIAN",
    title: "Canadian",
  },
  {
    value: "CAPE_VERDEAN",
    title: "Cape Verdean",
  },
  {
    value: "CAYMAN_ISLANDER",
    title: "Cayman Islander",
  },
  {
    value: "CENTRAL_AFRICAN",
    title: "Central African",
  },
  {
    value: "CHADIAN",
    title: "Chadian",
  },
  {
    value: "CHILEAN",
    title: "Chilean",
  },
  {
    value: "CHINESE",
    title: "Chinese",
  },
  {
    value: "COLOMBIAN",
    title: "Colombian",
  },
  {
    value: "COMORAN",
    title: "Comoran",
  },
  {
    value: "CONGOLESE_CONGO",
    title: "Congolese (Congo)",
  },
  {
    value: "CONGOLESE_DRC",
    title: "Congolese (DRC)",
  },
  {
    value: "COOK_ISLANDER",
    title: "Cook Islander",
  },
  {
    value: "COSTA_RICAN",
    title: "Costa Rican",
  },
  {
    value: "CROATIAN",
    title: "Croatian",
  },
  {
    value: "CUBAN",
    title: "Cuban",
  },
  {
    value: "CYMRAES",
    title: "Cymraes",
  },
  {
    value: "CYMRO",
    title: "Cymro",
  },
  {
    value: "CYPRIOT",
    title: "Cypriot",
  },
  {
    value: "CZECH",
    title: "Czech",
  },
  {
    value: "DANISH",
    title: "Danish",
  },
  {
    value: "DJIBOUTIAN",
    title: "Djiboutian",
  },
  {
    value: "DOMINICAN",
    title: "Dominican",
  },
  {
    value: "CITIZEN_OF_THE_DOMINICAN_REPUBLIC",
    title: "Citizen of the Dominican Republic",
  },
  {
    value: "DUTCH",
    title: "Dutch",
  },
  {
    value: "EAST_TIMORESE",
    title: "East Timorese",
  },
  {
    value: "ECUADOREAN",
    title: "Ecuadorean",
  },
  {
    value: "EGYPTIAN",
    title: "Egyptian",
  },
  {
    value: "EMIRATI",
    title: "Emirati",
  },
  {
    value: "ENGLISH",
    title: "English",
  },
  {
    value: "EQUATORIAL_GUINEAN",
    title: "Equatorial Guinean",
  },
  {
    value: "ERITREAN",
    title: "Eritrean",
  },
  {
    value: "ESTONIAN",
    title: "Estonian",
  },
  {
    value: "ETHIOPIAN",
    title: "Ethiopian",
  },
  {
    value: "FAROESE",
    title: "Faroese",
  },
  {
    value: "FIJIAN",
    title: "Fijian",
  },
  {
    value: "FILIPINO",
    title: "Filipino",
  },
  {
    value: "FINNISH",
    title: "Finnish",
  },
  {
    value: "FRENCH",
    title: "French",
  },
  {
    value: "GABONESE",
    title: "Gabonese",
  },
  {
    value: "GAMBIAN",
    title: "Gambian",
  },
  {
    value: "GEORGIAN",
    title: "Georgian",
  },
  {
    value: "GERMAN",
    title: "German",
  },
  {
    value: "GHANAIAN",
    title: "Ghanaian",
  },
  {
    value: "GIBRALTARIAN",
    title: "Gibraltarian",
  },
  {
    value: "GREEK",
    title: "Greek",
  },
  {
    value: "GREENLANDIC",
    title: "Greenlandic",
  },
  {
    value: "GRENADIAN",
    title: "Grenadian",
  },
  {
    value: "GUAMANIAN",
    title: "Guamanian",
  },
  {
    value: "GUATEMALAN",
    title: "Guatemalan",
  },
  {
    value: "CITIZEN_OF_GUINEA_BISSAU",
    title: "Citizen of Guinea-Bissau",
  },
  {
    value: "GUINEAN",
    title: "Guinean",
  },
  {
    value: "GUYANESE",
    title: "Guyanese",
  },
  {
    value: "HAITIAN",
    title: "Haitian",
  },
  {
    value: "HONDURAN",
    title: "Honduran",
  },
  {
    value: "HONG_KONGER",
    title: "Hong Konger",
  },
  {
    value: "HUNGARIAN",
    title: "Hungarian",
  },
  {
    value: "ICELANDIC",
    title: "Icelandic",
  },
  {
    value: "INDIAN",
    title: "Indian",
  },
  {
    value: "INDONESIAN",
    title: "Indonesian",
  },
  {
    value: "IRANIAN",
    title: "Iranian",
  },
  {
    value: "IRAQI",
    title: "Iraqi",
  },
  {
    value: "IRISH",
    title: "Irish",
  },
  {
    value: "ISRAELI",
    title: "Israeli",
  },
  {
    value: "ITALIAN",
    title: "Italian",
  },
  {
    value: "IVORIAN",
    title: "Ivorian",
  },
  {
    value: "JAMAICAN",
    title: "Jamaican",
  },
  {
    value: "JAPANESE",
    title: "Japanese",
  },
  {
    value: "JORDANIAN",
    title: "Jordanian",
  },
  {
    value: "KAZAKH",
    title: "Kazakh",
  },
  {
    value: "KENYAN",
    title: "Kenyan",
  },
  {
    value: "KITTITIAN",
    title: "Kittitian",
  },
  {
    value: "CITIZEN_OF_KIRIBATI",
    title: "Citizen of Kiribati",
  },
  {
    value: "KOSOVAN",
    title: "Kosovan",
  },
  {
    value: "KUWAITI",
    title: "Kuwaiti",
  },
  {
    value: "KYRGYZ",
    title: "Kyrgyz",
  },
  {
    value: "LAO",
    title: "Lao",
  },
  {
    value: "LATVIAN",
    title: "Latvian",
  },
  {
    value: "LEBANESE",
    title: "Lebanese",
  },
  {
    value: "LIBERIAN",
    title: "Liberian",
  },
  {
    value: "LIBYAN",
    title: "Libyan",
  },
  {
    value: "LIECHTENSTEIN_CITIZEN",
    title: "Liechtenstein citizen",
  },
  {
    value: "LITHUANIAN",
    title: "Lithuanian",
  },
  {
    value: "LUXEMBOURGER",
    title: "Luxembourger",
  },
  {
    value: "MACANESE",
    title: "Macanese",
  },
  {
    value: "MACEDONIAN",
    title: "Macedonian",
  },
  {
    value: "MALAGASY",
    title: "Malagasy",
  },
  {
    value: "MALAWIAN",
    title: "Malawian",
  },
  {
    value: "MALAYSIAN",
    title: "Malaysian",
  },
  {
    value: "MALDIVIAN",
    title: "Maldivian",
  },
  {
    value: "MALIAN",
    title: "Malian",
  },
  {
    value: "MALTESE",
    title: "Maltese",
  },
  {
    value: "MARSHALLESE",
    title: "Marshallese",
  },
  {
    value: "MARTINIQUAIS",
    title: "Martiniquais",
  },
  {
    value: "MAURITANIAN",
    title: "Mauritanian",
  },
  {
    value: "MAURITIAN",
    title: "Mauritian",
  },
  {
    value: "MEXICAN",
    title: "Mexican",
  },
  {
    value: "MICRONESIAN",
    title: "Micronesian",
  },
  {
    value: "MOLDOVAN",
    title: "Moldovan",
  },
  {
    value: "MONEGASQUE",
    title: "Monegasque",
  },
  {
    value: "MONGOLIAN",
    title: "Mongolian",
  },
  {
    value: "MONTENEGRIN",
    title: "Montenegrin",
  },
  {
    value: "MONTSERRATIAN",
    title: "Montserratian",
  },
  {
    value: "MOROCCAN",
    title: "Moroccan",
  },
  {
    value: "MOSOTHO",
    title: "Mosotho",
  },
  {
    value: "MOZAMBICAN",
    title: "Mozambican",
  },
  {
    value: "NAMIBIAN",
    title: "Namibian",
  },
  {
    value: "NAURUAN",
    title: "Nauruan",
  },
  {
    value: "NEPALESE",
    title: "Nepalese",
  },
  {
    value: "NEW_ZEALANDER",
    title: "New Zealander",
  },
  {
    value: "NICARAGUAN",
    title: "Nicaraguan",
  },
  {
    value: "NIGERIAN",
    title: "Nigerian",
  },
  {
    value: "NIGERIEN",
    title: "Nigerien",
  },
  {
    value: "NIUEAN",
    title: "Niuean",
  },
  {
    value: "NORTH_KOREAN",
    title: "North Korean",
  },
  {
    value: "NORTHERN_IRISH",
    title: "Northern Irish",
  },
  {
    value: "NORWEGIAN",
    title: "Norwegian",
  },
  {
    value: "OMANI",
    title: "Omani",
  },
  {
    value: "PAKISTANI",
    title: "Pakistani",
  },
  {
    value: "PALAUAN",
    title: "Palauan",
  },
  {
    value: "PALESTINIAN",
    title: "Palestinian",
  },
  {
    value: "PANAMANIAN",
    title: "Panamanian",
  },
  {
    value: "PAPUA_NEW_GUINEAN",
    title: "Papua New Guinean",
  },
  {
    value: "PARAGUAYAN",
    title: "Paraguayan",
  },
  {
    value: "PERUVIAN",
    title: "Peruvian",
  },
  {
    value: "PITCAIRN_ISLANDER",
    title: "Pitcairn Islander",
  },
  {
    value: "POLISH",
    title: "Polish",
  },
  {
    value: "PORTUGUESE",
    title: "Portuguese",
  },
  {
    value: "PRYDEINIG",
    title: "Prydeinig",
  },
  {
    value: "PUERTO_RICAN",
    title: "Puerto Rican",
  },
  {
    value: "QATARI",
    title: "Qatari",
  },
  {
    value: "ROMANIAN",
    title: "Romanian",
  },
  {
    value: "RUSSIAN",
    title: "Russian",
  },
  {
    value: "RWANDAN",
    title: "Rwandan",
  },
  {
    value: "SALVADOREAN",
    title: "Salvadorean",
  },
  {
    value: "SAMMARINESE",
    title: "Sammarinese",
  },
  {
    value: "SAMOAN",
    title: "Samoan",
  },
  {
    value: "SAO_TOMEAN",
    title: "Sao Tomean",
  },
  {
    value: "SAUDI_ARABIAN",
    title: "Saudi Arabian",
  },
  {
    value: "SCOTTISH",
    title: "Scottish",
  },
  {
    value: "SENEGALESE",
    title: "Senegalese",
  },
  {
    value: "SERBIAN",
    title: "Serbian",
  },
  {
    value: "CITIZEN_OF_SEYCHELLES",
    title: "Citizen of Seychelles",
  },
  {
    value: "SIERRA_LEONEAN",
    title: "Sierra Leonean",
  },
  {
    value: "SINGAPOREAN",
    title: "Singaporean",
  },
  {
    value: "SLOVAK",
    title: "Slovak",
  },
  {
    value: "SLOVENIAN",
    title: "Slovenian",
  },
  {
    value: "SOLOMON_ISLANDER",
    title: "Solomon Islander",
  },
  {
    value: "SOMALI",
    title: "Somali",
  },
  {
    value: "SOUTH_AFRICAN",
    title: "South African",
  },
  {
    value: "SOUTH_KOREAN",
    title: "South Korean",
  },
  {
    value: "SOUTH_SUDANESE",
    title: "South Sudanese",
  },
  {
    value: "SPANISH",
    title: "Spanish",
  },
  {
    value: "SRI_LANKAN",
    title: "Sri Lankan",
  },
  {
    value: "ST_HELENIAN",
    title: "St Helenian",
  },
  {
    value: "ST_LUCIAN",
    title: "St Lucian",
  },
  {
    value: "STATELESS",
    title: "Stateless",
  },
  {
    value: "SUDANESE",
    title: "Sudanese",
  },
  {
    value: "SURINAMESE",
    title: "Surinamese",
  },
  {
    value: "SWAZI",
    title: "Swazi",
  },
  {
    value: "SWEDISH",
    title: "Swedish",
  },
  {
    value: "SWISS",
    title: "Swiss",
  },
  {
    value: "SYRIAN",
    title: "Syrian",
  },
  {
    value: "TAIWANESE",
    title: "Taiwanese",
  },
  {
    value: "TAJIK",
    title: "Tajik",
  },
  {
    value: "TANZANIAN",
    title: "Tanzanian",
  },
  {
    value: "THAI",
    title: "Thai",
  },
  {
    value: "TOGOLESE",
    title: "Togolese",
  },
  {
    value: "TONGAN",
    title: "Tongan",
  },
  {
    value: "TRINIDADIAN",
    title: "Trinidadian",
  },
  {
    value: "TRISTANIAN",
    title: "Tristanian",
  },
  {
    value: "TUNISIAN",
    title: "Tunisian",
  },
  {
    value: "TURKISH",
    title: "Turkish",
  },
  {
    value: "TURKMEN",
    title: "Turkmen",
  },
  {
    value: "TURKS_AND_CAICOS_ISLANDER",
    title: "Turks and Caicos Islander",
  },
  {
    value: "TUVALUAN",
    title: "Tuvaluan",
  },
  {
    value: "UGANDAN",
    title: "Ugandan",
  },
  {
    value: "UKRAINIAN",
    title: "Ukrainian",
  },
  {
    value: "URUGUAYAN",
    title: "Uruguayan",
  },
  {
    value: "UZBEK",
    title: "Uzbek",
  },
  {
    value: "VATICAN_CITIZEN",
    title: "Vatican citizen",
  },
  {
    value: "CITIZEN_OF_VANUATU",
    title: "Citizen of Vanuatu",
  },
  {
    value: "VENEZUELAN",
    title: "Venezuelan",
  },
  {
    value: "VIETNAMESE",
    title: "Vietnamese",
  },
  {
    value: "VINCENTIAN",
    title: "Vincentian",
  },
  {
    value: "WALLISIAN",
    title: "Wallisian",
  },
  {
    value: "WELSH",
    title: "Welsh",
  },
  {
    value: "YEMENI",
    title: "Yemeni",
  },
  {
    value: "ZAMBIAN",
    title: "Zambian",
  },
  {
    value: "ZIMBABWEAN",
    title: "Zimbabwean",
  },
];
