import CTAButton from "components/CTA/Button";
import useApplicationStore from "store/Application";
import { APPLICATION_COMPLETE_KEY } from "utils/consts";
import { FormSchemaStatus } from "../formSchema";
import { StepType, TOTAL_STEPS } from "../formSteps";

interface NavigationProps {
  currentStep: StepType;
  handlePrevious: (validate: boolean) => void;
  handleSaveProgress: (validate: boolean) => void;
  handleNext: () => void;
}

const FormNav: React.FC<NavigationProps> = ({
  currentStep,
  handlePrevious,
  handleSaveProgress,
  handleNext,
}) => {
  const {
    formStatus: { [currentStep.key as keyof FormSchemaStatus]: stepStatus },
  } = useApplicationStore();

  const shouldTriggerValidate =
    stepStatus === APPLICATION_COMPLETE_KEY && currentStep.id < TOTAL_STEPS;

  return (
    <nav className="!mt-8 flex justify-between border-t border-gray-200 pt-8">
      <div>
        {currentStep.id > 1 && (
          <CTAButton
            secondary
            label="Previous"
            onClick={() => handlePrevious(shouldTriggerValidate)}
          />
        )}
      </div>
      <div className="flex space-x-2">
        {currentStep.id === TOTAL_STEPS ? (
          <CTAButton type="submit" label="Submit Application" />
        ) : (
          <>
            <CTAButton
              secondary
              label="Save progress"
              onClick={() => handleSaveProgress(shouldTriggerValidate)}
            />
            <div className="form-submit">
              <CTAButton label="Submit step" onClick={handleNext} />
            </div>
          </>
        )}
      </div>
    </nav>
  );
};

export default FormNav;
