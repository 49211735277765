const DEFAULT_FORM_DATA = {
  broker: {
    email: "",
    feeAmount: undefined,
    firmName: "",
    isFeeCharged: undefined,
    levelOfAdviceGiven: "",
    name: "",
    phoneNumber: "",
    confirmBrokerDeclarations: undefined,
  },
};

export default DEFAULT_FORM_DATA;
