import applicantsValidation from "./steps/ApplicantDetails/validation";
import employmentValidation from "./steps/ApplicantEmployment/validation";
import expenditureValidation from "./steps/ApplicantExpenditure/validation";
import loanValidation from "./steps/Loan/validation";
import startValidation from "./steps/Start/validation";

export const getValidationSchema = (stepKey: string) => {
  switch (stepKey) {
    case "start":
      return startValidation;
    case "applicants":
      return applicantsValidation;
    case "employments":
      return employmentValidation;
    case "expenditures":
      return expenditureValidation;
    case "loan":
      return loanValidation;
    default:
      return startValidation;
  }
};
