import { useMutation, useQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { MUTATION_SET_USER_PASSWORD } from "api/mutations/account";
import { QUERY_VALIDATE_ACCOUNT_RESET_PASSWORD_TOKEN } from "api/queries/account";

import AlertError from "components/Alert/Error";
import AlertSuccess from "components/Alert/Success";
import CTAButton from "components/CTA/Button";
import FormInputPassword from "components/Form/Input/Password";
import Loading from "components/Loading";
import PageContainer from "components/Page/Container";
import PageDialogScreen from "components/Page/DialogScreen";
import { AHAUZ_PHONE_NUMBER, APP_URLS } from "settings";
import { NewPasswordFormInputs, newPasswordSchema } from "utils/validation";

export default function ResetPassword() {
  let params = useParams();
  let { uidb64, token } = params;

  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(QUERY_VALIDATE_ACCOUNT_RESET_PASSWORD_TOKEN, {
    variables: {
      uidb64: uidb64,
      token: token,
    },
  });

  const [
    resetPassword,
    {
      loading: mutationLoading,
      error: mutationError,
      data: mutationData,
      reset: resetMutation,
    },
  ] = useMutation(MUTATION_SET_USER_PASSWORD);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<NewPasswordFormInputs>({
    resolver: yupResolver(newPasswordSchema),
    defaultValues: {
      newPassword1: "",
      newPassword2: "",
    },
  });

  const tokenQueryOk =
    queryData && queryData?.accountValidateResetPasswordToken?.ok;
  const newPassword1 = watch("newPassword1");
  const newPassword2 = watch("newPassword2");

  useEffect(() => {
    if (mutationData?.response?.ok) {
      setTimeout(() => (window.location.href = APP_URLS.DASHBOARD), 2000);
    }
  }, [mutationData]);

  const onSubmit: SubmitHandler<NewPasswordFormInputs> = (data) => {
    resetMutation();

    resetPassword({
      variables: {
        userPassword: {
          uidb64: uidb64,
          newPassword1: data.newPassword1,
          newPassword2: data.newPassword2,
        },
      },
    });
  };

  const renderResetPasswordSuccess = () => (
    <AlertSuccess title="Password reset!" shadow>
      <p>
        Your password has been reset and we'll now redirect you to your{" "}
        <a
          href={APP_URLS.DASHBOARD}
          className="text-green-800 underline hover:text-green-800"
        >
          Dashboard
        </a>
        .
      </p>
    </AlertSuccess>
  );

  const renderForm = () => (
    <PageContainer>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="space-y-4">
          <FormInputPassword
            id="newPassword1"
            register={register}
            error={
              errors?.newPassword1?.message ||
              mutationData?.response?.errors?.newPassword1?.[0]
            }
          />
          <FormInputPassword
            id="newPassword2"
            label="Confirm Password"
            register={register}
            validate={false}
            error={
              errors?.newPassword2?.message ||
              mutationData?.response?.errors?.newPassword2?.[0]
            }
          />
          <div className="pt-2">
            {mutationLoading ? (
              <Loading />
            ) : (
              <CTAButton
                type="submit"
                enabled={!!(newPassword1 && newPassword2)}
                label="Submit"
                full
              />
            )}
          </div>
        </div>
      </form>
    </PageContainer>
  );

  const renderError = () => (
    <AlertError>
      <>
        <p className="mb-2 font-medium">
          Sorry, there is an error with your reset password link. It could have
          expired or have been used already.
        </p>
        <p>
          Please try again, or contact our Support team on {AHAUZ_PHONE_NUMBER}.
        </p>
      </>
    </AlertError>
  );

  const renderResetPassword = () => {
    if (mutationData?.response?.ok) {
      return renderResetPasswordSuccess();
    }
    if (queryLoading) {
      return <Loading />;
    }
    if (!(queryError || mutationError) && tokenQueryOk) {
      return renderForm();
    }
    return renderError();
  };

  return (
    <PageDialogScreen
      title="Reset password"
      copy="Set a new account password to login to your Dashboard"
    >
      <>
        {renderResetPassword()}
        <div className="mt-8 text-center text-base text-brand-copy-light">
          Looking for the login form?{" "}
          <a href={APP_URLS.LOGIN}>Click here to sign in</a>
        </div>
      </>
    </PageDialogScreen>
  );
}
