import { setDataForApi } from "utils/api";
import { FormSchemaProperty } from "./types";

const parseDataForApi = (data: FormSchemaProperty) => {
  const _data = { ...data };
  setDataForApi(_data);
  return _data;
};

export default parseDataForApi;
