import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { classNames } from "utils/helpers";

type Props = {
  title: string;
  children: JSX.Element;
  shadow?: boolean;
};

export default function AlertSuccess({
  title,
  children,
  shadow = false,
}: Props) {
  return (
    <div
      className={classNames(
        shadow ? "border shadow-lg" : "",
        "rounded-md bg-green-100 p-4",
      )}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="h-5 w-5 text-green-800"
            aria-hidden="true"
          />
        </div>
        <div className="-mt-0.5 ml-3 text-base text-green-800">
          <p className="font-medium">{title}</p>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
}
