export const parseBool = (anyVal: any) => {
  if (anyVal === "true") {
    return true;
  }
  if (anyVal === "false") {
    return false;
  }
  return anyVal;
};

export const setDataForApi = (data: any) => {
  if (!data) {
    return data;
  }

  Object.keys(data).forEach((key) => {
    // Remove null values
    if (data[key] === "" || data[key] == null) {
      delete data[key];
    }
    // Set true strings as bool
    if (data[key] === "true" || data[key] === "yes") {
      data[key] = true;
    }
    // Set false strings as bool
    if (data[key] === "false" || data[key] === "no") {
      data[key] = false;
    }
    // If we still have key, check it against default choice values
    // if (data[key]) {
    //   data[key] = setDefaultChoiceVal(data[key]);
    //

    // if (data[key] instanceof Date) {
    //   const offset = data[key].getTimezoneOffset()
    //   const newDateWithOffset = new Date(data[key].getTime() - (offset * 60 * 1000))
    //   data[key] = newDateWithOffset.toISOString().split('T')[0];
    // }
  });

  // Remove keys
  if (data.status) {
    delete data.status;
  }
  if (data.application) {
    delete data.application;
  }
  if (data.case) {
    delete data.application;
  }
  if (data.numberOfApplicants) {
    delete data.numberOfApplicants;
  }
  if (data.address) {
    delete data.address;
  }

  return data;
};
