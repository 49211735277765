import ApplicantEmploymentFormFields from "components/Application/Form/ApplicantEmployment";
import FormTitle from "components/Form/Title";
import FormErrors from "pages/ApplicationForm/components/FormErrors";
import { getApplicantName } from "pages/ApplicationForm/utils";
import { FormSchemaApplicants } from "pages/DecisionInPrinciple/formSchema";
import { StepProps } from "pages/DecisionInPrinciple/types";

const STEP_KEY = "employments";
const PARENT_KEY = "applicants";

const ApplicantEmploymentForm: React.FC<StepProps> = ({
  clearErrors,
  register,
  errors,
  setValue,
  applicantsFields,
  control,
}) => {
  const stepErrors = errors.applicants as unknown as FormSchemaApplicants;

  return (
    <div className="flex flex-col space-y-4">
      <FormTitle left border title="Applicant Employment" mb={false} />

      {stepErrors && (
        <FormErrors
          formErrors={Object.values(stepErrors).map((errs) => errs.employments)}
        />
      )}

      <>
        {applicantsFields ? (
          applicantsFields.map((applicant: FormSchemaApplicants, k: number) => {
            const applicantNumber = k + 1;
            const idKey = `${PARENT_KEY}.${k}.${STEP_KEY}`;
            const errorKey = errors?.[PARENT_KEY]?.[k]?.[STEP_KEY];

            return (
              <div key={k} className="pt-4">
                <FormTitle
                  left
                  title={`Applicant: ${
                    getApplicantName(applicant.details) || applicantNumber
                  }`}
                />

                <ApplicantEmploymentFormFields
                  register={register}
                  control={control}
                  idKey={idKey}
                  errorKey={errorKey}
                  clearErrors={clearErrors}
                  setValue={setValue}
                />
              </div>
            );
          })
        ) : (
          <p className="pt-4 text-center">
            Please first submit Applicant Details to complete this step.
          </p>
        )}
      </>
    </div>
  );
};

export default ApplicantEmploymentForm;
