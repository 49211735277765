import React from "react";
import { useWatch } from "react-hook-form";

import FormInputCurrency from "components/Form/Input/Currency";
import FormInputEmail from "components/Form/Input/Email";
import FormInputRadio from "components/Form/Input/Radio";
import FormInputSelect from "components/Form/Input/Select";
import FormInputText from "components/Form/Input/Text";
import FormTitle from "components/Form/Title";
import FormErrors from "pages/ApplicationForm/components/FormErrors";
import { checkTruthyValue } from "utils/validation";
import { StepProps } from "../../types";
import { LABELS } from "./labels";
import { BrokerDetailAdviceLevels } from "./options";

const STEP_KEY = "broker";

const BrokerDetailsForm: React.FC<StepProps> = ({
  control,
  register,
  errors,
  setValue,
}) => {
  const isFeeCharged = useWatch({
    name: `${STEP_KEY}.isFeeCharged`,
    control: control,
  });
  const confirmBrokerDeclarations = useWatch({
    name: `${STEP_KEY}.confirmBrokerDeclarations`,
    control: control,
  });

  return (
    <div className="flex flex-col space-y-4">
      <FormTitle left border title="Broker Details" mb={false} />

      <FormErrors formErrors={errors?.[STEP_KEY]} />

      <FormInputText
        id={`${STEP_KEY}.name`}
        label={LABELS.name}
        register={register}
        requiredField={false}
        error={errors?.[STEP_KEY]?.name?.message}
      />

      <FormInputText
        id={`${STEP_KEY}.firmName`}
        label={LABELS.firmName}
        register={register}
        requiredField={false}
        error={errors?.[STEP_KEY]?.firmName?.message}
      />

      <FormInputEmail
        id={`${STEP_KEY}.email`}
        label={LABELS.email}
        register={register}
        requiredField={false}
        error={errors?.[STEP_KEY]?.email?.message}
      />

      <FormInputText
        id={`${STEP_KEY}.phoneNumber`}
        label={LABELS.phoneNumber}
        register={register}
        requiredField={false}
        error={errors?.[STEP_KEY]?.phoneNumber?.message}
      />

      <FormInputRadio
        id={`${STEP_KEY}.isFeeCharged`}
        label={LABELS.isFeeCharged}
        register={register}
        requiredField={false}
        initValue={isFeeCharged}
        error={errors?.[STEP_KEY]?.isFeeCharged?.message}
      />

      {checkTruthyValue(isFeeCharged) && (
        <FormInputCurrency
          id={`${STEP_KEY}.feeAmount`}
          label={LABELS.feeAmount}
          register={register}
          requiredField={false}
          setValue={setValue}
          error={errors?.[STEP_KEY]?.feeAmount?.message}
        />
      )}

      <FormInputSelect
        id={`${STEP_KEY}.levelOfAdviceGiven`}
        label={LABELS.levelOfAdviceGiven}
        register={register}
        initSelect
        options={BrokerDetailAdviceLevels}
        requiredField={false}
        error={errors?.[STEP_KEY]?.levelOfAdviceGiven?.message}
      />

      <div className="!mt-8 border-t border-gray-200 pt-6">
        <span className="block text-base font-semibold">
          Before you proceed, please confirm that the following statements apply
          to the application:
        </span>

        <ul className="mb-3 ml-4 mt-2 list-disc leading-relaxed">
          <li>
            There are no more than two applicants, and I have verified the
            identity of all applicants.
          </li>
          <li>
            I have read and agree to the Intermediary Terms of Business. This
            forms a legal contract between Ahauz and myself.
          </li>
          <li>
            All applicants have consented to my acting on their behalf, and I
            hold the necessary qualifications required to advise on regulated
            mortgage products in accordance with Financial Conduct Authority
            (FCA) rules.
          </li>
          <li>
            I have provided each applicant with a copy of the Ahauz Privacy
            Policy and given them the opportunity to read it. Each applicant
            agrees to their information being used in the way specified. Please
            note that we may update this document from time to time; the latest
            copy can be found{" "}
            <a
              href="https://ahauz.com/privacy-policy/"
              target="_blank"
              rel="noreferrer"
              className="link underline"
            >
              here
            </a>
            .
          </li>
          <li>
            I have outlined the nature of the mortgage and the product to the
            applicant(s), and explained the nature and amount of any fees
            payable by the applicant(s) and confirmed with the applicant(s)
            which fees are non-refundable, even if Ahauz does not offer them a
            mortgage or the mortgage application does not proceed for any
            reason.
          </li>
          <li>
            I have discussed the affordability of the mortgage with the
            applicant(s), informed them of the need to provide accurate income
            details, and discussed the plan for the payment of the interest-only
            element of the loan.
          </li>
          <li>
            The applicant(s) have received and been given time to consider a
            European Standard Information Sheet.
          </li>
          <li>
            To the best of my knowledge and belief, all information provided in
            this application is true, accurate, and complete.
          </li>
        </ul>

        <FormInputRadio
          id={`${STEP_KEY}.confirmBrokerDeclarations`}
          label={LABELS.confirmBrokerDeclarations}
          register={register}
          requiredField={false}
          initValue={confirmBrokerDeclarations}
          error={errors?.[STEP_KEY]?.confirmBrokerDeclarations?.message}
        />
      </div>
    </div>
  );
};

export default BrokerDetailsForm;
