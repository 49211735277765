export const ApplicantTitleHonorifics = [
  {
    value: "MR",
    title: "Mr",
  },
  {
    value: "MISS",
    title: "Miss",
  },
  {
    value: "MRS",
    title: "Mrs",
  },
  {
    value: "MS",
    title: "Ms",
  },
  {
    value: "MX",
    title: "Mx",
  },
  {
    value: "DR",
    title: "Dr",
  },
  {
    value: "OT",
    title: "Other",
  },
];
