import { gql } from "@apollo/client";

export const MUTATION_CREATE_CASE = gql`
  mutation {
    caseCreate {
      case {
        uuid
        reference
        status
        createdOn
        applications {
          reference
          createdOn
          status
        }
        illustrations {
          uuid
          reference
          createdOn
        }
      }
      ok
    }
  }
`;

export const MUTATION_DELETE_CASE = gql`
  mutation ($case: UUID!) {
    caseDelete(case: $case) {
      ok
      errors
    }
  }
`;
