import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

import FormInputEmail from "components/Form/Input/Email";
import FormInputNumber from "components/Form/Input/Number";
import FormInputText from "components/Form/Input/Text";
import FormInputTextArea from "components/Form/Input/TextArea";
import PageContainer from "components/Page/Container";
import PageContent from "components/Page/Content";
import { useEffect } from "react";
import { useUserStore } from "store/User";
import { VALIDATION_EMAIL, VALIDATION_REQUIRED } from "utils/validation";

interface FormInputs {
  firstName: string;
  lastName: string;
  email: string;
  firmName: string;
  firmPhoneNumber: string;
  firmAddress: string;
  firmWebsite: string;
  firmFcaNumber: string;
  brokerageFee: string;
}

const schema = yup.object({
  firstName: yup.string().required(VALIDATION_REQUIRED),
  lastName: yup.string().required(VALIDATION_REQUIRED),
  email: yup.string().email(VALIDATION_EMAIL).required(VALIDATION_REQUIRED),
  firmName: yup.string().required(VALIDATION_REQUIRED),
  firmPhoneNumber: yup.string().required(VALIDATION_REQUIRED),
  firmAddress: yup.string().required(VALIDATION_REQUIRED),
  firmWebsite: yup.string().required(VALIDATION_REQUIRED),
  firmFcaNumber: yup.string().required(VALIDATION_REQUIRED),
  brokerageFee: yup.string().required(VALIDATION_REQUIRED),
});

export default function AccountProfile() {
  const user = useUserStore((state) => state.user);

  // const [
  //   updateAccount,
  //   { loading: mutationLoading, error: mutationError, data: mutationData },
  // ] = useMutation(MUTATION_UPDATE_ACCOUNT);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      firmName: user?.firmName,
      firmPhoneNumber: user?.firmPhoneNumber,
      firmAddress: user?.firmAddress,
      firmWebsite: user?.firmWebsite,
      firmFcaNumber: user?.firmFcaNumber,
      brokerageFee: user?.brokerageFee,
    },
  });

  // const hasErrors = Object.keys(errors).length > 0;

  useEffect(() => {
    if (!isDirty) {
      reset({
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        firmName: user?.firmName,
        firmPhoneNumber: user?.firmPhoneNumber,
        firmAddress: user?.firmAddress,
        firmWebsite: user?.firmWebsite,
        firmFcaNumber: user?.firmFcaNumber,
        brokerageFee: user?.brokerageFee,
      });
    }
  }, [user, isDirty, reset]);

  // useEffect(() => {
  //   if (mutationData?.response?.ok) {
  //     setUser(mutationData.response.broker);
  //   } else {
  //     if (!mutationData?.response?.errors) {
  //       return;
  //     }

  //     const serverErrors: FormInputs = mutationData.response.errors;

  //     for (let [key, errors] of Object.entries(serverErrors)) {
  //       setError(key as keyof FormInputs, {
  //         type: "server",
  //         message: errors[0],
  //       });
  //     }
  //   }
  // }, [mutationData, setError, setUser]);

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    return;
    // TEMP DISBALING
    // updateAccount({
    //   variables: {
    //     input: {
    //       firstName: data.firstName,
    //       lastName: data.lastName,
    //       email: data.email,
    //       firmName: data.firmName,
    //       firmPhoneNumber: data.firmPhoneNumber,
    //       firmAddress: data.firmAddress,
    //       firmWebsite: data.firmWebsite,
    //       firmFcaNumber: data.firmFcaNumber,
    //       brokerageFee: data.brokerageFee,
    //     },
    //   },
    // });
  };

  return (
    <PageContent title="Account">
      <div className="mx-auto max-w-3xl">
        <PageContainer>
          <div className="space-y-4">
            <div>
              <p className="font-semibold">
                If you need to update any of the below details, please contact
                us so that we can confirm the changes to your account.
              </p>
            </div>
            <hr />
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <div className="space-y-4">
                <FormInputText
                  id="firstName"
                  label="First name"
                  register={register}
                  disabled
                  error={errors?.firstName?.message}
                />
                <FormInputText
                  id="lastName"
                  label="Last name"
                  register={register}
                  disabled
                  error={errors?.lastName?.message}
                />
                <FormInputEmail
                  id="email"
                  label="Email"
                  register={register}
                  // onChange={handleInputChange}
                  disabled
                  error={errors?.email?.message}
                />
                <FormInputText
                  id="firmName"
                  label="Firm name"
                  register={register}
                  disabled
                  error={errors?.firmName?.message}
                />
                <FormInputText
                  id="firmPhoneNumber"
                  label="Firm phone number"
                  register={register}
                  disabled
                  error={errors?.firmPhoneNumber?.message}
                />
                <FormInputTextArea
                  id="firmAddress"
                  label="Firm address"
                  register={register}
                  disabled
                  error={errors?.firmAddress?.message}
                />
                <FormInputText
                  id="firmWebsite"
                  label="Firm website"
                  type="url"
                  register={register}
                  disabled
                  error={errors?.firmWebsite?.message}
                />
                <FormInputText
                  id="firmFcaNumber"
                  label="Firm FCA number"
                  register={register}
                  disabled
                  error={errors?.firmFcaNumber?.message}
                />
                <FormInputNumber
                  id="brokerageFee"
                  label="Brokerage fee (%)"
                  register={register}
                  disabled
                  error={errors?.brokerageFee?.message}
                />
                {/* {mutationData?.response?.ok && (
                  <div className="pt-2">
                    <AlertSuccess title="Success!">
                      <p>Your account details have been saved.</p>
                    </AlertSuccess>
                  </div>
                )} */}
                {/* {(hasErrors || mutationError) && (
                  <div className="pt-2">
                    <AlertError title="Error updating account details">
                      <p>
                        Please correct the form errors and try again or contact
                        our Support team on {AHAUZ_PHONE_NUMBER}.
                      </p>
                    </AlertError>
                  </div>
                )} */}
                {/* <div className="flex justify-end pt-2">
                  {mutationLoading ? (
                    <Loading />
                  ) : (
                    <CTAButton type="submit" label="Update" />
                  )}
                </div> */}
              </div>
            </form>
          </div>
        </PageContainer>
      </div>
    </PageContent>
  );
}
