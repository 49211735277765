export const LoanMortgagePurposes = [
  {
    value: "FTB",
    title: "Purchase - first time buyer",
  },
  {
    value: "NFTB",
    title: "Purchase - Non first time buyer",
  },
  {
    value: "O",
    title: "Other",
  },
];

export const LoanRepaymentPlanTypes = [
  {
    value: "SMP",
    title: "Sale of main residence / mortgaged property",
  },
  {
    value: "SOP",
    title: "Sale of other property",
  },
  {
    value: "UT",
    title: "Unit trust",
  },
  {
    value: "IB",
    title: "Investment bonds",
  },
  {
    value: "SS",
    title: "Stocks and shares",
  },
  {
    value: "SSI",
    title: "Stocks and shares ISA",
  },
  {
    value: "P",
    title: "Pensions",
  },
  {
    value: "EP",
    title: "Endowment policies",
  },
  {
    value: "C",
    title: "Any combination of the above",
  },
  {
    value: "O",
    title: "Other",
  },
];

export const LoanDepositSources = [
  {
    value: "S",
    title: "Savings",
  },
  {
    value: "PS",
    title: "Previous property sale",
  },
  {
    value: "G",
    title: "Gift",
  },
  {
    value: "HIS",
    title: "HTB ISA savings",
  },
  {
    value: "I",
    title: "Inheritance",
  },
  {
    value: "O",
    title: "Other",
  },
];
