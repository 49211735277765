import {
  checkTruthyValue,
  INVALID_RESPONSE_MSG,
  PHONE_NUMBER_REGEX,
  VALIDATION_PHONE_NUMBER,
} from "utils/validation";
import * as Yup from "yup";

const DECLARATION_REQUIRED_YES_MSG =
  "You cannot proceed with the application if you are unable to confirm Yes to the declaration statements";

const validation = Yup.object({
  name: Yup.string().required(INVALID_RESPONSE_MSG),
  firmName: Yup.string().required(INVALID_RESPONSE_MSG),
  email: Yup.string().required(INVALID_RESPONSE_MSG).email("Email is invalid"),
  phoneNumber: Yup.string()
    .required(INVALID_RESPONSE_MSG)
    .matches(PHONE_NUMBER_REGEX, {
      message: VALIDATION_PHONE_NUMBER,
      excludeEmptyString: true,
    }),
  isFeeCharged: Yup.string()
    .nullable()
    .required("Please tell us if you are charging a fee"),
  feeAmount: Yup.string().when("isFeeCharged", {
    is: (value: any) => checkTruthyValue(value),
    then: () => Yup.string().nullable().required(INVALID_RESPONSE_MSG),
    otherwise: () => Yup.string().nullable(),
  }),
  levelOfAdviceGiven: Yup.string().required(INVALID_RESPONSE_MSG),
  confirmBrokerDeclarations: Yup.bool()
    .typeError(DECLARATION_REQUIRED_YES_MSG)
    .required(DECLARATION_REQUIRED_YES_MSG)
    .test(
      "valid-declaration",
      DECLARATION_REQUIRED_YES_MSG,
      (value: any) => value,
    ),
}).nullable();

export default validation;
