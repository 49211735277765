import {
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import FormTitle from "components/Form/Title";
import { Control, useWatch } from "react-hook-form";
import { APP_URLS } from "settings";
import useADipStore from "store/DecisionInPrinciple";
import { APPLICATION_COMPLETE_KEY } from "utils/consts";
import { checkTruthyValue } from "utils/validation";
import { FormSchema, FormSchemaStatus } from "../formSchema";
import { STEPS, StepType } from "../formSteps";

interface SidebarProps {
  onStepClick: (stepKey: string) => void;
  control: Control<FormSchema, any>;
}

const Sidebar: React.FC<SidebarProps> = ({ onStepClick, control }) => {
  const [isOpen, setIsOpen] = useState(true);

  const { formStatus, formStep, dipCase } = useADipStore();

  const watchConsentToCreditCheckAndInformationProcessing = useWatch({
    name: "start.consentToCreditCheckAndInformationProcessing",
    control: control,
  });
  const consentToCreditCheckAndInformationProcessing = checkTruthyValue(
    watchConsentToCreditCheckAndInformationProcessing,
  );

  const watchReceivedSuccessfulDipFromFirstChargeLender = useWatch({
    name: "start.receivedSuccessfulDipFromFirstChargeLender",
    control: control,
  });
  const receivedSuccessfulDipFromFirstChargeLender = checkTruthyValue(
    watchReceivedSuccessfulDipFromFirstChargeLender,
  );

  const renderDipInfo = () => {
    return (
      <>
        {dipCase?.reference && (
          <div className="mb-6 border-b pb-4 md:-mt-4">
            <span className="block">
              <span className="font-semibold">Case:</span>
              <br />
              <Link
                to={`${APP_URLS.DASHBOARD}cases/${dipCase.uuid}`}
                className="underline"
                target="_blank"
              >
                {dipCase.reference}
              </Link>
            </span>
          </div>
        )}
      </>
    );
  };

  const renderNavItemWithProgress = (step: StepType) => {
    const listStepStatus = formStatus[step.key as keyof FormSchemaStatus];
    const stepComplete = listStepStatus === APPLICATION_COMPLETE_KEY;

    return (
      <li key={step.key}>
        <button
          className={`flex w-full justify-between text-left ${
            formStep.key === step.key
              ? "font-semibold underline"
              : "hover:underline"
          }`}
          onClick={() =>
            (consentToCreditCheckAndInformationProcessing &&
              receivedSuccessfulDipFromFirstChargeLender) ||
            step.key === "start"
              ? onStepClick(step.key)
              : null
          }
        >
          <span>{step.name}</span>
          <span>
            {stepComplete && (
              <CheckCircleIcon className="text-secondary h-6 w-6 self-center text-green-400" />
            )}
          </span>
        </button>
      </li>
    );
  };

  return (
    <div
      className={`flex flex-col space-y-4 rounded-lg border border-gray-200 bg-white p-6 md:p-8`}
    >
      <div className={`flex justify-between ${isOpen && "border-b pb-4"}`}>
        <FormTitle left title="New DIP" mb={false} />

        <button className="block md:hidden" onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? (
            <ArrowUpCircleIcon className="h-6 w-6" />
          ) : (
            <ArrowDownCircleIcon className="h-6 w-6" />
          )}
        </button>
      </div>

      {isOpen && (
        <>
          {renderDipInfo()}
          <ul className="flex flex-col space-y-4">
            {Object.entries(STEPS).map(([key, step]) =>
              renderNavItemWithProgress(step),
            )}
          </ul>
        </>
      )}
    </div>
  );
};

export default Sidebar;
