import PageContainer from "components/Page/Container";
import PageDialogScreen from "components/Page/DialogScreen";
import { APP_URLS } from "settings";

export default function Page404() {
  return (
    <div className="my-14">
      <PageDialogScreen title="404 - Page not found">
        <PageContainer>
          <>
            <p className="mb-2 text-lg font-medium">
              Sorry, but it looks like the page you are looking does not exist.
            </p>
            <p>
              If you're trying to Log in or access your Dashboard, please{" "}
              <a href={APP_URLS.DASHBOARD}>click here</a>.
            </p>
          </>
        </PageContainer>
      </PageDialogScreen>
    </div>
  );
}
