import { parseApplicantDetail } from "components/Application/Form/ApplicantDetails/api";
import { parseApplicantEmployment } from "components/Application/Form/ApplicantEmployment/api";
import { parseApplicantExpenditure } from "components/Application/Form/ApplicantExpenditure/api";
import { parseBool, setDataForApi } from "utils/api";
import { FormSchema } from "./formSchema";

export const getApiSubmitData = (formData: FormSchema) => {
  const applicantsData = formData?.applicants?.map((applicant) => {
    parseApplicantDetail(applicant?.details);
    parseApplicantEmployment(applicant?.employments);
    parseApplicantExpenditure(applicant?.expenditures);
    return applicant;
  });

  return {
    numberOfApplicants: formData.start?.numberOfApplicants,
    consentToCreditCheckAndInformationProcessing: parseBool(
      formData.start?.consentToCreditCheckAndInformationProcessing,
    ),
    receivedSuccessfulDipFromFirstChargeLender: parseBool(
      formData.start?.receivedSuccessfulDipFromFirstChargeLender,
    ),
    firstChargeLenderDipReference:
      formData.start?.firstChargeLenderDipReference,
    applicants: applicantsData,
    loan: setDataForApi(formData?.loan),
  };
};
