import React, { useState } from "react";

import {
  STYLE_INPUT,
  STYLE_INPUT_MARGIN_TOP,
} from "components/Form/Input/consts";
import FormInputError from "components/Form/Input/Error";
import FormInputLabel from "components/Form/Input/Label";
import { FormInputProps } from "components/Form/Input/types";

export const INVALID_PASSWORD_FORMAT =
  "Password must be 8 or more characters, including at least 1 number and 1 upper case letter";

export const isValidPassord = (password: string | null): boolean => {
  if (!password) {
    return false;
  }
  // One uppercase, one lowercase, one number
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
  return regex.test(password);
};

export const isPasswordMatch = (
  password1: string | null,
  password2: string | null,
): boolean => password1 === password2;

export default function FormInputPassword({
  id = "password",
  label = "Password",
  validate = true,
  onChange,
  error,
  register,
}: FormInputProps) {
  const [passwordError, setPasswordError] = useState<string | null>(null);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordError(null);
    if (validate && !isValidPassord(event.target.value)) {
      setPasswordError(INVALID_PASSWORD_FORMAT);
    }
    onChange && onChange(event);
  };

  return (
    <div>
      <FormInputLabel id={id} label={label} />
      <div className={STYLE_INPUT_MARGIN_TOP}>
        <input
          id={id}
          type="password"
          autoComplete="off"
          required
          className={STYLE_INPUT}
          {...register(id, {
            required: true,
            onChange: handleOnChange,
          })}
        />
        <FormInputError error={passwordError || error} />
      </div>
    </div>
  );
}
