import ActionList from "./ActionList";

// Define the props interface
interface ActionsProps {
  actions: Array<any>; // You can replace `any` with a more specific type based on the structure of the `actions` array
}

const Actions: React.FC<ActionsProps> = ({ actions }) => {
  return (
    <>
      <div className="flex items-end justify-between">
        <h3 className="text-lg font-semibold">Actions</h3>
      </div>
      <ActionList actions={actions} />
    </>
  );
};

Actions.displayName = "Actions";

export default Actions;
