import { renderDate } from "utils/helpers";

// Define the interface for action items
interface ActionItem {
  comment: string;
  createdOn: string; // Replace with `Date` if `createdOn` is a Date object
}

// Define the props interface
interface ActionListProps {
  actions: ActionItem[]; // Array of action items
}

const ActionList: React.FC<ActionListProps> = ({ actions }) => {
  return (
    <div className="mt-4 flex flex-col">
      <div className="overflow-hidden rounded-lg shadow ring-1 ring-black ring-opacity-5">
        <table className="min-w-full table-auto divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Action
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Created
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Action</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {actions.map((actn, i) => (
              <tr key={i}>
                <td className="whitespace-normal px-3 py-4 text-sm text-gray-900">
                  {actn.comment}
                </td>
                <td className="whitespace-normal px-3 py-4 text-sm text-gray-500">
                  {renderDate(actn.createdOn)}
                </td>
                <td className="relative whitespace-normal py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  &nbsp;
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

ActionList.displayName = "ActionList";

export default ActionList;
