import { gql } from "@apollo/client";

import {
  FRAGMENT_BASE_RESPONSE_FIELDS,
  FRAGMENT_BROKER_TYPE,
} from "api/fragments";

export const MUTATION_LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    accountLogin(username: $username, password: $password) {
      email
    }
  }
`;

export const MUTATION_LOGOUT = gql`
  mutation Logout {
    accountLogout
  }
`;

export const MUTATION_FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    accountForgotPassword(email: $email) {
      ...BaseResponseFields
    }
  }
  ${FRAGMENT_BASE_RESPONSE_FIELDS}
`;

export const MUTATION_SET_USER_PASSWORD = gql`
  mutation ResetPassword($userPassword: SetUserPasswordInput!) {
    response: accountResetPassword(userPassword: $userPassword) {
      ...BaseResponseFields
    }
  }
  ${FRAGMENT_BASE_RESPONSE_FIELDS}
`;

export const MUTATION_CHANGE_PASSWORD = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    response: accountChangePassword(input: $input) {
      ...BaseResponseFields
    }
  }
  ${FRAGMENT_BASE_RESPONSE_FIELDS}
`;

export const MUTATION_UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($input: BrokerInput!) {
    response: accountBrokerUpdate(input: $input) {
      ...BaseResponseFields
      broker {
        ...BrokerFields
      }
    }
  }
  ${FRAGMENT_BASE_RESPONSE_FIELDS}
  ${FRAGMENT_BROKER_TYPE}
`;

export const MUTATION_CREATE_ACCOUNT = gql`
  mutation BrokerCreate($account: CreateAccountFromTokenInput!) {
    response: accountBrokerCreate(account: $account) {
      ...BaseResponseFields
    }
  }
  ${FRAGMENT_BASE_RESPONSE_FIELDS}
`;
