const FormTitle = ({
  title,
  left = false,
  right = false,
  border = false,
  mb = true,
}: {
  title: string;
  left?: boolean;
  right?: boolean;
  border?: boolean;
  mb?: boolean;
}) => {
  const _align = left ? "left" : right ? "right" : "center border-b pb-4";
  const _border = border ? "border-b pb-4" : "";
  const _mb = mb ? "mb-4" : "";

  return (
    <h3
      className={`text-${_align} ${_mb} mt-0 font-display text-lg font-semibold leading-6 ${_border}`}
    >
      {title}
    </h3>
  );
};

export default FormTitle;
