import { useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";

import { useQuery } from "@apollo/client";

import { QUERY_AUTHENTICATED_USER } from "api/queries/account";
import Footer from "components/Footer";
import Header from "components/Header";
import Hijack from "components/Hijack";
import Loading from "components/Loading";
import PageDialogScreen from "components/Page/DialogScreen";
import Page404 from "pages/Error/404";
import AUTHENTICATED_ROUTES from "routes/authenticated";
import RouteRequireAuth from "routes/components/RouteRequireAuth";
import RouteUnauthenticated from "routes/components/RouteUnauthenticated";
import UNAUTHENTICATED_ROUTES from "routes/unauthenticated";
import { APP_URLS } from "settings";
import { useUserStore } from "store/User";

export default function App() {
  const setUser = useUserStore((state) => state.setUser);

  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(QUERY_AUTHENTICATED_USER, {
    fetchPolicy: "no-cache",
  });

  /**
   * Check API response and set user state based on authenticated result
   */
  useEffect(() => {
    queryData?.broker && setUser(queryData.broker);
  }, [queryData, setUser]);

  useEffect(() => {
    if (queryData && !queryData.broker && queryError) {
      window.location.href = APP_URLS.LOGIN;
    }
  }, [queryData, queryError]);

  /**
   * Restore scroll window position on route change
   * @param props
   * @returns Route
   */
  const ScrollToTop = (props: any) => {
    const location = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);

    return <>{props.children}</>;
  };

  if (queryLoading) {
    return (
      <PageDialogScreen logo={false}>
        <Loading large />
      </PageDialogScreen>
    );
  }

  const isAuthenticated = queryData?.broker;

  return (
    <>
      <Header />
      <Router>
        <ScrollToTop>
          <Routes>
            <Route
              element={<RouteRequireAuth authenticated={isAuthenticated} />}
            >
              {AUTHENTICATED_ROUTES.map(
                (route, i) =>
                  !(route?.disabled ?? false) && (
                    <Route
                      key={i}
                      path={route.path}
                      element={<route.component />}
                    />
                  ),
              )}
            </Route>
            <Route
              element={<RouteUnauthenticated authenticated={isAuthenticated} />}
            >
              {UNAUTHENTICATED_ROUTES.map(
                (route, i) =>
                  !(route?.disabled ?? false) && (
                    <Route
                      key={i}
                      path={route.path}
                      element={<route.component />}
                    />
                  ),
              )}
            </Route>
            <Route path="*" element={<Page404 />} />
          </Routes>
        </ScrollToTop>
      </Router>
      <Footer />
      <Hijack />
    </>
  );
}
