import { useMutation } from "@apollo/client";
import {
  UPDATE_APPLICANT_DECLARATIONS,
  UPDATE_APPLICANT_DETAILS,
  UPDATE_APPLICANT_EMPLOYMENT,
  UPDATE_APPLICANT_EXPENDITURE,
  UPDATE_BROKER_DETAILS,
  UPDATE_LOAN_DETAILS,
  UPDATE_OTHER_DETAILS,
  UPDATE_PROPERTY_DETAILS,
  UPDATE_REVIEW_CONFIRM,
  UPDATE_SOLICITOR_DETAILS,
} from "api/mutations/application";

const useApiStepBroker = () => useMutation(UPDATE_BROKER_DETAILS);
const useApiStepApplicants = () => useMutation(UPDATE_APPLICANT_DETAILS);
const useApiStepApplicantDeclarations = () =>
  useMutation(UPDATE_APPLICANT_DECLARATIONS);
const useApiStepApplicantEmployment = () =>
  useMutation(UPDATE_APPLICANT_EMPLOYMENT);
const useApiStepApplicantExpenditure = () =>
  useMutation(UPDATE_APPLICANT_EXPENDITURE);
const useApiStepLoan = () => useMutation(UPDATE_LOAN_DETAILS);
const useApiStepProperty = () => useMutation(UPDATE_PROPERTY_DETAILS);
const useApiStepOther = () => useMutation(UPDATE_OTHER_DETAILS);
const useApiStepSolicitor = () => useMutation(UPDATE_SOLICITOR_DETAILS);
const useApiStepConfirm = () => useMutation(UPDATE_REVIEW_CONFIRM);

export const mutationHooks: { [k: string]: Function } = {
  broker: useApiStepBroker,
  applicants: useApiStepApplicants,
  declarations: useApiStepApplicantDeclarations,
  employments: useApiStepApplicantEmployment,
  expenditures: useApiStepApplicantExpenditure,
  loan: useApiStepLoan,
  property: useApiStepProperty,
  other: useApiStepOther,
  solicitor: useApiStepSolicitor,
  confirm: useApiStepConfirm,
};
