type Props = {
  title?: string;
  children?: JSX.Element;
};

export default function PageContent({ title = "", children }: Props) {
  return (
    <div className="min-h-full">
      <div className="py-10">
        {title && (
          <header>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-center text-3xl font-medium leading-tight tracking-tight text-brand-copy-primary">
                {title}
              </h1>
            </div>
          </header>
        )}
        <main>
          <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="px-4 py-8 sm:px-0">{children}</div>
          </div>
        </main>
      </div>
    </div>
  );
}
