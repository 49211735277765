import {
  STYLE_INPUT,
  STYLE_INPUT_MARGIN_TOP,
} from "components/Form/Input/consts";
import FormInputError from "components/Form/Input/Error";
import FormInputLabel from "components/Form/Input/Label";
import { FormInputProps } from "components/Form/Input/types";

export default function FormInputDate({
  id,
  label,
  error,
  disabled = false,
  requiredField = true,
  register,
}: FormInputProps) {
  return (
    <div>
      <FormInputLabel id={id} label={label} />
      <div className={STYLE_INPUT_MARGIN_TOP}>
        <input
          id={id}
          type="date"
          required
          className={STYLE_INPUT}
          disabled={disabled}
          max={"9999-12-31"}
          {...register(id, {
            required: requiredField,
          })}
        />
        <FormInputError error={error} />
      </div>
    </div>
  );
}
