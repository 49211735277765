const DEFAULT_FORM_DATA = {
  property: {
    address: "",
    buildingName: "",
    buildingNumber: "",
    subBuildingName: "",
    subBuildingNumber: "",
    thoroughfare: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    townOrCity: "",
    county: "",
    postcode: "",
    propertyType: "",
    tenure: "",
    yearsLeftOnLease: undefined,
    yearPropertyBuilt: undefined,
    isNewBuildPurchase: undefined,
    nameOfBuilder: "",
    hasNhbcFnd15Cover: undefined,
    willBePartiallyRented: undefined,
    willBePrimaryResidence: undefined,
    propertyNearBusinessPremises: undefined,
    canUseWithoutStructuralChanges: undefined,
    isCouncilFlat: undefined,
    isStudioFlat: undefined,
    groundRentIfFlat: "",
    serviceChargeIfFlat: "",
    noOfFloorsIfFlat: undefined,
    flatFloor: undefined,
    noOfBedrooms: undefined,
    councilTaxMonthly: "",
    councilTaxBand: "",
    localAuthority: "",
    hasOutbuildingOrAnnex: undefined,
    useOfOutbuildingOrAnnexDetails: "",
  },
};

export default DEFAULT_FORM_DATA;
