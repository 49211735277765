import {
  STYLE_INPUT,
  STYLE_INPUT_MARGIN_TOP,
} from "components/Form/Input/consts";
import FormInputError from "components/Form/Input/Error";
import FormInputLabel from "components/Form/Input/Label";
import { FormInputProps } from "components/Form/Input/types";

export default function FormInputTextArea({
  id,
  label,
  error,
  register,
  disabled = false,
}: FormInputProps) {
  return (
    <div>
      <FormInputLabel id={id} label={label} />
      <div className={STYLE_INPUT_MARGIN_TOP}>
        <textarea
          className={STYLE_INPUT}
          rows={4}
          id={id}
          required
          disabled={disabled}
          {...register(id, {
            required: true,
          })}
        ></textarea>
        <FormInputError error={error} />
      </div>
    </div>
  );
}
