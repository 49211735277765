import {
  checkTruthyValue,
  futureDateValidation,
  INVALID_RESPONSE_MSG,
} from "utils/validation";
import * as Yup from "yup";

export const baseExpenditureValidation = Yup.object().shape({
  applicantHasOutstandingCredit: Yup.bool()
    .required(INVALID_RESPONSE_MSG)
    .typeError(INVALID_RESPONSE_MSG),
  creditProviders: Yup.bool().when("applicantHasOutstandingCredit", {
    is: (value: any) => checkTruthyValue(value),
    then: () =>
      Yup.array()
        .of(
          Yup.object({
            providerType: Yup.string().required(INVALID_RESPONSE_MSG),
            creditEndDate: futureDateValidation,
          }),
        )
        .min(
          1,
          "Please provide the details for the Applicants outstanding credit",
        ),
    otherwise: () => Yup.array().nullable(),
  }),
});
