import React from "react";
import {
  Control,
  useFieldArray,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";

import { DEFAULT_EMPLOYMENT_OTHER_SOURCES_DATA } from "components/Application/Form/ApplicantEmployment/defaultData";
import CTAButton from "components/CTA/Button";
import FormInputCurrency from "components/Form/Input/Currency";
import FormInputSelect from "components/Form/Input/Select";
import { ComonObjectType } from "ts/types";
import { LABELS } from "./labels";
import { ApplicantEmploymentOtherIncomeSources } from "./options";

interface StepProps {
  control: Control<any, any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  idKey: string;
  errorKey: ComonObjectType | undefined;
}

const STEP_KEY = "employmentOtherIncomeSources";

const OtherIncomeSources: React.FC<StepProps> = ({
  control,
  register,
  setValue,
  idKey,
  errorKey,
}) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `${idKey}.${STEP_KEY}`,
  });

  const applicantsOtherIncome = useWatch({
    name: `${idKey}.${STEP_KEY}`,
    control: control,
  });

  return (
    <div className="flex flex-col space-y-4">
      <label className="block text-base">If yes, please specify below:</label>

      {fields.map((item, k) => {
        return (
          <div key={item.id} className="flex flex-col space-y-4">
            <label className="block text-base font-semibold">
              Income source {k + 1}
            </label>

            <input
              {...register(`${idKey}.${STEP_KEY}.${k}.uuid`, {
                value: applicantsOtherIncome?.[k]?.uuid,
              })}
              type="hidden"
            />

            <FormInputSelect
              id={`${idKey}.${STEP_KEY}.${k}.source`}
              label={LABELS.employmentOtherIncomeSources.source}
              register={register}
              initSelect
              options={ApplicantEmploymentOtherIncomeSources}
              requiredField={false}
              error={errorKey?.[STEP_KEY]?.[k]?.source?.message}
            />

            <FormInputCurrency
              id={`${idKey}.${STEP_KEY}.${k}.annualGrossIncome`}
              label={LABELS.employmentOtherIncomeSources.annualGrossIncome}
              register={register}
              requiredField={false}
              error={errorKey?.[STEP_KEY]?.[k]?.annualGrossIncome?.message}
              setValue={setValue}
            />

            <div className="mb-8 sm:col-span-6">
              <div className="flex justify-start space-x-4">
                <CTAButton
                  secondary
                  label="Delete source"
                  onClick={() => remove(k)}
                />
              </div>
            </div>
          </div>
        );
      })}

      <div className="mb-8 sm:col-span-6">
        <div className="flex justify-start space-x-4">
          <CTAButton
            secondary
            label="Add source"
            onClick={() => append(DEFAULT_EMPLOYMENT_OTHER_SOURCES_DATA)}
          />
        </div>
      </div>
    </div>
  );
};

export default OtherIncomeSources;
