/**
 * Fetch a Cookie value by name
 * @param name
 */
export const getCookie = (name: string) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

/**
 * Merge a set of class names
 * @param classes
 * @returns string
 */
export const classNames = (...classes: any) => {
  return classes.filter(Boolean).join(" ");
};

export const renderDate = (date?: string) => {
  if (date) {
    return new Date(date).toLocaleDateString();
  }
  return "-";
};

export const getQueryParams = (query: string | null = null) => {
  type ReduceQueryType = {
    [key: string]: string;
  };
  return (
    (query || window.location.search.replace("?", ""))
      .split("&")
      .map((e) => e.split("=").map(decodeURIComponent))
      // eslint-disable-next-line no-sequences
      .reduce((r: ReduceQueryType, [k, v]) => ((r[k] = v), r), {})
  );
};

/**
 * Creates a debounced function that delays invoking the provided
 * function until at least ms milliseconds have elapsed since
 * the last time it was invoked.
 * @param fn Function
 * @param ms timeout
 * @returns
 */
export const debounce = (fn: Function, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};
