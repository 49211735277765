import { Link } from "react-router-dom";
import { APP_URLS } from "settings";
import { Case } from "ts/interfaces/common/case";
import { renderDate } from "utils/helpers";

interface CaseListProps {
  cases?: Case[];
  recentCase?: string | null;
}

const CaseList: React.FC<CaseListProps> = ({ cases, recentCase }) => {
  const renderCases = () =>
    cases &&
    cases.map((c) => (
      <tr
        key={c.uuid}
        className={
          recentCase === c.uuid ? "bg-brand-green-medium bg-opacity-25" : ""
        }
      >
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
          {c.reference}
        </td>
        <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 md:block">
          {c.statusDisplay}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {renderDate(c.createdOn)}
        </td>
        <td className="hidden max-w-[100px] truncate px-3 py-4 text-sm text-gray-500 md:block">
          {c.applicantNames}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <Link
            to={`${APP_URLS.DASHBOARD}cases/${c.uuid}/`}
            className="text-primary font-medium underline"
          >
            View
          </Link>
        </td>
      </tr>
    ));

  const renderNoCases = () => (
    <tr>
      <td
        colSpan={4}
        className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
      >
        No cases created yet
      </td>
    </tr>
  );

  return (
    <div className="overflow-hidden rounded-lg shadow ring-1 ring-black ring-opacity-5">
      <table className="w-full table-auto divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Reference
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:block"
            >
              Status
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Created
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:block"
            >
              Applicant Name(s)
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            ></th>
          </tr>
        </thead>

        <tbody className="divide-y divide-gray-200 bg-white">
          {cases ? renderCases() : renderNoCases()}
        </tbody>
      </table>
    </div>
  );
};

export default CaseList;
