import { parseApplicantExpenditure } from "components/Application/Form/ApplicantExpenditure/api";
import { FormSchemaApplicantDetails } from "../ApplicantDetails/types";
import { FormSchemaApplicantExpenditure } from "./types";

const parseDataForApi = (
  data: Array<FormSchemaApplicantExpenditure>,
  _numberOfApplicants: number,
  applicantsFieldArray: Array<FormSchemaApplicantDetails>,
) => {
  if (!data) {
    return data;
  }

  const applicantsDetail = Object.values(data).map((applicant, i) => {
    parseApplicantExpenditure(applicant);
    if (!applicant.applicantId) {
      applicant.applicantId = applicantsFieldArray[i].uuid;
    }
    return applicant;
  });

  return applicantsDetail;
};

export default parseDataForApi;
