export const LABELS = {
  addressLine1: "Address Line 1",
  addressLine2: "Address Line 2",
  addressLine3: "Address Line 3",
  townOrCity: "Town or city",
  county: "County",
  postcode: "Postcode",
  firmName: "Name of the solicitor/conveyancing firm",
  actingSolicitorName:
    "Name of the solicitor/conveyancer acting for the customer",
  emailAddress: "Email Address",
  phoneNumber: "Phone number",
  reference: "Reference #",
};
