import React, { useState } from "react";
import { useWatch } from "react-hook-form";

import ApplicantDetailsFormFields from "components/Application/Form/ApplicantDetails";
import FormInputSelect from "components/Form/Input/Select";
import FormTitle from "components/Form/Title";
import Loading from "components/Loading";
import FormErrors from "pages/ApplicationForm/components/FormErrors";
import { getApplicantName } from "pages/ApplicationForm/utils";
import { StepProps } from "../../types";
import { LABELS } from "./labels";

const STEP_KEY = "applicants";

const ApplicantDetailsForm: React.FC<StepProps> = ({
  clearErrors,
  control,
  register,
  errors,
  setValue,
  applicantsFieldArray,
}) => {
  const [loadingApplicantNumberChange, setLoadingApplicantNumberChange] =
    useState(false);

  const applicants = useWatch({ name: STEP_KEY, control: control });

  return (
    <div className="flex flex-col space-y-4">
      <FormTitle left border title="Applicant Details" mb={false} />

      <FormErrors formErrors={errors?.[STEP_KEY]} />

      {loadingApplicantNumberChange ? (
        <div className="p-6 text-center">
          <Loading />
          <p className="mt-4">Updating number of Applicants...</p>
        </div>
      ) : (
        <>
          <FormInputSelect
            id={`numberOfApplicants`}
            label={LABELS.numberOfApplicants}
            register={register}
            options={[
              {
                value: 1,
                title: "1",
              },
              {
                value: 2,
                title: "2",
              },
            ]}
            error={errors?.numberOfApplicants?.message}
            onChange={() => {
              setLoadingApplicantNumberChange(true);
            }}
          />

          {applicantsFieldArray.map((applicant, k) => {
            const applicantNumber = k + 1;
            const idKey = `${STEP_KEY}.${k}`;
            const errorKey = errors?.[STEP_KEY]?.[k];

            return (
              <div key={applicant.id} className="pt-4">
                <FormTitle
                  left
                  title={`Applicant: ${
                    getApplicantName(applicant) || applicantNumber
                  }`}
                />

                <input
                  {...register(`${STEP_KEY}.${k}.uuid`, {
                    value: applicants?.[k]?.uuid,
                  })}
                  type="hidden"
                />

                <ApplicantDetailsFormFields
                  register={register}
                  control={control}
                  idKey={idKey}
                  errorKey={errorKey}
                  clearErrors={clearErrors}
                  setValue={setValue}
                  isApplication={true}
                />
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default ApplicantDetailsForm;
