import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

import { MUTATION_FORGOT_PASSWORD } from "api/mutations/account";
import AlertSuccess from "components/Alert/Success";
import CTAButton from "components/CTA/Button";
import FormInputEmail from "components/Form/Input/Email";
import Loading from "components/Loading";
import PageContainer from "components/Page/Container";
import PageDialogScreen from "components/Page/DialogScreen";
import { AHAUZ_PHONE_NUMBER, APP_URLS } from "settings";
import { VALIDATION_EMAIL, VALIDATION_REQUIRED } from "utils/validation";

interface FormInputs {
  email: string;
}

const schema = yup.object({
  email: yup.string().email(VALIDATION_EMAIL).required(VALIDATION_REQUIRED),
});

export default function AccountForgotPassword() {
  const [submitted, setSubmitted] = useState<boolean>(false);

  const [forgotPassword, { loading: mutationLoading, reset: resetMutation }] =
    useMutation(MUTATION_FORGOT_PASSWORD);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  const email = watch("email");

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    resetMutation();

    forgotPassword({
      variables: {
        email: data.email,
      },
    });

    setSubmitted(true);
  };

  const resetComplete = () => (
    <AlertSuccess title="Submitted!" shadow>
      <p>
        We'll now send you an email to reset your password. If you're still
        having issues, please contact our Support team on {AHAUZ_PHONE_NUMBER}.
      </p>
    </AlertSuccess>
  );

  const renderForm = () => (
    <PageContainer>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="space-y-4">
          <FormInputEmail
            id="email"
            label="Email"
            register={register}
            error={errors?.email?.message}
          />
          <div className="pt-2">
            {mutationLoading ? (
              <Loading />
            ) : (
              <CTAButton type="submit" enabled={!!email} label="Submit" full />
            )}
          </div>
        </div>
      </form>
    </PageContainer>
  );

  const renderResetPassword = () => {
    if (submitted) {
      return resetComplete();
    }
    return renderForm();
  };

  return (
    <PageDialogScreen
      title="Forgot your password"
      copy={
        <>
          Please submit your registered email address,
          <br />
          we'll send you an email to reset your password
        </>
      }
    >
      <>
        {renderResetPassword()}
        <div className="mt-8 text-center text-base text-brand-copy-light">
          Looking for the login form?{" "}
          <a href={APP_URLS.LOGIN}>Click here to sign in</a>
        </div>
      </>
    </PageDialogScreen>
  );
}
