import { baseExpenditureValidation } from "components/Application/Form/ApplicantExpenditure/validation";
import * as Yup from "yup";

const validation: Yup.ObjectSchema<any> = Yup.object().shape({
  applicants: Yup.array().of(
    Yup.object({
      expenditures: baseExpenditureValidation,
    }),
  ),
});

export default validation;
