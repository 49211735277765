import { gql } from "@apollo/client";

export const ADDRESS_LOOKUP_QUERY = gql`
  query AddressLookup($search: String!) {
    addressLookup(search: $search) {
      address
      id
    }
  }
`;

export const ADDRESS_DETAIL_QUERY = gql`
  query addressDetail($id: String!) {
    addressDetail(id: $id) {
      address
      line1
      line2
      line3
      townOrCity
      county
      postcode
    }
  }
`;
