// query {
//   __type(name:"BrokerDetailAdviceLevels"){
//     name
//     enumValues{
//       value: name
//       title: description
//     }
//   }
// }

export const BrokerDetailAdviceLevels = [
  {
    value: "AG",
    title: "Advice given",
  },
  {
    value: "ER",
    title: "Execution only - rejected advice",
  },
  {
    value: "ENW",
    title: "Execution only - HNW customer",
  },
  {
    value: "EMP",
    title: "Execution only - mortgage professional",
  },
  {
    value: "ENIS",
    title: "Execution only - non-interactive sale",
  },
];
