import FormInputError from "components/Form/Input/Error";
import { FormInputProps } from "components/Form/Input/types";
import { STYLE_INPUT } from "./consts";

type SelectOptionsType = {
  title: string;
  value: string | number;
};

type FormInputSelectProps = FormInputProps & {
  options?: Array<SelectOptionsType>;
  initSelect?: boolean;
  initSelectLabel?: string;
};

export default function FormInputSelect({
  id,
  label,
  error,
  onChange,
  options = [],
  initSelect = false,
  initSelectLabel = "-- Select --",
  requiredField = true,
  register,
}: FormInputSelectProps) {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event.target.value);
  };

  if (!options.length) {
    return <></>;
  }
  return (
    <div>
      <div className="flex justify-start">
        <label>{label}</label>
      </div>
      <select
        id={id}
        {...register(id, {
          required: requiredField,
          onChange: handleOnChange,
        })}
        className={`${STYLE_INPUT} mt-1`}
      >
        {initSelect && (
          <option key="init" value="">
            {initSelectLabel}
          </option>
        )}
        {options.map((option, i) => (
          <option key={i} value={option.value === "" ? "" : option.value}>
            {option.value === "" || option.value === ""
              ? initSelectLabel
              : option.title}
          </option>
        ))}
      </select>
      {error && <FormInputError error={error} />}
    </div>
  );
}
