import {
  STYLE_INPUT,
  STYLE_INPUT_MARGIN_TOP,
} from "components/Form/Input/consts";
import FormInputError from "components/Form/Input/Error";
import FormInputLabel from "components/Form/Input/Label";
import { FormInputProps } from "components/Form/Input/types";
import { ChangeEvent, WheelEvent } from "react";

type FormInputNumberProps = FormInputProps & {
  min?: number | null;
  max?: number | null;
  maxDigits?: number | null;
};

export default function FormInputNumber({
  id,
  label,
  error,
  disabled = false,
  requiredField = true,
  min = null,
  max = null,
  maxDigits = 9,
  register,
}: FormInputNumberProps) {
  return (
    <div>
      <FormInputLabel id={id} label={label} />
      <div className={STYLE_INPUT_MARGIN_TOP}>
        <input
          id={id}
          type="number"
          required
          className={STYLE_INPUT}
          disabled={disabled}
          onWheel={(e: WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
          {...register(id, {
            required: requiredField,
            valueAsNumber: true,
          })}
          {...(min ? { min: min } : {})}
          {...(max ? { max: max } : {})}
          {...(maxDigits
            ? {
                onInput: (e: ChangeEvent<HTMLInputElement>) =>
                  (e.target.value = e.target.value.slice(0, maxDigits)),
              }
            : {})}
        />
        <FormInputError error={error} />
      </div>
    </div>
  );
}
