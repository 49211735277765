import FormInputError from "components/Form/Input/Error";
import FormInputLabel from "components/Form/Input/Label";
import { FormInputProps } from "components/Form/Input/types";
import { UseFormSetValue } from "react-hook-form";
import { asMoney, inputToFloat } from "utils/calcs";

type FormInputCurrencyProps = FormInputProps & {
  currency?: string;
  countryCode?: string;
  allowZero?: boolean;
  setValue?: UseFormSetValue<any>;
  allowNegative?: boolean;
};

export default function FormInputCurrency({
  id,
  label,
  error,
  register,
  defaultVal = "",
  disabled = false,
  helperText = "",
  allowZero = false,
  allowNegative = false,
  setValue,
}: FormInputCurrencyProps) {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;

    value = value.replaceAll(",", "");

    let negativeValue = false;
    if (allowNegative && value.includes("-")) {
      negativeValue = true;
      value = value.replaceAll("-", "");
    }

    if (value.length > 8) {
      // Enforcing max vals to sync with BE
      value = value.slice(0, 8);
    }

    let moneyVal = asMoney(inputToFloat(value), allowZero);

    if (negativeValue) {
      moneyVal = `-${moneyVal}`;
    }

    setValue && setValue(id, moneyVal);
  };

  const inputProps =
    id && register
      ? {
          ...register(id, {
            required: true,
            onChange: handleOnChange,
            setValueAs: (value) => inputToFloat(value),
          }),
        }
      : {};

  return (
    <div>
      <FormInputLabel id={id} label={label} />
      <div className="sm:col-span-2">
        {helperText && (
          <label className="mb-1 block text-base text-brand-copy-lighter">
            {helperText}
          </label>
        )}
        <div className="mt-1 flex rounded-md">
          <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-base text-gray-500">
            £
          </span>
          <input
            type="text"
            id={id}
            name={id}
            required
            className="block w-full appearance-none rounded-none rounded-r-md border border-gray-300 px-3 py-2 text-base placeholder-gray-400 focus:border-brand-orange-medium focus:outline-none focus:ring-brand-orange-medium"
            disabled={disabled}
            inputMode="numeric"
            pattern={allowNegative ? "[-]?[0-9]*" : "[0-9]*"}
            {...inputProps}
          />
        </div>
        <FormInputError error={error} />
      </div>
    </div>
  );
}
