import React from "react";
import {
  Control,
  FieldArrayWithId,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";

import AddressSetter from "components/Form/AddressSetter";
import FormInputCurrency from "components/Form/Input/Currency";
import FormInputDate from "components/Form/Input/Date";
import FormInputPercent from "components/Form/Input/Percent";
import FormInputRadio from "components/Form/Input/Radio";
import FormInputSelect from "components/Form/Input/Select";
import FormInputText from "components/Form/Input/Text";
import { ComonObjectType } from "ts/types";
import { LABELS } from "./labels";
import {
  ApplicantEmploymentDetailStatuses,
  ApplicantEmploymentTypeStatuses,
} from "./options";

interface StepProps {
  control: Control<any, any>;
  setValue: UseFormSetValue<any>;
  clearErrors: UseFormClearErrors<any>;
  register: UseFormRegister<any>;
  idKey: string;
  errorKey: ComonObjectType | undefined;
  jobsFields?: FieldArrayWithId<any, "employmentDetail", "id">[];
}

const STEP_KEY = "employmentDetail";

const MainEmploymentJobs: React.FC<StepProps> = ({
  control,
  setValue,
  clearErrors,
  register,
  idKey,
  errorKey,
  jobsFields,
}) => {
  const applicantsEmployment = useWatch({
    name: `${idKey}.${STEP_KEY}`,
    control: control,
  });

  if (!jobsFields) {
    return <></>;
  }

  return (
    <div className="flex flex-col space-y-4">
      {jobsFields.map((item, k) => {
        return (
          <div key={item.id} className="flex flex-col space-y-4">
            <label className="block text-base font-semibold">Job {k + 1}</label>

            <input
              {...register(`${idKey}.${STEP_KEY}.${k}.uuid`, {
                value: applicantsEmployment?.[k]?.uuid,
              })}
              type="hidden"
            />

            <FormInputSelect
              id={`${idKey}.${STEP_KEY}.${k}.employmentStatus`}
              label={LABELS.employmentDetail.employmentStatus}
              register={register}
              initSelect
              options={ApplicantEmploymentDetailStatuses}
              requiredField={false}
              error={errorKey?.[STEP_KEY]?.[k]?.employmentStatus?.message}
            />

            {["EMPL", "DNOD"].includes(
              applicantsEmployment?.[k]?.employmentStatus || "",
            ) && (
              <>
                <FormInputSelect
                  id={`${idKey}.${STEP_KEY}.${k}.employmentType`}
                  label={LABELS.employmentDetail.employmentType}
                  register={register}
                  initSelect
                  options={ApplicantEmploymentTypeStatuses}
                  requiredField={false}
                  error={errorKey?.[STEP_KEY]?.[k]?.employmentType?.message}
                />

                {["SCFT", "SCOE"].includes(
                  applicantsEmployment?.[k].employmentType || "",
                ) && (
                  <>
                    <FormInputCurrency
                      id={`${idKey}.${STEP_KEY}.${k}.dailyRate`}
                      label={LABELS.employmentDetail.dailyRate}
                      register={register}
                      requiredField={false}
                      error={errorKey?.[STEP_KEY]?.[k]?.dailyRate?.message}
                      setValue={setValue}
                    />

                    <FormInputRadio
                      id={`${idKey}.${STEP_KEY}.${k}.treatedAsEmployedForTax`}
                      label={LABELS.employmentDetail.treatedAsEmployedForTax}
                      register={register}
                      requiredField={false}
                      initValue={
                        applicantsEmployment?.[k]?.treatedAsEmployedForTax
                      }
                      error={
                        errorKey?.[STEP_KEY]?.[k]?.treatedAsEmployedForTax
                          ?.message
                      }
                    />
                  </>
                )}

                <FormInputText
                  id={`${idKey}.${STEP_KEY}.${k}.occupation`}
                  label={LABELS.employmentDetail.occupation}
                  register={register}
                  requiredField={false}
                  error={errorKey?.[STEP_KEY]?.[k]?.occupation?.message}
                />

                <FormInputText
                  id={`${idKey}.${STEP_KEY}.${k}.employerName`}
                  label={LABELS.employmentDetail.employerName}
                  register={register}
                  requiredField={false}
                  error={errorKey?.[STEP_KEY]?.[k]?.employerName?.message}
                />

                <FormInputDate
                  id={`${idKey}.${STEP_KEY}.${k}.startDate`}
                  label={LABELS.employmentDetail.startDate}
                  register={register}
                  requiredField={false}
                  error={errorKey?.[STEP_KEY]?.[k]?.startDate?.message}
                />

                <FormInputDate
                  id={`${idKey}.${STEP_KEY}.${k}.endDate`}
                  label={LABELS.employmentDetail.endDate}
                  register={register}
                  requiredField={false}
                  error={errorKey?.[STEP_KEY]?.[k]?.endDate?.message}
                />

                <FormInputCurrency
                  id={`${idKey}.${STEP_KEY}.${k}.annualBasicSalary`}
                  label={LABELS.employmentDetail.annualBasicSalary}
                  register={register}
                  requiredField={false}
                  error={errorKey?.[STEP_KEY]?.[k]?.annualBasicSalary?.message}
                  setValue={setValue}
                />

                <FormInputCurrency
                  id={`${idKey}.${STEP_KEY}.${k}.annualBonusGross`}
                  label={LABELS.employmentDetail.annualBonusGross}
                  register={register}
                  requiredField={false}
                  error={errorKey?.[STEP_KEY]?.[k]?.annualBonusGross?.message}
                  setValue={setValue}
                />

                <FormInputCurrency
                  id={`${idKey}.${STEP_KEY}.${k}.annualOvertimeGross`}
                  label={LABELS.employmentDetail.annualOvertimeGross}
                  register={register}
                  requiredField={false}
                  error={
                    errorKey?.[STEP_KEY]?.[k]?.annualOvertimeGross?.message
                  }
                  setValue={setValue}
                />

                <FormInputCurrency
                  id={`${idKey}.${STEP_KEY}.${k}.annualCommissionGross`}
                  label={LABELS.employmentDetail.annualCommissionGross}
                  register={register}
                  requiredField={false}
                  error={
                    errorKey?.[STEP_KEY]?.[k]?.annualCommissionGross?.message
                  }
                  setValue={setValue}
                />

                <FormInputCurrency
                  id={`${idKey}.${STEP_KEY}.${k}.annualLocationOrCarAllowanceGross`}
                  label={
                    LABELS.employmentDetail.annualLocationOrCarAllowanceGross
                  }
                  register={register}
                  requiredField={false}
                  error={
                    errorKey?.[STEP_KEY]?.[k]?.annualLocationOrCarAllowanceGross
                      ?.message
                  }
                  setValue={setValue}
                />
              </>
            )}

            {["SEMP", "SEMS"].includes(
              applicantsEmployment?.[k]?.employmentStatus || "",
            ) && (
              <>
                <FormInputText
                  id={`${idKey}.${STEP_KEY}.${k}.occupation`}
                  label={LABELS.employmentDetail.occupation}
                  register={register}
                  requiredField={false}
                  error={errorKey?.[STEP_KEY]?.[k]?.occupation?.message}
                />

                <FormInputText
                  id={`${idKey}.${STEP_KEY}.${k}.businessName`}
                  label={LABELS.employmentDetail.businessName}
                  register={register}
                  requiredField={false}
                  error={errorKey?.[STEP_KEY]?.[k]?.businessName?.message}
                />

                <FormInputPercent
                  id={`${idKey}.${STEP_KEY}.${k}.percentBusinessOwned`}
                  label={LABELS.employmentDetail.percentBusinessOwned}
                  register={register}
                  requiredField={false}
                  error={
                    errorKey?.[STEP_KEY]?.[k]?.percentBusinessOwned?.message
                  }
                />

                <FormInputDate
                  id={`${idKey}.${STEP_KEY}.${k}.startDate`}
                  label={LABELS.employmentDetail.startDate}
                  register={register}
                  requiredField={false}
                  error={errorKey?.[STEP_KEY]?.[k]?.startDate?.message}
                />

                <FormInputCurrency
                  id={`${idKey}.${STEP_KEY}.${k}.latestYearNetProfit`}
                  label={LABELS.employmentDetail.latestYearNetProfit}
                  register={register}
                  requiredField={false}
                  error={
                    errorKey?.[STEP_KEY]?.[k]?.latestYearNetProfit?.message
                  }
                  setValue={setValue}
                  allowNegative
                />

                <FormInputCurrency
                  id={`${idKey}.${STEP_KEY}.${k}.previousYearNetProfit`}
                  label={LABELS.employmentDetail.previousYearNetProfit}
                  register={register}
                  requiredField={false}
                  error={
                    errorKey?.[STEP_KEY]?.[k]?.previousYearNetProfit?.message
                  }
                  setValue={setValue}
                  allowNegative
                />

                <FormInputText
                  id={`${idKey}.${STEP_KEY}.${k}.selfEmployedAccountant.firmName`}
                  label={
                    LABELS.employmentDetail.selfEmployedAccountant.firmName
                  }
                  register={register}
                  requiredField={false}
                  error={
                    errorKey?.[STEP_KEY]?.[k]?.selfEmployedAccountant?.firmName
                      ?.message
                  }
                />

                <FormInputText
                  id={`${idKey}.${STEP_KEY}.${k}.selfEmployedAccountant.phoneNumber`}
                  label={
                    LABELS.employmentDetail.selfEmployedAccountant.phoneNumber
                  }
                  register={register}
                  requiredField={false}
                  error={
                    errorKey?.[STEP_KEY]?.[k]?.selfEmployedAccountant
                      ?.phoneNumber?.message
                  }
                />

                <FormInputText
                  id={`${idKey}.${STEP_KEY}.${k}.selfEmployedAccountant.emailAddress`}
                  label={
                    LABELS.employmentDetail.selfEmployedAccountant.emailAddress
                  }
                  register={register}
                  requiredField={false}
                  error={
                    errorKey?.[STEP_KEY]?.[k]?.selfEmployedAccountant
                      ?.emailAddress?.message
                  }
                />

                <div className="flex flex-col space-y-4">
                  <label className="block text-base">Accountants address</label>

                  <AddressSetter
                    clearErrors={clearErrors}
                    setValue={setValue}
                    register={register}
                    idKey={`${idKey}.${STEP_KEY}.${k}.selfEmployedAccountant`}
                    errorKey={errorKey?.[STEP_KEY]?.[k]?.selfEmployedAccountant}
                  />
                </div>
              </>
            )}

            {["DMDV", "DWDV"].includes(
              applicantsEmployment?.[k]?.employmentStatus || "",
            ) && (
              <>
                <FormInputText
                  id={`${idKey}.${STEP_KEY}.${k}.occupation`}
                  label={LABELS.employmentDetail.occupation}
                  register={register}
                  requiredField={false}
                  error={errorKey?.[STEP_KEY]?.[k]?.occupation?.message}
                />

                <FormInputText
                  id={`${idKey}.${STEP_KEY}.${k}.employerName`}
                  label={LABELS.employmentDetail.employerName}
                  register={register}
                  requiredField={false}
                  error={errorKey?.[STEP_KEY]?.[k]?.employerName?.message}
                />

                <FormInputDate
                  id={`${idKey}.${STEP_KEY}.${k}.startDate`}
                  label={LABELS.employmentDetail.startDate}
                  register={register}
                  requiredField={false}
                  error={errorKey?.[STEP_KEY]?.[k]?.startDate?.message}
                />

                <FormInputCurrency
                  id={`${idKey}.${STEP_KEY}.${k}.projectedGrossProfitCurrentTradingYear`}
                  label={
                    LABELS.employmentDetail
                      .projectedGrossProfitCurrentTradingYear
                  }
                  register={register}
                  requiredField={false}
                  error={
                    errorKey?.[STEP_KEY]?.[k]
                      ?.projectedGrossProfitCurrentTradingYear?.message
                  }
                  setValue={setValue}
                  allowNegative
                />

                <FormInputCurrency
                  id={`${idKey}.${STEP_KEY}.${k}.latestYearNetProfit`}
                  label={LABELS.employmentDetail.latestYearNetProfit}
                  register={register}
                  requiredField={false}
                  error={
                    errorKey?.[STEP_KEY]?.[k]?.latestYearNetProfit?.message
                  }
                  setValue={setValue}
                  allowNegative
                />

                <FormInputCurrency
                  id={`${idKey}.${STEP_KEY}.${k}.previousYearNetProfit`}
                  label={LABELS.employmentDetail.previousYearNetProfit}
                  register={register}
                  requiredField={false}
                  error={
                    errorKey?.[STEP_KEY]?.[k]?.previousYearNetProfit?.message
                  }
                  setValue={setValue}
                  allowNegative
                />

                <FormInputPercent
                  id={`${idKey}.${STEP_KEY}.${k}.shareholdingPercentInCompany`}
                  label={LABELS.employmentDetail.shareholdingPercentInCompany}
                  register={register}
                  requiredField={false}
                  error={
                    errorKey?.[STEP_KEY]?.[k]?.shareholdingPercentInCompany
                      ?.message
                  }
                />

                <FormInputCurrency
                  id={`${idKey}.${STEP_KEY}.${k}.salaryFromLatestFinalisedYear`}
                  label={LABELS.employmentDetail.salaryFromLatestFinalisedYear}
                  register={register}
                  requiredField={false}
                  error={
                    errorKey?.[STEP_KEY]?.[k]?.salaryFromLatestFinalisedYear
                      ?.message
                  }
                  setValue={setValue}
                />

                <FormInputCurrency
                  id={`${idKey}.${STEP_KEY}.${k}.salaryFromPreviousYear`}
                  label={LABELS.employmentDetail.salaryFromPreviousYear}
                  register={register}
                  requiredField={false}
                  error={
                    errorKey?.[STEP_KEY]?.[k]?.salaryFromPreviousYear?.message
                  }
                  setValue={setValue}
                />

                <FormInputCurrency
                  id={`${idKey}.${STEP_KEY}.${k}.currentYearProjectedDividends`}
                  label={LABELS.employmentDetail.currentYearProjectedDividends}
                  register={register}
                  requiredField={false}
                  error={
                    errorKey?.[STEP_KEY]?.[k]?.currentYearProjectedDividends
                      ?.message
                  }
                  setValue={setValue}
                />

                <FormInputCurrency
                  id={`${idKey}.${STEP_KEY}.${k}.dividendFromLatestFinalisedYear`}
                  label={
                    LABELS.employmentDetail.dividendFromLatestFinalisedYear
                  }
                  register={register}
                  requiredField={false}
                  error={
                    errorKey?.[STEP_KEY]?.[k]?.dividendFromLatestFinalisedYear
                      ?.message
                  }
                  setValue={setValue}
                />

                <FormInputCurrency
                  id={`${idKey}.${STEP_KEY}.${k}.dividendFromPreviousYear`}
                  label={LABELS.employmentDetail.dividendFromPreviousYear}
                  register={register}
                  requiredField={false}
                  error={
                    errorKey?.[STEP_KEY]?.[k]?.dividendFromPreviousYear?.message
                  }
                  setValue={setValue}
                />
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default MainEmploymentJobs;
