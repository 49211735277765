import { gql } from "@apollo/client";

export const MUTATION_CREATE_DIP = gql`
  mutation CreateNewDip($case: UUID, $data: DipInput!) {
    dip {
      createDip(case: $case, data: $data) {
        ... on DipType {
          __typename
          case {
            reference
            status
            uuid
          }
          uuid
          reference
        }
      }
    }
  }
`;
