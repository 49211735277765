export const LABELS = {
  purposeOfMortgage: "Purpose of Mortgage",
  depositAmount: "Deposit amount",
  firstChargeMortgageValue: "First charge mortgage value (without fees)",
  valueOfFeeAddedToFirstChargeMortgage:
    "How much is the fee added to the first charge mortgage?",
  equityLoanAmountRequired: "Equity loan amount required",
  propertyPurchasePrice: "Property purchase price",
  isFirstChargeARepaymentMortgage:
    "Is the first charge mortgage a repayment mortgage?",
  firstChargeLender: "First charge lender",
  termOfFirstChargeLoanYears: "Term of first charge loan (years)",
  isInterestRateFixedForAnInitialPeriod:
    "Is the first charge interest rate fixed for an initial period?",
  termOfInitialInterestRatePeriodYears:
    "Term of initial fixed interest rate period of first charge mortgage (years)",
  firstChargeInitialInterestRatePercent:
    "First charge initial interest rate (%)",
  firstChargeSvrPercent: "First charge mortgage SVR %",
  equityLoanRepaymentPlan:
    "How does the applicant plan to repay the equity loan?",
  otherRepaymentPlan: "If other, please specify",
  sourceOfDeposit: "What is the source of the deposit?",
  otherSourceOfDeposit: "If other, please specify",
  isAllDepositByOwnResource:
    "Will all of the deposit be provided by the applicants own resources?",
  otherDepositAmount:
    "If no, how much of the deposit is not from the applicants own sources?",
  otherDepositSource:
    "If not from the applicants own sources, where does it come from?",
  isAllOrPartDepositAGift: "Is part or all of the deposit a gift?",
  isGiftFromCurrentOwner: "Is the gift from the current owner?",
  willGifterLiveInPropertyAfterPurchase:
    "Will the person providing the gift reside in the property on or after completion?",
  willGifterTakeInterestInPropertyForGift:
    "Will the person providing the gift take an interest in the property or taking out a second charge?",
  amountOfGift: "Amount of gift",
  gifterDetail:
    "Please provide the full name(s) and relationship(s) to the person(s) providing the gift",
};
