import CTAButton from "components/CTA/Button";
import { useNavigate } from "react-router-dom";
import { APP_URLS } from "settings";
import useDipStore from "store/DecisionInPrinciple";
import DipList from "./DipList";

// Define the types for the props
interface DipCase {
  reference: string;
  uuid: string;
}

interface DipsProps {
  dips: any[]; // Replace 'any[]' with the actual type if you have it for dips
  dipCase: DipCase;
}

const Dips: React.FC<DipsProps> = ({ dipCase, dips }) => {
  const navigate = useNavigate();

  const { setDipCase } = useDipStore();

  const startNewDip = () => {
    setDipCase({
      reference: dipCase.reference,
      uuid: dipCase.uuid,
    });
    navigate(APP_URLS.DECISION_IN_PRINCIPLE);
  };

  return (
    <>
      <div className="flex items-end justify-between">
        <h3 className="text-lg font-semibold">Decision in Principle</h3>
        <CTAButton label="Create DIP" onClick={startNewDip} />
      </div>
      <DipList dips={dips} />
    </>
  );
};

Dips.displayName = "Dips";

export default Dips;
