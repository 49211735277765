import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
} from "@apollo/client";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import React from "react";
import ReactDOM from "react-dom/client";

import * as Sentry from "@sentry/react";

import App from "App";
import "css/App.css";

import reportWebVitals from "reportWebVitals";
import { API_URL, COOKIES } from "settings";
import { getCookie } from "utils/helpers";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    environment: process.env.REACT_APP_ENV,
    tracesSampleRate: 0.1,
  });
}

const apolloLink = createUploadLink({
  uri: API_URL,
  credentials: "include",
  headers: {
    "X-CSRFToken": getCookie(COOKIES.CSRF) || "",
  },
});

const client = new ApolloClient({
  link: apolloLink as unknown as ApolloLink,
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
);

reportWebVitals();
