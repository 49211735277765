export const LABELS = {
  email: "Email",
  feeAmount: "Amount of fee?",
  firmName: "Broker firm name",
  isFeeCharged: "Are you charging a fee to the applicants for your advice?",
  levelOfAdviceGiven: "What level of advice was given to the applicant(s)",
  name: "Broker name",
  phoneNumber: "Phone number",
  confirmBrokerDeclarations: "I confirm that all the above statements are true",
};
