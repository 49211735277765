export const DEFAULT_EMPLOYMENT_OTHER_SOURCES_DATA = {
  source: "",
  annualGrossIncome: undefined,
};

export const DEFAULT_EMPLOYMENT_DETAIL_DATA = {
  employmentStatus: "",
  employmentType: "",
  occupation: "",
  employerName: "",
  dailyRate: undefined,
  treatedAsEmployedForTax: undefined,
  startDate: "",
  endDate: "",
  annualBasicSalary: undefined,
  annualBonusGross: undefined,
  annualOvertimeGross: undefined,
  annualCommissionGross: undefined,
  annualLocationOrCarAllowanceGross: undefined,
  businessName: "",
  percentBusinessOwned: undefined,
  projectedGrossProfitCurrentTradingYear: undefined,
  latestYearNetProfit: undefined,
  previousYearNetProfit: undefined,
  shareholdingPercentInCompany: undefined,
  salaryFromLatestFinalisedYear: undefined,
  salaryFromPreviousYear: undefined,
  currentYearProjectedDividends: undefined,
  dividendFromLatestFinalisedYear: undefined,
  dividendFromPreviousYear: undefined,
  selfEmployedAccountant: {
    address: "",
    buildingName: "",
    buildingNumber: "",
    subBuildingName: "",
    subBuildingNumber: "",
    thoroughfare: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    townOrCity: "",
    county: "",
    postcode: "",
    firmName: "",
    phoneNumber: "",
    emailAddress: "",
  },
};

export const DEFAULT_EMPLOYMENT_DATA = {
  isRetired: undefined,
  employedInUk: undefined,
  numberOfJobs: undefined,
  awareOfAnyChangesToIncome: undefined,
  incomeChangesDetails: "",
  hasOtherSourcesOfIncome: undefined,
  anticipatedRetirementAge: undefined,
  mortgageTermBeyondRetirementAge: undefined,
  retirementIncomeSource: "",
  anticipatedCurrentRetirementAnnualAmount: undefined,
  employmentDetail: [{ ...DEFAULT_EMPLOYMENT_DETAIL_DATA }],
  employmentOtherIncomeSources: [],
};
