import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import { applicationStepOne } from "pages/ApplicationForm/formSteps";
import { APP_URLS } from "settings";

const NAV_ITEMS = [
  {
    href: applicationStepOne(),
    name: "New Application",
  },
  {
    href: APP_URLS.DECISION_IN_PRINCIPLE,
    name: "New Decision in Principle (DIP)",
  },
  {
    href: APP_URLS.CREATE_ILLUSTRATION,
    name: "New Mortgage Illustration (ESIS)",
  },
  {
    href: APP_URLS.CASE_MANAGEMENT,
    name: "View my Cases",
  },
];

export default function DashboardNavList() {
  return (
    <div
      className="flex basis-2/3 flex-col lg:mr-16"
      aria-labelledby="Dashboard links"
    >
      <h3 className="mb-6 text-left text-xl font-normal">
        What would you like to do?
      </h3>

      {NAV_ITEMS.map((item, i) => (
        <a
          key={i}
          href={item.href}
          className="mb-4 flex w-full justify-between rounded-md border border-gray-200 bg-white px-6 py-6 text-lg text-brand-copy-primary transition-all focus:outline-none"
        >
          <span>{item.name}</span>
          <ChevronDoubleRightIcon className="h-7 w-7" />
        </a>
      ))}
    </div>
  );
}
