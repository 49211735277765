export const ExpenditureCreditProviderTypes = [
  {
    value: "CC",
    title: "Credit card",
  },
  {
    value: "OD",
    title: "Overdraft",
  },
  {
    value: "MO",
    title: "Mail order",
  },
  {
    value: "HP",
    title: "Hire purchase",
  },
  {
    value: "MM",
    title: "Mortgage (on main residence)",
  },
  {
    value: "OM",
    title: "Mortgage (on other property)",
  },
  {
    value: "IN",
    title: "Insurance",
  },
  {
    value: "O",
    title: "Other",
  },
];
