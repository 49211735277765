import { useId } from "react";
import { classNames } from "utils/helpers";

export default function Loading({
  large = false,
  small = false,
}: {
  large?: boolean;
  small?: boolean;
}) {
  const id = useId();

  return (
    <div className="text-center">
      <div role="status">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="200"
          height="200"
          viewBox="0 0 200 200"
          fill="none"
          color="#0D493B"
          className={classNames(
            large ? "h-24 w-24" : small ? "h-8 w-8" : "h-10 w-10",
            "inline animate-spin",
          )}
        >
          <defs>
            <linearGradient id={`spinner-secondHalf-${id}`}>
              <stop offset="0%" stopOpacity="0" stopColor="currentColor" />
              <stop offset="100%" stopOpacity="0.5" stopColor="#00DEA3" />
            </linearGradient>
            <linearGradient id={`spinner-firstHalf-${id}`}>
              <stop offset="0%" stopOpacity="1" stopColor="currentColor" />
              <stop offset="100%" stopOpacity="0.5" stopColor="#00DEA3" />
            </linearGradient>
          </defs>
          <g strokeWidth="8">
            <path
              stroke={`url(#spinner-secondHalf-${id})`}
              d="M 4 100 A 96 96 0 0 1 196 100"
            />
            <path
              stroke={`url(#spinner-firstHalf-${id})`}
              d="M 196 100 A 96 96 0 0 1 4 100"
            />
            <path
              stroke="currentColor"
              strokeLinecap="round"
              d="M 4 100 A 96 96 0 0 1 4 98"
            />
          </g>
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}
