import { useMutation } from "@apollo/client";
import { MUTATION_CREATE_CASE } from "api/mutations/cases";
import CTAButton from "components/CTA/Button";

// Define the props interface
interface CaseCreateButtonProps {
  refetch: () => void;
  setCreateError: (error: any) => void;
  setRecentCase: (uuid: string) => void;
}

const CaseCreateButton: React.FC<CaseCreateButtonProps> = ({
  refetch,
  setCreateError,
  setRecentCase,
}) => {
  const [createCase, { loading, error }] = useMutation(MUTATION_CREATE_CASE);

  const handleCreateCase = async () => {
    try {
      const result = await createCase();
      if (result.data.caseCreate.ok) {
        setRecentCase(result.data.caseCreate.case.uuid);
      } else {
        setCreateError(error);
      }
      refetch();
    } catch (e) {
      setCreateError(e);
    }
  };

  return (
    <div className="mb-8 flex flex-col justify-end lg:flex-row">
      <CTAButton
        label="Create new case"
        onClick={handleCreateCase}
        loading={loading}
      />
    </div>
  );
};

CaseCreateButton.displayName = "CaseCreateButton";

export default CaseCreateButton;
