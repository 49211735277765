import React from "react";

import AddressSetter from "components/Form/AddressSetter";
import FormInputDate from "components/Form/Input/Date";
import FormInputNumber from "components/Form/Input/Number";
import FormInputRadio from "components/Form/Input/Radio";
import FormInputSelect from "components/Form/Input/Select";
import FormInputText from "components/Form/Input/Text";
import {
  Control,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { ComonObjectType } from "ts/types";
import { ApplicantTitleHonorifics } from "utils/options";
import { checkFalseyValue } from "utils/validation";
import { LABELS } from "./labels";
import {
  ApplicantAddressResidentialStatuses,
  ApplicantGenders,
  ApplicantMaritalStatuses,
  ApplicantNationalities,
} from "./options";
import PreviousAddress from "./PreviousAddress";

interface FormProps {
  clearErrors: UseFormClearErrors<any>;
  setValue: UseFormSetValue<any>;
  register: UseFormRegister<any>;
  control: Control<any, any>;
  idKey: string;
  errorKey: ComonObjectType | undefined;
  isApplication?: boolean;
}

const ApplicantDetailsFormFields: React.FC<FormProps> = ({
  clearErrors,
  register,
  setValue,
  control,
  idKey,
  errorKey,
  isApplication = false,
}) => {
  const watchApplicant = useWatch({ name: idKey, control: control });

  return (
    <>
      <div className="flex flex-col space-y-4">
        <FormInputSelect
          id={`${idKey}.title`}
          label={LABELS.title}
          register={register}
          initSelect
          options={ApplicantTitleHonorifics}
          requiredField={false}
          error={errorKey?.title?.message}
        />

        <FormInputText
          id={`${idKey}.forename`}
          label={LABELS.forename}
          register={register}
          requiredField={false}
          error={errorKey?.forename?.message}
        />

        <FormInputText
          id={`${idKey}.middleName`}
          label={LABELS.middleName}
          register={register}
          requiredField={false}
          error={errorKey?.middleName?.message}
        />

        <FormInputText
          id={`${idKey}.surname`}
          label={LABELS.surname}
          register={register}
          requiredField={false}
          error={errorKey?.surname?.message}
        />

        <FormInputDate
          id={`${idKey}.dob`}
          label={LABELS.dob}
          register={register}
          requiredField={false}
          error={errorKey?.dob?.message}
        />

        <FormInputText
          id={`${idKey}.previousName`}
          label={LABELS.previousName}
          register={register}
          requiredField={false}
          error={errorKey?.previousName?.message}
        />

        {watchApplicant?.previousName && (
          <FormInputDate
            id={`${idKey}.dateOfChange`}
            label={LABELS.dateOfChange}
            register={register}
            requiredField={false}
            error={errorKey?.dateOfChange?.message}
          />
        )}

        {isApplication && (
          <>
            <FormInputSelect
              id={`${idKey}.nationality`}
              label={LABELS.nationality}
              register={register}
              initSelect
              options={ApplicantNationalities}
              requiredField={false}
              error={errorKey?.nationality?.message}
            />

            <FormInputSelect
              id={`${idKey}.dualNationality`}
              label={LABELS.dualNationality}
              register={register}
              initSelect
              initSelectLabel="None"
              options={ApplicantNationalities}
              requiredField={false}
              error={errorKey?.dualNationality?.message}
            />

            {watchApplicant?.nationality !== "BRITISH" && (
              <>
                <FormInputRadio
                  id={`${idKey}.hasPermanentUkResideRight`}
                  label={LABELS.hasPermanentUkResideRight}
                  register={register}
                  requiredField={false}
                  initValue={watchApplicant?.hasPermanentUkResideRight}
                  error={errorKey?.hasPermanentUkResideRight?.message}
                />

                {checkFalseyValue(
                  watchApplicant?.hasPermanentUkResideRight,
                ) && (
                  <FormInputText
                    id={`${idKey}.visaType`}
                    label={LABELS.visaType}
                    register={register}
                    requiredField={false}
                    error={errorKey?.visaType?.message}
                  />
                )}

                <FormInputRadio
                  id={`${idKey}.hasRefugeeAsylumStatus`}
                  label={LABELS.hasRefugeeAsylumStatus}
                  register={register}
                  requiredField={false}
                  initValue={watchApplicant?.hasRefugeeAsylumStatus}
                  error={errorKey?.hasRefugeeAsylumStatus?.message}
                />
              </>
            )}

            <FormInputRadio
              id={`${idKey}.hasDiplomaticImmunity`}
              label={LABELS.hasDiplomaticImmunity}
              register={register}
              requiredField={false}
              initValue={watchApplicant?.hasDiplomaticImmunity}
              error={errorKey?.hasDiplomaticImmunity?.message}
            />
          </>
        )}

        <FormInputText
          id={`${idKey}.phoneNumber`}
          label={LABELS.phoneNumber}
          register={register}
          requiredField={false}
          error={errorKey?.phoneNumber?.message}
        />

        <FormInputText
          id={`${idKey}.email`}
          label={LABELS.email}
          register={register}
          requiredField={false}
          error={errorKey?.email?.message}
          type="email"
        />

        {isApplication && (
          <>
            <FormInputSelect
              id={`${idKey}.gender`}
              label={LABELS.gender}
              register={register}
              initSelect
              options={ApplicantGenders}
              requiredField={false}
              error={errorKey?.gender?.message}
            />

            <FormInputSelect
              id={`${idKey}.maritalStatus`}
              label={LABELS.maritalStatus}
              register={register}
              initSelect
              options={ApplicantMaritalStatuses}
              requiredField={false}
              error={errorKey?.maritalStatus?.message}
            />
          </>
        )}

        <div className="flex flex-col space-y-4">
          <label className="block text-base">Current address</label>

          <AddressSetter
            clearErrors={clearErrors}
            setValue={setValue}
            register={register}
            idKey={`${idKey}.currentAddress`}
            errorKey={errorKey?.currentAddress}
          />

          <FormInputNumber
            id={`${idKey}.currentAddress.stayDurationYears`}
            label={LABELS.address.stayDurationYears}
            register={register}
            requiredField={false}
            error={errorKey?.currentAddress?.stayDurationYears?.message}
            maxDigits={4}
          />

          <FormInputNumber
            id={`${idKey}.currentAddress.stayDurationMonths`}
            label={LABELS.address.stayDurationMonths}
            register={register}
            requiredField={false}
            error={errorKey?.currentAddress?.stayDurationMonths?.message}
            maxDigits={2}
          />

          <FormInputSelect
            id={`${idKey}.currentAddress.residentialStatus`}
            label={LABELS.address.residentialStatus}
            register={register}
            initSelect
            options={ApplicantAddressResidentialStatuses}
            requiredField={false}
            error={errorKey?.currentAddress?.residentialStatus?.message}
          />
        </div>

        <PreviousAddress
          idKey={idKey}
          setValue={setValue}
          clearErrors={clearErrors}
          errorKey={errorKey}
          {...{ control, register }}
        />
      </div>
    </>
  );
};

export default ApplicantDetailsFormFields;
