import { setDataForApi } from "utils/api";

export const parseApplicantExpenditure = (applicant: any) => {
  if (!applicant) {
    return applicant;
  }

  const { creditProviders } = applicant;

  if (creditProviders) {
    creditProviders.forEach((cp: any) => {
      setDataForApi(cp);
    });
  }

  setDataForApi(applicant);

  return applicant;
};
