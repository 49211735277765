import { setDataForApi } from "utils/api";

export const parseApplicantDetail = (applicant: any) => {
  if (!applicant) {
    return applicant;
  }

  const { currentAddress, previousAddresses } = applicant;

  if (currentAddress) {
    setDataForApi(currentAddress);
  }

  if (previousAddresses && Array.isArray(previousAddresses)) {
    previousAddresses.forEach((address) => setDataForApi(address));
  }

  setDataForApi(applicant);

  return applicant;
};
