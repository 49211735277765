import { Navigate, Outlet } from "react-router-dom";

import { APP_URLS } from "settings";

/**
 * Route authenticated users to their Dashboard if logged in
 */
export default function RouteUnauthenticated({
  authenticated,
}: {
  authenticated: boolean;
}) {
  if (authenticated) {
    const navigateTo = APP_URLS.DASHBOARD;
    return <Navigate replace to={navigateTo} />;
  }
  return <Outlet />;
}
