export default function LogoDark() {
  return (
    <svg
      width="172"
      height="45"
      viewBox="0 0 172 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="h-8 w-auto"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.7651 18.2804L56.6174 44.5869H62.6315L64.4352 39.6626V39.7378H74.7694V39.6626L76.6483 44.5869H82.736L72.4395 18.2804H66.7651ZM69.6196 24.4433L73.1159 34.9653H66.0135L69.6196 24.4433Z"
        fill="#052A2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.5762 17.416H90.1754V27.938C91.7913 25.6833 93.8205 24.8942 96.1504 24.8942C101.562 24.8942 103.029 29.2172 103.029 34.1009V44.5868H97.4295V34.2151C97.4295 31.5456 96.451 29.5178 93.8581 29.5178C91.2652 29.5178 90.1754 31.5846 90.1754 34.2513V44.5868H84.5762V17.416Z"
        fill="#052A2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.293 40.4525C119.035 40.4525 121.327 38.6863 121.327 35.1914C121.327 31.6215 119.187 29.4419 116.143 29.4419C113.061 29.4419 111.331 31.9221 111.331 34.8908C111.331 37.9723 113.21 40.4525 116.293 40.4525ZM126.777 25.3083V44.5861H121.178V41.7677C119.788 43.7594 117.946 44.9995 115.127 44.9995C109.266 44.9995 105.732 40.49 105.732 34.8532C105.732 29.4419 109.001 24.8949 115.014 24.8949C117.608 24.8949 119.863 26.1726 121.178 27.9388V25.3083H126.777Z"
        fill="#052A2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M149.061 44.5866H143.462V41.9561C141.846 44.2108 139.817 44.9999 137.486 44.9999C132.074 44.9999 130.609 40.6784 130.609 35.7932V25.3087H136.208V35.6804C136.208 38.3485 137.185 40.3778 139.78 40.3778C142.372 40.3778 143.462 38.3109 143.462 35.6428V25.3087H149.061V44.5866Z"
        fill="#052A2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M171.533 44.5866H151.953V41.3172L162.776 29.7054H152.479V25.3087H170.894V28.6156L160.109 40.1147H171.533V44.5866Z"
        fill="#052A2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.47 0L0 18.308H8.68067V44.5871L27.47 4.8621L46.2593 44.5871V18.308H54.9385L27.47 0Z"
        fill="#052A2E"
      />
    </svg>
  );
}
